// When algolia is displayed, hide the page content and the page footer
body.algolia_displayed {
    #site_global_wrap,
    #flowbox-container {
        display: none;
    }

    // Hide it that way because we need access to elements inside the footer
    #copyright {
        height: 0;
        overflow: hidden;
    }
}

.ais-Pagination {
    margin-top: 1em;
}

.ais-InstantSearch {
    overflow: hidden;
    margin: 0 auto;

    ol {
        list-style-type: none;
    }
}

.ais-Hits-item {
    margin-bottom: 1em;
    width: calc(50% - 1rem);
}

.hit-name {
    margin-bottom: 0.5em;
}

.ais-RefinementList-showMore--disabled {
    display: none;
}

.ais-InstantSearch{
    position: absolute;
    top: $headerHeightBig;
    left: 0;
    width: 100%;
    background: $white;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    z-index: -1;

    &.sticky {
        #filter_sticky_search {
            top: $fixed_headerHeightBig;
            height: calc(100vh - #{$fixed_headerHeightBig});
        }
    }

    &.displayed {
        opacity: 1;
        z-index: 7;
    }

    /* General */
    .ais-Panel-header {
        font-size: 16px;
        font-weight: $regular;
        text-transform: initial;
        margin-bottom: 0;
        border: 0;
        color: $primaryColor;
        padding-bottom: 0;


        .trigger_dropdown {
            cursor: pointer;
            display: block;
            font-size: 16px;
            color: $primaryColor;
        }
    }

    .ais-Panel-body {
        color: $primaryColor;
    }

    // RESULTS
    #results-wrapper {
        max-width: 1680px;
        width: 98%;
        margin: 0 auto fs-vw(50,1920);

        @media screen and (max-width: 1600px) {
            padding: 0 2rem;
        }
    }

    #hits {
        margin-bottom: fs-vw(50,1920);

        .ais-InfiniteHits {
            display: flex;
            flex-direction: column;
            margin-top: fs-vw(20,1920);
        }

        .ais-InfiniteHits-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .ais-InfiniteHits-item {
            max-width: calc(97% / 4);
            width: 100%;
            padding: 0 0 10px 0;

            &:not(:nth-child(4n)) {
                margin-right: 1%;
            }
        }

        .wrapperProdVertical {
            padding: 0;
        }

        .wrapper_price {
            flex-direction: row-reverse;
        }
    }

    // RESULTS
    #stats {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1680px;
        width: 100%;
        margin: 0 auto 2rem;

        &.no_results {
            display: none;
        }

        .holder_recap {
            box-sizing: border-box;
            background-color: #f5f5f5;
            max-width: 553px;
            width: 33%;
            min-height: fs-vw(270,1920);
            padding: fs-vw(56,1920) fs-vw(64,1920);

            .result_query {
                display: none;

                &.search_term {
                    display: block;
                    font-size: 2.6vw;
                    font-weight: 700;
                    line-height: 1;
                    quotes: "“" "”";

                    &::first-letter {
                        text-transform: uppercase;
                    }

                    &::before {
                        content: open-quote;
                    }

                    &::after {
                        content: close-quote;
                    }
                }
            }

            .result_count {
                display: none;

                &.nbr_result {
                    font-size: 2.35vw;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    line-height: 40px;
                    margin-top: 15px;

                    &::before {
                        content: "(";
                    }

                    &::after {
                        content: ")";
                    }
                }
            }
        }

        .search_details_wrapper {
            width: 67%;

            @media screen and (max-width: 1024px) {
                width: 60%;
                margin: 0 auto;
            }
        }
    }

    #holder_filters_sections {
        display: flex;
    }

    #trigger_filtre {
        display: flex;
        justify-content: initial;



        .ais-DynamicWidgets-widget {
            // COLOR FILTER HIDEN
            &:has(#primary_color_hex) {
                display: none;
            }

            &:has(.all_filters_display) {
                order: 1;
            }

        }

        .ais-DynamicWidgets {
            display: flex;

            .ais-Panel-body {
                display: none;
            }
        }
    }

    .ais-Panel-body.selected_options {
        .filter_label {
            cursor: pointer;
        }

        .available_count {
            font-size: fs-vw(16,1920);
            font-weight: 700;
            margin-left: fs-vw(5,1920);

            &::after {
                content: ")";
            }

            &::before {
                content: "(";
            }
        }
    }

    #filter_trier {
        position: relative;
        top: 0.6rem;
        padding-right: 1rem;
        white-space: nowrap;
        z-index: 5;

        &:hover {
            .selected_options {
                display: block;
            }

            #pic {
                transform: rotate(-135deg);
            }
        }

        #selectedSortFilter {
            margin-left: 0.4rem;
            font-size: 16px;
            color: #8d8d8d;
        }

        #pic {
            border: solid #8d8d8d;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(45deg);
            position: absolute;
            top: 6px;
            right: 0;
            margin-left: 8px;
        }

        .selected_options {
            position: absolute;
            flex: 0 0 100%;
            display: none;
            width: calc(100% - 2rem);
            transition: 0.3s ease-out;
            background-color: $white;
            padding: 1rem;
            box-sizing: content-box;

        }
    }

    .form_itm {
        cursor: pointer;

        &.checked {
            font-weight: $bold;
            cursor: initial;
        }
    }

    #filter_sticky_search {
        justify-content: inherit;

        .filters_cta.holder_submits {
            display: flex;
            justify-content: space-between;
            position: fixed;
            bottom: 2rem;
            width: calc(100% - 120px);
            padding-top: 40px;
            height: 50px;
            margin-top: 30px;
        }

        .button {
            height: 50px;
            background-color: #000;
            color: #fff;
            text-transform: uppercase;
            border: 0;
            max-width: 175px;
            width: 100%;
            text-align: center;
            line-height: 50px;
            cursor: pointer;
        }

        #clear-refinements_sticky button {
            height: 48px;
            background-color: #fff;
            color: #000;
            border: 1px solid #000;
            text-transform: uppercase;
            max-width: 175px;
            width: 100%;
            text-align: center;
            line-height: 50px;
            cursor: pointer;
        }

        .filters_container {
            .filters_dropdown_bloc {
                width: 93.5%;
            }

            .selected_options.colorOptions {
                margin-bottom: 20px;
            }
        }

        .form_itm {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &.color {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                cursor: pointer;
                box-sizing: border-box;

                &[data-color="blanc"],
                &[data-color="multicolore"] {
                    border: 1px solid rgba($primaryColor, .5);
                }

                label {
                    position: absolute;
                    display: flex;
                    left: 25px;
                    font-size: 16px;
                    font-weight: 300;
                    white-space: nowrap;
                    letter-spacing: .05rem;
                    text-transform: capitalize;

                    .available_count {
                        display: none;
                    }
                }

                &.checked {
                    label {
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    // Price filter
    .selected_options.for_range_slider {
        width: 90%;
        margin: 30px auto 60px;
    }
    #range-slider {
        font-family: $primaryFont;
        width: 90%;
        margin: 0 auto;

        .rheostat-handle {
            z-index: 2;
            position: absolute;
            top: -5px;
            transform: translateX(-22px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            outline: 0;
            cursor: pointer;

            &::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background-color: #fff;
                border: 1px solid #000;
                border-radius: 50%;
            }
        }

        .rheostat-tooltip {
            font-weight: 900;
            padding-top: 15px;

            &:first-child {
                margin-left: -130%;
            }

            &:last-child {
                margin-right: -130%;
            }
        }

        .rheostat-progress {
            position: absolute;
            background-color: $black;
            height: 1px;
        }

        .rheostat-background {
            background-color: $txtGrey;
            height: 1px;
        }
    }

    // NO RESULTS
    #noresult {
        display: flex;
        flex-direction: column;
        margin: fs-vw(44,1920) auto;

        .order_wrapper  {
            &.top_wrapper {
                display: flex;
                align-items: center;
                width: 100%;
            }

            &.bottom_wrapper {
                .gondoleTitle {
                    font-size: clamp(15px, fs-vw(30,1920), 30px);
                    font-weight: 800;
                    line-height: fs-vw(44,1920);
                    text-align: center;
                    text-transform: uppercase;
                    display: block !important;
                    width: 100%;
                    margin: fs-vw(50,1920) auto fs-vw(27,1920);
                }
            }

            .holder_recap {
                box-sizing: border-box;
                background-color: $bgGrey;
                max-width: 553px;
                min-width: 33%;
                min-height: 270px;
                padding: 56px 64px;
            }

            .search_term {
                font-size: 2.6vw;
                font-weight: 700;
                display: flex;
                flex-direction: column;
                line-height: 1;
            }

            .nbr_result {
                font-size: 2.35vw;
                font-weight: 400;
                display: flex;
                align-items: center;
                line-height: 40px;
                margin-top: 15px;
            }

            .search_details_wrapper {
                padding-left: 50px;


                .no_result_block {
                    .title_no_result {
                        font-size: 30px;
                        font-weight: 700;
                        margin-bottom: fs-vw(16,1920);
                    }

                    .search_tips_section,
                    .search_advice {
                        font-size: 16px;
                        font-weight: 400;
                    }

                    .search_tips_list {
                        margin-bottom: 2rem;
                    }

                    .tip {
                        list-style-type: disc;
                        list-style-position: inside;
                    }
                }
            }
        }

        .container_inject_gondole {
            position: relative;
            display: flex;
            flex-direction: column;
            max-width: 1680px;
            width: 100%;
            box-sizing: border-box;

            .inject_gondole:not(.swiper-pagination) {
                margin-bottom: 0.5rem;
            }

            .ais-Hits--empty,
            .no_swiper {
                display: none;
            }

            .swiper-wrapper {
                max-width: 1680px;
                margin: 0 auto;
                box-sizing: border-box;
            }

            .noresult_gondole_title {
                width: 100%;
                font-size: clamp(15px, fs-vw(30,1920), 30px);
                font-weight: 800;
                line-height: fs-vw(44,1920);
                text-align: center;
                text-transform: uppercase;
                margin-bottom: fs-vw(27,1920);
                order: -1;

                > span {
                    font-weight: 400;
                }
            }

            .swiper-pagination {
                bottom: 0;
            }

            .inject_gondole_arrow {
                position: absolute;
                bottom: 0;
                transform: translateY(50%);
                cursor: pointer;
                transition: all .3s ease;

                &.slide_prev {
                    left: 85%;

                    &::after {
                        content: '';
                        display: block;
                        width: fs-vw(24,1920);
                        height: fs-vw(24,1920);
                        @include bgIconPath('../svg/spritesheet.svg');
                        @include bgIconCoord(6, 6, 4);
                        transform: rotate(180deg);
                    }
                }

                &.slide_next {
                    right: 10%;

                    &::after {
                        content: '';
                        display: block;
                        width: fs-vw(24,1920);
                        height: fs-vw(24,1920);
                        @include bgIconPath('../svg/spritesheet.svg');
                        @include bgIconCoord(6, 6, 4);
                    }
                }
            }
        }
    }

    // SUGGESTIONS
    .bloc_suggestions_container {
        position: relative;
        max-width: 1024px;
        width: 90%;
        padding: 0 25px;
        margin: clamp(16px, fs-vw(20,1920), 20px) auto 0;
        box-sizing: border-box;

        &.no_swiper {
            #query_suggestions_list {
                .swiper-wrapper {
                    justify-content: flex-start;
                }
            }

            .suggestions_arrow {
                display: none;
            }

            .swiper-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                .swiper-slide:not(:last-of-type) {
                    margin-right: 10px;
                }
            }
        }

        #query_suggestions_list {
            width: 98%;
            margin: 0 auto;

            .ais-Hits-item {
                font-size: 16px;
                font-weight: 400;
                text-transform: initial;
                color: #000;
                margin-right: fs-vw(20,1920);
                margin-bottom: 0;
                padding-bottom: 0;
                border: 0;
            }

            .aa-ItemLink {
                display: block;
                padding-right: 20px;
                padding-left: 20px;
                height: 40px;
                background-color: #f5f5f5;
                font-size: 16px;
                line-height: 40px;
                text-align: center;
                border-radius: 20px;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: fit-content;

                &:hover {
                    background-color: $backgroundnavy;
                    border-color: $backgroundnavy;
                }

                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }

        .list_suggestions {
            position: relative;
            flex-wrap: unset;
            margin: 0;

            &:not(.swiper-initialized) {
                .ais-Hits-item {
                    width: fit-content;
                }
            }
        }

        .suggestions_arrow {
            position: absolute;
            top: 56%;
            transform: translateY(-50%);
            cursor: pointer;
            transition: all .3s ease;

            &.suggestions_prev {
                left: 0;

                &::after {
                    content: '';
                    display: block;
                    width: fs-vw(24,1920);
                    height: fs-vw(24,1920);
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(6, 6, 4);
                    transform: rotate(180deg);
                }
            }

            &.suggestions_next {
                right: 0;

                &::after {
                    content: '';
                    display: block;
                    width: fs-vw(24,1920);
                    height: fs-vw(24,1920);
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(6, 6, 4);
                }
            }
        }
    }

    // NO RESULTS VISITED ARTICLES
    #algolia_visited_articles {
        #gondole_derniers_articles_vus {
            position: relative;

            #productVisitedSwiper {
                margin-bottom: 1rem;
            }

            // Arrows
            .leftarrow,
            .rightarrow {
                position: absolute;
                top: unset;
                bottom: -1rem;
                transform: translateY(50%);
                width: fs-vw(24,1920);
                height: fs-vw(24,1920);
                cursor: pointer;
                transition: all .3s ease;
                background-image: none;
            }

            .leftarrow {
                left: 85%;

                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(6, 6, 4);
                    transform: rotate(180deg);
                }
            }

            .rightarrow {
                right: 10%;

                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(6, 6, 4);
                }
            }

            // Pagination
            .swiper-pagination {
                bottom: unset;
                left: unset;
                transform: unset;
                margin-left: 11.905%;
                width: 67.679%;
                height: 3px;

                .swiper-pagination-progressbar-fill {
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        #derniers_articles_vus {
            width: 100%;
            font-size: clamp(15px, fs-vw(30,1920), 30px);
            font-weight: 800;
            line-height: fs-vw(44,1920);
            text-align: center;
            text-transform: uppercase;
            margin-bottom: fs-vw(27,1920);
            order: -1;

            > span {
                font-weight: 400;
            }
        }
    }

    .ais-InfiniteHits-loadMore {
        position: relative;
        background: #f5f5f5;
        min-width: 185px;
        height: 40px;
        font-family: $primaryFont;
        font-weight: 500;
        font-size: 16px;
        line-height: 48px;
        letter-spacing: 0.6px;
        color: #ffffff;
        border-radius: 20px;
        margin-top: 30px;
        cursor: pointer;
        color: black;
        line-height: 40px;
        margin-left: auto;
        margin-right: auto;

        &::before{
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            width: 100%;
            height: 40px;
            background: url(../svg/three_dots.svg) center center/18% no-repeat #f5f5f5 !important;
            z-index: -1;
            border-radius: 20px;
            opacity: 0;
        }

        &.loading{
            &::before{
                z-index: 1;
                opacity: 1;
            }
        }

        &--disabled {
            display: none;
        }
    }
}

.algolia_footer.footer .wrapper_newsletter .w-input.w-email-input .w-input-element {
    height: 44px;
}

#filter_sticky_search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form_itm {
        display: block;
        height: 35px;
        margin-bottom: 16px;
        color: $primaryColor;

        &.color {
            height: 28px;
            font-size: 0;
        }
    }

    // Scrollbar parent
    .filters_container {
        height: calc(100% - 126px);
        overflow: hidden;
        overflow-y: auto;
        width: 100%;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            background: $white;
        }

        &::-webkit-scrollbar-thumb {
            background: $txtGrey;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $black;
        }
    }

    // Element with scrollbar
    .ais-DynamicWidgets {
        max-height: 100%;
    }

    .ais-Panel {
        width: 92%;
        font-size: 16px;
        padding: 0;
        border-bottom: 1px solid #cfcfcf;

        &.body_visible .ais-Panel-header::after {
            transform: rotate(-135deg);
        }
    }

    .ais-Panel-header {
        position: relative;
        height: 51px;
        line-height: 51px;
        font-weight: $regular;

        &::after {
            content: "";
            border: solid black;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 3px;
            transition: all .3s ease;
            transform: rotate(45deg);
            position: absolute;
            right: 5px;
            top: 22px;
        }
    }
    .ais-Panel-body {
        display: none;
        padding-left: 14px;
    }


    .holder_submits {
        display: flex;
        justify-content: space-between;

        >.form_submit,
        #clear-refinements_sticky {
            width: 48%
        }
    }

    .ais-ClearRefinements {
        margin: 0;
    }

    .ais-ClearRefinements-button {
        @extend .button;
        @extend .light;

        cursor: pointer;
        opacity: 1;
        border-radius: 0;


        &.ais-ClearRefinements-button--disabled {
            pointer-events: none;
            opacity: .4;
        }

        &:hover {
            text-decoration: none;
        }
    }
}


//Autocomplete
.aa-Panel {
    z-index: 20;
    position: absolute;

    .aa-ItemIcon,
    .aa-ItemActions{
        display: none;
    }

    .aa-List{
        margin-top: 10px;
        width: 100%;
        background: #ededed;

        li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .4rem 1rem;
            font-family: Arial,Helvetica,sans-serif;
            font-size: 13px;
            color: #000;

            mark{
                background: none;
            }
        }
    }
}




// TOP INPUT
#site_head_wrap.sticky {
    .ais-InputContainer {
        #autocomplete {
            .aa-Input {
                max-height: 34px;
            }

            .aa-Form {
                .aa-InputWrapperSuffix,
                .aa-InputWrapperPrefix {
                    top: -7px;
                }
            }
        }

        @media screen and (min-width: 1120px) {
            position: relative;
            width: stretch;
            height: 34px;
            padding: 0 3.2vw 0 4.2vw;
            left: unset;
            top: unset;

            .ais-SearchBox-input,
            .aa-Input {
                padding: 8px 20px 6px;
            }
        }

    }
}

#input-container.ais-InputContainer {
    position: absolute;
    left: 0;
    top: 25px;
    width: 25vw;
    height: auto;
    min-height: 44px;
    background-color: $white;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    @media screen and (max-width: $wrapperMedium) {
        left: 2rem;
    }

    #autocomplete {
        max-width: 430px;
        width: 100%;
        height: 100%;
        z-index: 2;

        @media screen and (max-width: 1120px) {
            max-width: unset;
            width: 25vw;
        }

        .aa-Autocomplete {
            position: relative;
        }

        .aa-Form {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;

            .aa-InputWrapperPrefix,
            .aa-InputWrapperSuffix {
                position: absolute;
                right: 1rem;
                top: 0;
                transform: translateY(50%);
            }

            .aa-InputWrapperPrefix {
                .aa-Label {
                    position: relative;
                    top: 0.4rem;
                    display: block;
                }
            }

            .aa-Input {
                border-radius: 18px;
                padding: 15px 20px;
                text-transform: uppercase;
                appearance: 0;
                border: 1px solid $primaryColor;
                font-family: $primaryFont;
                color: $primaryColor;
                font-style: italic;
                box-sizing: border-box;
                width: 100%;
                height: 100%;

                &::placeholder {
                    font-weight: $medium;
                    font-size: 14px;
                    color: $primaryColor;
                }
                &::-webkit-input-placeholder,
                &::input-placeholder {
                    font-weight: $medium;
                    font-size: 14px;
                    color: $primaryColor;
                }
            }

            .aa-ClearButton,
            .aa-SubmitButton {
                background-color: $white;
                cursor: pointer;
            }

            .aa-ClearButton,
            .aa-SubmitButton {
                position: absolute;
                appearance: none;
                top: 50%;
                right: 0;
                left: unset;
                transform: translateY(50%);
                width: 20px;
                height: 20px;
                background-color: $white;
                padding: 0;
                z-index: 1;

                svg {
                    width: 14px;
                    height: 14px;
                    color: #495588;
                }

                .aa-ClearIcon {
                    width: 20px;
                    height: 20px;
                    padding-top: 0.3rem;
                }
            }

        }

        .ais-SearchBox-submit {
            cursor: initial;
        }
    }
}


// SHADE
#algolia_shade {
    cursor: pointer;
    display: none;

    &.displayed {
        display: block !important;
        opacity: 1;
        z-index: 8;
    }
}

.container_inject_gondole,
#algolia_visited_articles {
    .swiper-pagination {
        height: 3px;
        margin-left: 11.905%;
        width: 67.679%;

        .swiper-pagination-progressbar-fill {
            background-color: rgba(0, 0, 0, .5);
        }
    }
}