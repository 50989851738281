.w-form-line * {
    box-shadow: none !important;
    outline: none;
}

//Style général des champs
.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,
.w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]):not(textarea),
.w-newuser-form .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]),
.w-nonempty:not(.no-label) input[type=text].w-input-element,
.w-input-element:-webkit-autofill,
.w-input-element:-webkit-autofill:hover,
.w-input-element:-webkit-autofill:focus,
.w-input-element:-internal-autofill-selected {
    width: calc(100% - 2rem);
    height: 1.225rem;
    padding: 1.5rem 1rem .4rem;
    font-size: .813rem;
    border-radius: 0;
    transition: background-color 5000s ease-in-out 0s;
    border-right: 0;
    border: solid 1px #C1C1C1;
    background-color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-internal-autofill-selected {
    width: calc(100% - 2rem);
    height: 1.225rem;
    padding: 1.5rem 1rem .4rem;
    font-family: $primaryFont;
    font-size: .813rem;
    border: 1px solid $primaryColor !important;
    border-radius: 0;
    transition: background-color 5000s ease-in-out 0s;
}

//Affichage du pictogramme pour voir le mot de passe
.w-visibility-toggle {
    opacity: .5;
    z-index: 1;
    border: 0;
}

//Texte en italique quand l'input est focus
.w-input:not(.w-dropdown) .w-input-element:not([type="radio"]):not([type="checkbox"]):focus,
.w-input:not(.w-dropdown) input[type="email"].w-input-element:focus {
    &#telephone+button.selected-flag {
        +.w-input-label {
            font-style: italic;
            transform: translateY(-115%);
            font-size: .75rem;
            transition: transform .2s ease-out, font-size .2s ease-out, opacity .2s linear, -webkit-transform .2s ease-out;
        }
    }

    +.w-input-label {
        font-style: italic;
        transform: translateY(-115%);
        font-size: .75rem;
        transition: transform .2s ease-out, font-size .2s ease-out, opacity .2s linear, -webkit-transform .2s ease-out;
    }
}

.w-input.w-nonempty {
    .w-input-element {
        border-color: #999;
    }

    .w-input-label {
        font-style: italic !important;
        font-size: .75rem !important;
    }
}


/***** Select *****/

.w-contact-form {
    padding-bottom: 0;

    .w-input.w-dropdown {
        border-radius: 0;
        border: solid 1px #c5c5c5;

        &.w-nonempty {
            border-color: #999;
        }
    }
}

.w-date-input {
    .w-input-label {
        font-style: italic;
        transform: translateY(-115%);
        font-size: .75rem;
    }
}

.w-input-label {
    color: #777777;
}

.w-form-line {
    margin-bottom: 25px;
}


/***** Check & Radio input style *****/

.w-checkbox-input,
.w-radio-input {
    height: unset;

    .w-input-element {
        &:checked+.w-input-label::before {
            border-color: white;
            background-color: black;
            box-shadow: inset 0 0 0 3px #fff;
            border: 1px solid #c5c5c5;
        }

        +.w-input-label {
            left: unset;
            top: unset;
            color: black;
            font-family: $primaryFont;
            font-size: .813rem;
            font-weight: 300;

            &::before {
                min-width: 18px;
                width: 18px;
                height: 18px;
                border: 1px solid lightgray;
                border-radius: 50%;
                background: transparent;
                box-shadow: none;
            }
        }
    }
}

.w-radio-group.w-has-error .w-input-element + .w-input-label::before {
    border-color: #ff6d6d!important;
    box-shadow: 0 0 0 1px #ff6d6d!important;
}


/***** buttons style *****/
.newlogin_page .w-newlogin-form .w-form-line.w-submit {
    min-width: 340px;
}

input[type=tel] {
    padding-left: 3.875rem !important;
    width: calc(100% - 1rem - 3.875rem) !important;

    +.selected-flag {
        border: unset;
        border-right: 1px solid #d9d9d9;
        background-color: unset;
    }
}


/***** Textarea *****/

.w-input.w-textarea {
    .w-input-element {
        border: solid 1px #c5c5c5;
        height: unset;
        padding-bottom: 0;
        padding: 2rem 1rem 0 1rem !important;

        +.w-input-label {
            transform: unset !important;
            transition: none;
            font-style: unset;
            top: 12px !important;
        }
    }

    &.w-nonempty {
        font-size: .75rem !important;
    }
}


/* NL - New otpin style */

.w-newspreferences-form .w-checkbox-input .w-input-element {
    &+.w-input-label {
        .multiline {
            strong {
                color: #9d9d9d;
                font-weight: 400;
            }
        }

        &::before {
            position: relative;
            width: 24px;
            height: 14px;
            display: block;
            background: lightgray;
            border-radius: 10px;
            box-shadow: none;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 1px;
            width: 12px;
            height: 12px;
            background: white;
            border-radius: 13px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .5);
            transition: all .2s ease-out;
        }
    }

    &:checked+.w-input-label {
        .multiline strong {
            color: black;
            font-weight: 500;
        }

        &::before {
            background: black;
            border-radius: 10px;
        }

        &::after {
            left: 13px;
        }
    }
}


/* ICON ERREUR FORM */
img.valid_form,
.img_error {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}


/* NEW USER FORM */
.w-newuser-form {
    .w-checkbox-input .w-input-element+.w-input-label::after {
        top: 50%;
        transform: translateY(-50%);
    }

    .w-checkbox-input .w-input-element:checked+.w-input-label::after {
        left: 18px;
        transform: translate(-50%, -50%);
    }
}

/* INFO MESSAGE ON LOGIN PAGE */
.login_bloc .form-msg.active {
    max-height: fit-content;
}