@import '../../_app/_src/scss/desktop/mixins/typeFace';

$outfit: (
    normal: (thin, extralight, light, regular, medium, semibold, bold, extrabold, black)
);

@include typeFace('outfit', $outfit);

$primaryFont: 'outfit', sans-serif;
$secondaryFont: Arial, sans-serif;
$tertiaryFont: 'outfit';

//FONT-WEIGHT SETUP - OUTFIT FONT
$thin : 100;
$extralight : 200;
$light : 300;
$regular : 400;
$medium : 500;
$semibold : 600;
$bold : 700;
$extrabold : 800;
$blackfont : 900;