.satellite_suivi_commande {

    #ariane {
        display: flex;
    }

    .return_container {

        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 98%;
        max-width: 1680px;
        height: 31px;
        margin: 0 auto;

        .btn_retour {
            font-size: clamp(12px, 0.677vw, 13px);
            font-weight: 500;
            color: $secondaryColor;
            letter-spacing: .05em;
            text-decoration: none;
            line-height: 30px;
            transition: all 0.5s ease-out;

            &:hover {
                color: $primaryColor;
            }
        }
    }

    .satellite_content {
        display: block;
        max-width: 1440px;
        width: 100%;
        margin: auto;
    }

    .holder_shipup {

        h1 {
            margin-top: 1rem;
            font-size: clamp(16px, 1.2vw, 22px);
            text-align: center;
        }

        button.shipup-tracker-search-submit {
            text-indent: initial;
        }
    }
}