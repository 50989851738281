.product_page .page_produit {
    max-width: $wrapperMedium;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    @media screen and (max-width: $wrapperMedium) {
        padding: 0 2rem;
    }

    .prod_top_wrapper {
        display: flex;
    }

    .wrapper_product_detail {
        width: 100%;
        display: flex;
        flex-direction: column;

        .bloc_wrapper {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
            row-gap: 12px;
            column-gap: 12px;

            >.bloc_infos.bord {
                outline: 10px solid $backgroundWhite;
                outline-offset: -10px;

                .wrapping {
                    width: calc(100% - 23px);
                    padding: 0 7%;
                    box-sizing: border-box;
                    height: fit-content;
                    display: block;
                    max-height: 300px;
                    margin: 20px 0;

                    @media screen and (min-width: 1124px) {
                        max-height: 350px
                    }

                    @media screen and (min-width: 1440px) {
                        max-height: 491px
                    }

                    tr {
                        font-weight: 700;
                        border-bottom: 1px solid #D9D9D9;
                    }
                }

                .os-scrollbar-track {
                    &.os-scrollbar-track-off {
                        background: #ece8e8;
                    }
                }

                .os-scrollbar {
                    &.os-scrollbar-vertical {
                        right: 0;
                    }
                }

                #batchPanel {
                    padding: 0 23px 0 7%;

                    .title {
                        width: calc(100% - 7px);
                        box-sizing: border-box;
                        max-height: calc(532px - 40px);
                        margin: 20px 0 7px;
                        display: block;
                        font-size: 18px;

                        .batchNumberProduct {
                            font-weight: normal;
                            margin-left: 3px;
                            font-size: 14px;
                        }
                    }

                    .contentTxt {
                        .batchProdWrapper {
                            margin-bottom: 20px;

                            a {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 10px;
                                flex-wrap: wrap;
                            }

                            .batchProdImg {
                                img {
                                    display: block;
                                    width: 101px;
                                }
                            }

                            .batchProdInfos {
                                max-width: 300px;
                                width: 100%;
                                flex: 1 1 0;
                                padding-right: 20px;
                            }

                            .batchProdTitle {
                                font-size: 16px;
                                font-weight: $bold;
                            }

                            .batchProdSubtitle {
                                font-size: 16px;
                                font-weight: 400;
                            }

                            .batchProdPriceWrapper {
                                margin: 5px 0;

                                .item_price {
                                    display: flex;

                                    .pricetag {
                                        font-size: 24px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: normal;

                                        .cents,
                                        .currency {
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: normal;
                                            vertical-align: .5em;
                                        }

                                        &.crossed {
                                            color: #8C8C8C;

                                            &::after {
                                                background-color: $black;
                                            }
                                        }
                                    }
                                }
                            }

                            .batchProdRef {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 78%;
                                flex-wrap: wrap;

                                .prodRef,
                                .prodQuantite {
                                    font-size: 14px;
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }
                }

                #bundle_caracs {
                    padding: 0 23px 0 7%;

                    .title {
                        width: calc(100% - 7px);
                        box-sizing: border-box;
                        max-height: calc(532px - 40px);
                        margin: 20px 0 7px;
                        display: block;
                        font-size: 18px;
                    }

                    .contentTxt {
                        .bundle_caracs_product {
                            margin-bottom: 10px;

                            .title_container {
                                font-size: 18px;
                                font-weight: 500;
                                cursor: pointer;
                                display: flex;
                                margin-bottom: 10px;
                                padding-left: 20px;

                                &.active {
                                    .caracPanel_arrow {
                                        transform: rotate(180deg);
                                    }
                                }

                                .caracPanel_arrow {
                                    background-image: url(../svg/spritesheet.svg);
                                    background-repeat: no-repeat;
                                    background-size: 600% auto;
                                    width: 20px;
                                    height: 20px;
                                    background-position: 80% 40%;
                                    position: absolute;
                                    right: 20px;
                                }
                            }

                            .bundle_caracs_container {
                                .dimensions_container {
                                    font-size: 18px;
                                    font-weight: 400;
                                    margin-bottom: 17px;
                                    display: flex;

                                    .bundle_caracs_download_pdf {
                                        display: block;
                                        background-image: url(../svg/spritesheet.svg);
                                        background-repeat: no-repeat;
                                        background-size: 600% auto;
                                        width: 21px;
                                        height: 21px;
                                        background-position: 0 20%;
                                        margin-left: 11px;
                                    }
                                }

                                .caracteristiques_container {
                                    margin-bottom: 40px;

                                    strong {
                                        font-size: 18px;
                                    }

                                    .specs {
                                        margin-top: 8px;

                                        .lineCarac {
                                            display: flex;
                                            width: 95%;
                                            border-bottom: 1px solid #d9d9d9;
                                            padding: 12px 0;
                                            margin-bottom: 0;

                                            label {
                                                width: 52%;
                                                font-weight: 500;
                                                font-size: 16px;
                                            }

                                            p {
                                                font-weight: 400;
                                                font-size: 16px;
                                                width: 48%;
                                            }
                                        }
                                    }
                                }

                                .montage_et_entretien_container {
                                    strong {
                                        font-size: 18px;
                                    }

                                    p {
                                        font-weight: 500;
                                    }

                                    .flex_container {
                                        display: flex;
                                        margin-top: 13px;

                                        >div {
                                            width: 50%;
                                            display: flex;

                                            .pdf_download {
                                                display: block;
                                                background-image: url(../svg/spritesheet.svg);
                                                background-repeat: no-repeat;
                                                background-size: 600% auto;
                                                width: 21px;
                                                height: 21px;
                                                background-position: 0 20%;
                                                margin-left: 11px;
                                            }
                                        }
                                    }
                                }

                                .lineCarac {
                                    display: flex;
                                    margin-bottom: 15px;

                                    label {
                                        width: 52%;
                                        font-weight: 400;
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                }

                @media screen and (max-width: 1350px) {
                    #bundle_caracs {
                        padding: 0 23px 0 7%;
                    }
                }

                .product_attributes {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;

                    .attribute_wrapper {
                        display: flex;

                        .attribute_image {
                            max-width: 48px;
                            height: auto;

                            img {
                                width: 100%;
                                height: auto;
                                vertical-align: bottom;
                            }
                        }
                    }
                }
            }

            >.bloc_infos {
                max-height: 100%;
                width: calc(50% - 6px);
                box-sizing: border-box;

                @media screen and (max-width: 1120px) {
                    width: 100%;
                }

                &.bord .contentTxt {
                    max-height: 300px;
                    margin: 20px 0;

                    @media screen and (min-width: 1124px) {
                        max-height: 350px
                    }

                    @media screen and (min-width: 1440px) {
                        max-height: 400px
                    }
                }

                span {
                    font-size: 18px;
                    font-weight: $bold;
                }

                table {
                    margin-top: 13px;
                    width: 100%;
                    margin-bottom: 20px;

                    tr {
                        td.light {
                            font-weight: 400;
                            width: 43%;
                            font-size: 16px;
                        }

                        td {
                            padding: 12px 0;
                        }
                    }
                }

                .cta_download {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;

                    .download {
                        cursor: pointer;

                        &::after {
                            width: 25px;
                            height: 25px;
                            margin-left: 10px;
                        }
                    }
                }

                img.img_detail {
                    max-width: 100%;
                    vertical-align: top;
                }

                &.double {
                    width: 100%;
                    max-width: 1077px;
                }
            }

            .bloc_infos.video {
                position: relative;

                iframe {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                }
            }

            .bloc_infos.bord.video {
                position: relative;
                padding-bottom: 49.5%;
                display: flex;
                justify-content: flex-start;

                iframe {
                    position: absolute;
                }
            }
        }
    }

    #gamme_color {
        width: 100%;
        margin-top: 70px;

        .cms-page-module.image-text-gamme {
            width: 100%;

            >div {
                margin: 0;
                top: 50%;
                transform: translateY(-50%);
                left: 1.5%;
            }
        }

        .gc_bloc_text {
            margin-left: 20px;
            position: relative;
            width: 380px;
            height: 300px;
            outline: 10px solid $pavlova;
            outline-offset: -10px;
            padding: 50px;
            margin-top: 30px;
        }

        .cms-page-column-title {
            font-size: 50px;
            color: $camouflageGreen;
            font-weight: $bold;
            line-height: 54px;
        }

        .cms-page-column-txt {
            line-height: 18px;
            margin-top: 20px;
        }

        .cms-page-column-cta {
            color: $camouflageGreen;
            position: absolute;
            bottom: 50px;
            right: 50px;
            height: 20px;
            font-weight: $extrabold;
            font-weight: 15px;
            text-transform: uppercase;

            &::after {
                content: '';
                display: flex;
                width: 20px;
                height: 20px;
                margin-left: 10px;
                mask-image: url(../svg/spritesheet.svg);
                mask-position: 100% 0;
                mask-size: 600% auto;
                background: var(--parenttextcolor, #000);
            }
        }
    }

    /* Swiper associations */
    .carousel_swiper {
        padding-left: 10px;
        width: 100%;

        .bloc_swiper {
            width: 330px;
            height: 330px;
            margin-left: 10px;
            border: 1px solid $black;
        }

        &:not(.swiper-initialized) {
            display: flex;

            .item.wrapperProdVertical {
                box-sizing: content-box;
                /* Due to content-box */
                max-width: calc((100% - 30px) / 4);
                width: 100%;
            }
        }

        /* Remove padding on swiper initialized */
        &.swiper-initialized {
            .item.wrapperProdVertical {
                padding-right: 0;
            }
        }
    }

    .label_swiper {
        max-width: 328px;
        width: 100%;
        /* This height should be swiper-slides img's width on 1920px screen */
        height: 328px;
        position: relative;

        h3 {
            margin-top: 60px;
            margin-left: 40px;
            font-size: 50px;
            font-weight: $bold;
            line-height: 54px;
        }
    }

    .similar_product_pagi,
    .complementary_product_pagi {
        width: 90px;
        position: absolute;
        bottom: 10px;
        right: 20px;
        display: flex;
        justify-content: space-between;

        .swiper-button-prev,
        .swiper-button-preva {
            left: inherit;
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: initial;
            top: unset;
            left: unset;
        }

        .swiper-button-next,
        .swiper-button-nexta {
            &::before {
                @include maskIconCoord(6, 6, 6);
            }
        }

        .swiper-button-prev,
        .swiper-button-preva {
            &::before {
                @include maskIconCoord(8, 6, 6);
            }
        }

        .swiper-button-next,
        .swiper-button-nexta,
        .swiper-button-prev,
        .swiper-button-preva {
            cursor: pointer;
            background-image: inherit;

            &::before {
                content: '';
                display: flex;
                width: 25px;
                height: 25px;
                mask-image: url(../svg/spritesheet.svg);
                background-repeat: no-repeat;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            &::before {
                background-color: var(--parenttextcolor, $primaryColor);
            }
        }

        .swiper-button-nexta,
        .swiper-button-preva {
            &::before {
                background-color: var(--parentbgcolor, $white);
            }
        }

        .swiper-button-next,
        .swiper-button-nexta {
            right: inherit;
        }
    }

    #similar_product {
        width: 100%;
        display: flex;
        margin-top: 90px;

        .label_swiper {
            color: var(--parenttextcolor, $black);
            background-color: var(--parentbgcolor, $white);
        }

        .wrapper_price {
            position: relative;
        }

        .old-price {
            position: absolute;
            font-size: 13px;
            white-space: nowrap;
            bottom: 0;
            right: 0;
        }
    }

    #complementary_product {
        width: 100%;
        display: flex;
        margin-top: 35px;

        .label_swiper {
            color: var(--parentbgcolor, $white);
            background-color: var(--parenttextcolor, $black);
            height: 326px;
        }

        .wrapper_price {
            position: relative;
        }

        .old-price {
            position: absolute;
            font-size: 13px;
            white-space: nowrap;
            bottom: 0;
            right: 0;
        }
    }

    #clients_review {
        width: 1280px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 90px;
        height: 320px;
        border: 1px solid $black;
    }

    #recent_see {
        margin-top: 80px;
        width: 100%;
        margin-bottom: 85px;

        /* Set relative for swiper buttons */
        #gondole_derniers_articles_vus {
            position: relative;

            .wrapper_price {
                position: relative;
            }

            .old-price {
                position: absolute;
                font-size: 13px;
                white-space: nowrap;
                bottom: -15px;
                right: 0;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            bottom: -11px;
            top: unset;
            height: initial;
            background-image: none;
            z-index: 2;

            &::before {
                width: 20px;
                height: 20px;
            }
        }

        .swiper-button-next {
            left: 86.5%;
        }

        .swiper-button-prev {
            left: 83%;
        }

        .swiper-pagination {
            bottom: 22px;
            left: 50%;
            transform: translate(50%);
        }

        .swiper-scrollbar {
            margin-left: 11.905%;
            width: 67.679%;
            height: 3px;

            .swiper-scrollbar-drag {
                height: 3px;
            }
        }

        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 10px;
            opacity: 1;
            border: solid 1px $dune;
            background: transparent;
        }

        .swiper-pagination-bullet-active {
            background: $dune;
        }

        #derniers_articles_vus {
            font-size: 50px;
            font-weight: $bold;
            line-height: 40px;
            text-transform: uppercase;
            text-align: center;
        }

        #productVisitedSwiper {
            width: 100%;
            display: flex;
            overflow: hidden;
            margin-top: 25px;

            &.swiper-initialized {
                /* Add padding-bottom when initialized */
                padding-bottom: 30px;
            }

            /* Hide swiper nav when not initialized */
            &:not(.swiper-initialized) {

                &+.swiper-scrollbar,
                &+.swiper-pagination {
                    display: none;

                    &+.swiper-button-prev {
                        display: none;

                        &+.swiper-button-next {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .product_left_wrapper {
        width: 64.1071%;
        position: relative;

        @media screen and (max-width: 1680px) {
            width: 54%;
        }
    }

    .product_left_image {
        display: flex;
        align-items: flex-start;
        width: 100%;
        box-sizing: border-box;
        position: relative;

        .swiper-button-prev {
            width: 45px;
            height: 45px;
            left: 180px;
            background-image: none;

            &.swiper-button-disabled {
                display: none;
            }
        }

        .swiper-button-next {
            width: 45px;
            height: 45px;
            right: 6px;
            background-image: none;

            &.swiper-button-disabled {
                display: none;
            }
        }


    }



    .product_info_wrapper {
        align-self: baseline;
        position: sticky;
        top: calc(178px + 24px + 10px);
        /* header height + arial height + padding-top */
        max-width: 553px;
        /* + 50px = 603px = right wrapper */
        width: 35.8929%;
        padding-left: 50px;
        padding-top: 30px;
        box-sizing: border-box;

        @media only screen and (max-width: 1680px) {
            width: 46%;
        }

        .product_eclat_attributes {
            position: absolute;
            top: -1px;
            display: flex;
            flex-wrap: wrap;
            /* 25px (= size of wishlist picto) */
            max-width: calc(100% - 25px);
            margin: 0 -3px;

            >* {
                margin: 0 3px 0;
            }

            img {
                vertical-align: bottom;
            }
        }

        .product_info {
            display: flex;
            flex-wrap: wrap;

            &.with_crossed_pricetag {
                .product_item_name {
                    max-width: 65%;
                }

                .item_price_wrapper {
                    max-width: 35%;
                }
            }

            #product_selects {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                #ligne_couleur {
                    width: 73%;
                    order: 0;

                    @media screen and (max-width : 1250px) {
                        width: 100%;
                    }
                }

                .size_qnt {
                    width: 100%;
                    order: 2;
                }

                #product_addcart {
                    width: 100%;
                    order: 3;
                }

                #alma-widget {
                    width: min-content;
                    order: 1;
                    margin-bottom: 1rem;
                    display: flex;

                    svg {
                        path {
                            fill: #fa5022;
                        }
                    }

                    .alma-payment-plans-container {
                        width: max-content;
                    }

                    .alma-payment-plans-active-option {
                        background-color: #fa5022;
                        color: $white;
                    }

                    .alma-payment-plans-payment-info {
                        font-weight: 600;
                    }

                }

                .stock_delivery {
                    width: 100%;
                    order: 4;
                }

                #ref_product {
                    width: 100%;
                    order: 5;
                }

                .product_feats_wrapper {
                    width: 100%;
                    order: 6;
                }

                .wrapper_reinsurance {
                    width: 100%;
                    order: 7;
                }
            }
        }

        .product_item_name {
            max-width: 85%;
            width: 100%;

            h1 {
                display: flex;
                flex-direction: column;

                strong {
                    font-size: 27px;
                    font-weight: $bold;
                    line-height: 1.2;
                }

                small {
                    font-size: 17px;
                    line-height: 1;
                    font-weight: 400;

                    @media screen and (min-width : 1600px) {
                        max-width: 72%;
                    }

                    @media screen and (min-width : 1400px) {
                        max-width: 65%;
                    }

                    @media screen and (max-width : 1224px) {
                        max-width: 50%;
                    }
                }
            }
        }

        .item_price_wrapper {
            position: relative;
            max-width: 15%;
            width: 100%;
            margin-top: -14px;
            padding-bottom: 25px;

            .price_and_wishlist {
                display: flex;
                flex-direction: column-reverse;
                position: relative;

                .ecoPart {
                    position: absolute;
                    font-size: 11px;
                    white-space: nowrap;
                    bottom: -7px;
                    right: 0;
                }

                .old-price {
                    position: absolute;
                    font-size: 13px;
                    white-space: nowrap;
                    bottom: -22px;
                    right: 0;
                }

                .item_price {
                    display: flex;
                    justify-content: flex-end;
                    align-items: baseline;
                    /*  Add 14px margin due to -14px on wrapper to keep spacings */
                    margin-top: 14px;

                    @media screen and (max-width : 1024px) {
                        flex-wrap: wrap;
                    }

                    .pricetag {
                        font-size: 35px;

                        &.crossed {
                            font-size: 25px;

                            .cents {
                                font-size: 17px;
                            }
                        }

                        .cents {
                            font-size: 20px;
                        }

                        .currency {
                            font-size: 15px;
                            vertical-align: 0.85em;
                        }
                    }
                }

                #addToWishlistButton {
                    border: 0;
                    width: auto;
                    height: auto;

                    span {
                        display: none;
                    }

                    .bgSprite::before {
                        width: 21px;
                        height: 21px;
                    }

                    i {
                        justify-content: end;
                    }

                    &.existToWishlistButton .bfr.bgSprite.wish::before {
                        background-position: 40% 60%;
                    }
                }
            }
        }

        #product_selects {
            .size_qnt {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;

                #ligne_pointure {
                    display: inline-block;
                    width: 78.12%;
                    height: 48px;

                    @media screen and (max-width: 1480px) {
                        width: 72%;
                    }

                    /* Size select element */
                    .size_wrapper {
                        height: 100%;
                    }

                    #tailleProd {
                        font-size: 16px;
                        padding-left: 15px;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        cursor: pointer;
                        font-family: $primaryFont;
                    }
                }

                #ligne_qte,
                #ligne_pointure {
                    position: relative;

                    &::after {
                        pointer-events: none;
                        cursor: pointer;
                        position: absolute;
                        top: 50%;
                        right: 1rem;
                        transform: translateY(-50%);
                        content: '';
                        width: 12px;
                        height: 12px;
                        @include bgIconPath('../svg/spritesheet.svg');
                        @include bgIconCoord(17, 6, 6);
                    }
                }

                #ligne_pointure {
                    &.one_size {
                        pointer-events: none;

                        &::after {
                            content: '';
                            display: none;
                        }
                    }
                }

                /* QUANTITY SECTION */
                /* Select element's parents */
                #ligne_qte {
                    max-width: 111px;
                    width: 20.1%;
                    height: 48px;
                    display: inline-block;

                    @media screen and (max-width: 1480px) {
                        width: 25%;
                    }

                    &.without_stock {
                        &::after {
                            display: none;
                        }

                        #qteProd {
                            pointer-events: none;
                            text-align: center;
                            text-transform: uppercase;
                            font-size: 12px;
                            padding: 0 5px;
                        }
                    }
                }

                #liste_qte {
                    height: 100%;
                }

                /* Quantity select element */
                #qteProd {
                    cursor: pointer;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    padding: 0 15px;
                }
            }

            #ligne_couleur {
                margin-top: 3px;
                display: flex;
                margin-bottom: 1rem;

                .prod_listes {
                    display: flex;
                    align-items: center;
                }

                #more_coloris {
                    cursor: pointer;
                    margin-left: 1rem;
                    margin-right: 1.6rem;

                    @media screen and (max-width: 1649px) {
                        margin-right: 3.6rem;
                    }

                    @media screen and (max-width: 1649px) {
                        margin-right: 3.1rem;
                    }

                    @media screen and (max-width: 1558px) {
                        margin-right: 2.8rem;
                    }

                    @media screen and (max-width: 1499px) {
                        margin-right: 2.4rem;
                    }

                    @media screen and (max-width: 1430px) {
                        margin-right: 2rem;
                    }

                    @media screen and (max-width: 1387px) {
                        margin-right: 3.5rem;
                    }

                    @media screen and (max-width: 1300px) {
                        margin-right: 3rem;
                    }

                    @media screen and (max-width: 1246px) {
                        margin-right: 3rem;
                    }
                }

                .more_coloris {
                    display: block;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                }

                .choices_list {
                    display: flex;
                    flex-wrap: wrap;

                    .form_itm {
                        margin-right: 2px;
                        position: relative;

                        &:nth-child(n + 5) {
                            display: none;
                        }

                        .input_check {
                            cursor: pointer;

                            &:checked {
                                /* Disable pointer events on checked color */
                                pointer-events: none;

                                &+label {
                                    border: 1px solid rgba(0, 0, 0, 0.2);
                                    pointer-events: none;
                                }
                            }
                        }

                        input[type='radio'] {
                            opacity: 0;
                            position: absolute;
                            height: 100%;
                            width: 100%;
                        }

                        label {
                            cursor: pointer;
                            display: block;
                            text-align: center;
                            width: 63px;
                            height: 63px;
                            background-position: center;
                            background-size: 85%;
                            background-repeat: no-repeat;
                            margin-left: auto;
                            margin-right: auto;
                            border: 1px solid transparent;
                            transition: all 0.3s ease;

                            span {
                                line-height: 65px;
                            }
                        }

                        .cart_thumb_holder {
                            display: none;
                        }
                    }

                    &.show_all .form_itm {
                        display: block;
                    }
                }
            }

            #product_addcart {
                .prod_btn_wrapper {
                    display: flex;
                    justify-content: space-between;
                    border: 0;

                    #btn_add_cart {
                        width: 100%;
                    }

                    .button {
                        letter-spacing: 0;
                    }

                    .btnAddBasketWrapper,
                    .pdvWrapper,
                    .bloc_add_alert {
                        position: relative;
                        max-width: 270px;
                        width: 48.83%;
                    }

                    .bloc_add_alert {
                        .button {
                            border: 1px solid $red;
                            color: $red;

                            span {
                                font-size: 14px;
                            }
                        }
                    }

                    .bloc_add_alert_confirmation {
                        >p {
                            font-size: 14px;
                        }

                        .w-submit-button {
                            margin-top: 20px;
                            @extend .dark;
                        }
                    }

                    .btnAddBasketWrapper {
                        position: relative;
                        margin-right: 5px;
                    }

                    .pdvWrapper {
                        margin-left: 5px;

                        .btnFindRetailer {
                            text-transform: uppercase;

                            &.loading {
                                font-size: 0;
                                background: $black url(../svg/three_dots_w.svg) no-repeat center;
                                background-size: auto 20%;
                            }
                        }
                    }

                    .pdvWrapper {
                        margin-left: 5px;

                        .btnFindRetailer {
                            text-transform: uppercase;
                        }
                    }

                    #ligne_qte {
                        height: 50px;
                        display: inline-block;
                        width: 48%;
                        position: relative;

                        &.without_stock {
                            pointer-events: none;

                            &::after {
                                display: none;
                            }
                        }

                        &::after {
                            pointer-events: none;
                            cursor: pointer;
                            position: absolute;
                            top: 50%;
                            right: 1rem;
                            transform: translateY(-50%);
                            content: '';
                            width: 12px;
                            height: 12px;
                            background-image: url(../svg/spritesheet.svg);
                            background-size: 600% auto;
                            background-position: 80% 40%;
                        }

                        .qte_select_wrapper {
                            height: 100%;

                            #qteProd {
                                cursor: pointer;
                                position: relative;
                                height: 100%;
                                width: 100%;
                                padding: 0 15px;
                            }
                        }
                    }
                }
            }

            .stock_delivery {
                width: 100%;
                display: flex;
                padding-top: 25px;

                &.without_stock {
                    .chez_vous_entre {
                        display: none;
                    }
                }

                .stock_info {
                    padding-left: 25px;
                    position: relative;

                    &::before {
                        content: '';
                        width: 17px;
                        height: 17px;
                        position: absolute;
                        left: 0;
                        top: 4px;
                        border-radius: 50%;
                    }
                }

                .stock_info.yes {
                    &::before {
                        background-color: $green;
                    }
                }

                .stock_info.no {
                    &::before {
                        background-color: $red;
                    }
                }

                .delivery {
                    margin-left: 20px;

                    &::before {
                        width: 26px;
                        height: 26px;
                        margin-right: 8px;
                    }
                }
            }

            .texte_bloc_noel {
                order: 5;
                font-size: 16px;
                color: $cognac;
                line-height: 1;
                font-family: $primaryFont;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                font-weight: 600;

                &:before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                    background-image: url(../img/noel.jpg);
                    background-repeat: no-repeat;
                }
            }

            #ref_product {
                color: $cloud;
                font-size: 16px;

                p {
                    font-weight: $light;

                    span {
                        font-weight: $medium;
                    }
                }
            }

            .product_feats_wrapper {
                border-bottom: 1px solid $black;
                padding-bottom: 30px;

                ul {
                    margin-top: 5px;

                    .thumb {
                        justify-content: inherit;
                        min-height: 45px;

                        &.gammeColor {
                            color: var(--parenttextcolor, $black);

                            .desc_arrow {
                                background-color: var(--parenttextcolor, $primaryColor );
                            }
                        }

                        .desc_arrow {
                            content: '';
                            mask-image: url(../svg/spritesheet.svg);
                            @include maskIconCoord(6, 6, 6);
                            width: 20px;
                            height: 20px;
                            background-color: $primaryColor;
                            margin-left: 10px;
                        }

                        .thumblnk {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        h2 {
                            line-height: 45px;
                            font-size: 18px;
                            font-weight: $bold;
                            font-size: 18px;
                        }

                        &.recovery_product {
                            .desc_arrow {
                                transition: all linear 0.3s;
                            }

                            &.active {
                                .desc_arrow {
                                    transform: rotate(90deg);
                                }

                                .thumbcontent {
                                    transition: opacity linear 0.3s;
                                    opacity: 1;
                                    height: auto;
                                    visibility: visible;

                                    a {
                                        text-decoration: underline;
                                        font-weight: 600;
                                    }
                                }
                            }

                            .thumbtitle {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            }

                            .thumbcontent {
                                transition: opacity linear 0.3s;
                                opacity: 0;
                                height: 0;
                                visibility: hidden;
                            }
                        }

                        &.net_reviews {
                            .thumblnk {
                                position: relative;
                                justify-content: flex-start;

                                .netreviews_stars {
                                    padding-left: 15px;

                                    .netreviews_review_rate_and_stars {
                                        display: flex;
                                        position: relative;
                                        justify-content: flex-start;
                                        align-items: center;
                                        cursor: pointer;
                                        flex-wrap: wrap;

                                        div:not(.netreviews_reviews_rate) {
                                            position: absolute;
                                            left: 0;
                                            top: 3px;
                                            margin: auto;
                                            transform: inherit;
                                            white-space: nowrap;

                                            .nr-icon {
                                                color: $reviewstar;
                                                text-shadow: 1px 0px $reviewstar, -1px 0px $reviewstar;
                                                font-size: 15px;
                                                letter-spacing: 3px;

                                                &.grey {
                                                    color: $white !important;
                                                }
                                            }
                                        }

                                        .netreviews_reviews_rate {
                                            width: 100px;
                                            left: 96px;
                                            font-family: $primaryFont;
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 1;
                                            position: relative;
                                            top: -5px;
                                        }
                                    }
                                }

                                .desc_arrow {
                                    position: absolute;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }

            .wrapper_reinsurance {
                display: flex;
                width: 100%;
                margin: 25px auto;
                justify-content: space-between;
                align-items: baseline;

                .reisurance_item {
                    width: 33%;
                }

                .reisurance_item>a {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    .reisurance_item_link {
                        margin-bottom: 10px;

                        &:before {
                            width: 25px;
                            height: 25px;
                        }

                        &.domicilenoel,
                        &.garantienoel {
                            &:before {
                                background-image: url(../img/livraison.png);
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }

                        &.deliveryfp::before {
                            width: 43px;
                            padding-bottom: 4px;
                            box-sizing: content-box;
                        }
                    }

                    .title {
                        text-transform: uppercase;
                        font-size: 13px;
                        line-height: 18px;
                        font-weight: $bold;
                    }

                    .show_on_fp_only {
                        display: block !important;

                        &+.title {
                            display: none !important;
                        }
                    }

                    .subtitle {
                        font-size: 13px;
                        line-height: 1;
                        font-weight: $regular;
                    }
                }
            }
        }

        #bloc_add_alert {
            #btn_add_alert {
                border: 1px solid $black;
            }

            #keep_alive_stock {
                .w-alertestock-form {
                    width: 100%;

                    .w-submit-button {
                        @extend .dark;
                    }
                }
            }
        }
    }
}

#visual_scroller_wrapper {
    position: relative;
    margin-right: 10px;
    margin: 60px 10px 60px 0;
    height: 100%;
    width: 167px;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.3s ease-out;

    &.is-active {
        opacity: 1;
    }

    .swiper-slide {
        height: auto !important;
    }

    .thumbPrev,
    .thumbNext {
        background-color: $white;
        width: 100%;
        height: 1.5rem;
        margin: auto;
        cursor: pointer;
        position: relative;
        z-index: 5;
        bottom: -2.5rem;
        left: 0;
        right: 0;
        position: absolute;

        &.swiper-button-disabled,
        &.swiper-button-lock {
            opacity: 0.5;
            /* display: block; */
            cursor: default;
        }
    }

    .thumbPrev {
        transform: rotate(180deg);
        bottom: inherit;
        top: -2.5rem;
    }

    &.no_swiper {

        .thumbPrev,
        .thumbNext {
            display: none;
        }
    }

    #visual_scroller {
        height: 100%;
        width: 167px;
        box-sizing: border-box;

        &.swiper-container {
            &:not(.swiper-vertical) {
                .swiper-wrapper {
                    .swiper-slide {
                        width: auto;
                        height: auto;
                    }
                }
            }

            .swiper-wrapper {
                flex-direction: column;

                .swiper-slide {
                    margin-bottom: 40px;

                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            }

            .swiper-slide-thumb-active {
                img {
                    border: 1px solid rgba(0, 0, 0, 0.2);
                }
            }
        }

        img {
            max-width: 100%;
            height: auto;
            box-sizing: border-box;
        }
    }
}

#video_holder {
    position: relative;

    &:before {
        content: ' ';
        display: block;
        background: transparent url('../img/play.png') center center no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

#jqzoom {
    width: calc(100% - 177px);
    height: 100%;
    max-width: 900px;
    max-height: 900px;

    .swiper-container {
        overflow: unset;
    }

    .swiper-slide {
        img {
            display: block;
            width: 100%;
            cursor: zoom-in;
            height: unset;
        }

        &.has_video {
            .block_vid {
                position: relative;
                padding-bottom: calc(900 / 900 * 100%);

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .swiper-pagination {
        bottom: 30px;
        left: 0;
        right: 0;
        display: flex;
        width: calc(100% - 177px);
        flex-direction: row;
        justify-content: center;
        z-index: 5;
        margin: auto;

        &.outarea {
            bottom: -25px;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            border: 1px solid $black;
            background: transparent;
            opacity: 1;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &.swiper-pagination-bullet-active {
                background: $black;
            }
        }
    }

    /* Hide swiper nav when not initialized */
    &:not(.swiper-initialized) {
        +.swiper-pagination {
            display: none;
        }

        +.swiper-button-prev {
            display: none;

        }

        +.swiper-button-next {
            display: none;
        }
    }

}



.product_page {
    .page_produit #recent_see .swiper-scrollbar {
        display: none;
    }

    .product_left_image #jqzoom {

        .swiper-slide.swiper-slide-active,
        .swiper-slide.swiper-slide-prev,
        .swiper-slide.swiper-slide-next {
            #img_large {
                height: auto;
            }
        }
    }
}

.product_page .page_produit #recent_see .swiper-pagination {
    bottom: unset;
    left: unset;
    transform: unset;
    margin-left: 11.905%;
    width: 67.679%;
    height: 3px;

    .swiper-pagination-progressbar-fill {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

/* Bloc avis verifier */
#w-netreviews-comments {
    width: 100%;
    max-width: 1280px;
    margin: 85px auto 90px;

    .nrc_customizable_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .main_title_nrc {
            font-size: 50px;
            font-weight: $bold;
            line-height: 40px;
            text-transform: uppercase;
            text-align: left;
            width: 100%;
        }

        .nrc_mark {
            width: 360px;
            margin-right: 4.6875%;

            .title_mark {
                font-size: 17px;
                font-weight: $bold;
                text-transform: uppercase;
                margin: 20px 0
            }

            .nrc_average_rating {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 24px;

                .nr_rate {
                    span {
                        display: inline-block;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center center;
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;

                        &.star_full {
                            background-image: url("../img/star_full.png");
                        }

                        &.star_mid_full {
                            background-image: url("../img/star_mid_full.png");
                        }

                        &.star_empty {
                            background-image: url("../img/star_empty.png");
                        }

                    }
                }

                .nrc_average_mark {
                    font-size: 16px;
                    font-weight: $regular;
                    margin-right: 24px;
                }

                .total_reviews {
                    font-size: 16px;
                    font-weight: $regular;
                }
            }

            .nrc_chart {
                .nrc_chart_single {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;

                    .nrc_chart_value {
                        font-size: 16px;
                        font-weight: $regular;
                        display: inline-block;
                        width: 28px;
                        text-align: left;
                    }

                    progress {
                        /* Reset the default appearance */
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        width: 287px;
                        height: 9px;
                        border-radius: 15px;
                        border: none;

                        &::-webkit-progress-bar,
                        &::-moz-progress-bar {
                            background-color: rgba($primaryColor, 0.04);
                            border-radius: 15px;
                        }
                    }

                    progress::-webkit-progress-value {
                        background-color: $secondaryColor;
                        border-radius: 15px;
                    }

                    progress::-webkit-progress-bar {
                        background-color: rgba($primaryColor, 0.04);
                        border-radius: 15px;
                    }

                    progress::-moz-progress-bar {
                        background-color: $secondaryColor;
                        border-radius: 15px;
                        border: none;
                    }

                    .nrc_chart_percent {
                        font-size: 16px;
                        font-weight: $regular;
                        display: inline-block;
                        width: 45px;
                        text-align: right;
                    }
                }
            }
        }

        #nrc_comments {
            width: calc(95.3125% - 360px);
            position: relative;
            top: -15px;

            .title_mark {
                font-size: 17px;
                font-weight: $bold;
                text-transform: uppercase;
                line-height: 1;
                margin-bottom: 25px;
            }

            .swiperNetreviews {
                width: 100%;

                .swiper-wrapper {
                    width: 100%;

                    .swiper-slide {
                        background-color: rgba($primaryColor, 0.04);
                        padding: 22px 32px;
                        width: 49.3%;
                        box-sizing: border-box;
                        margin-right: 10px;
                        min-height: 246px;
                        height: auto;

                        .nrc_content_comment {
                            .name_date {
                                font-size: 14px;
                                font-weight: $regular;
                                text-transform: uppercase;
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 3px;

                                p {
                                    width: 49%;

                                    &:nth-child(1) {
                                        text-align: left;
                                    }

                                    &:nth-child(2) {
                                        text-align: right;
                                    }
                                }
                            }

                            .nrc_rate {
                                margin-bottom: 17px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                span {
                                    display: inline-block;
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    background-position: center center;
                                    width: 18px;
                                    height: 18px;
                                    margin-right: 5px;

                                    &.star_full {
                                        background-image: url("../img/star_full.png");
                                    }

                                    &.star_mid_full {
                                        background-image: url("../img/star_mid_full.png");
                                    }

                                    &.star_empty {
                                        background-image: url("../img/star_empty.png");
                                    }

                                }
                            }

                            .nrc_comment {
                                font-size: 16px;
                                font-weight: $regular;
                                line-height: 18px;
                                margin-bottom: 20px;

                                span.nrc_comment_see_more {
                                    cursor: pointer;
                                    text-decoration: underline;
                                }
                            }

                            .nrc_comment_disclaimer,
                            .nrc_comment_order_date {
                                font-size: 13px;
                                font-weight: $regular;
                            }
                        }
                    }
                }
            }

            .prevSlideFp,
            .nextSlideFp {
                display: block !important;
                width: 25px;
                height: 25px;
                background-image: url(../svg/spritesheet.svg);
                background-repeat: no-repeat;
                background-size: 600%;
                position: absolute;
                top: 0;
                cursor: pointer;
            }

            .prevSlideFp {
                background-position: 20% 20%;
                right: 70px;
            }

            .nextSlideFp {
                background-position: 100% 0;
                right: 0;
            }
        }
    }
}

// Reviews stars

.star_empty,
.star_full,
.star_mid_full {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: .35em;
}

.star_empty {
    background: transparent url(../svg/empty_star.svg) no-repeat center;
    background-size: cover;
}

.star_full {
    background: transparent url(../svg/full_star.svg) no-repeat center;
    background-size: cover;
}

.star_mid_full {
    background: transparent url(../svg/half_star.svg) no-repeat center;
    background-size: cover;
}

div.item_ratings {

    display: flex;
}

div.netreviews_reviews_rate {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 10px;

    .star_empty,
    .star_full,
    .star_mid_full {
        width: 1.5em;
        height: 1.5em;
    }
}

body.product_page.bandeauDelivery {
    #pdvBox {
        #content_pdvBox_new {
            .formulaire {

                .w-input.w-text-input,
                .w-input.w-textarea {
                    .inputErr {
                        transition: border 0.2s ease-out, color 0.2s ease-out;
                        border-color: $borderRed;
                    }
                }

                #wrapper_optin,
                #wrapper_optin_sms {
                    &.inputErr {
                        .choose {
                            label {
                                color: $red;

                                &::before {
                                    border-color: $red !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//SW-12447 before implementing EVO in BO
.header_main_wrapper {

    #menu_rangement,
    #menu_cuisine,
    #menu_salle-de-bain,
    #menu_dressing,
    #menu_buanderie {
        color: #000 !important;

        &:hover {
            color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;

            &::after {
                background-color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
            }
        }
    }
}