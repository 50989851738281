.wrapper_tunnel {
    margin-bottom: 30px;
    display: flex;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;

    .w-client-form {
        align-items: inherit;
    }

    .login_image {
        height: 670px;
        width: 490px;
        position: relative;
    }

    .newlogin_page {
        margin-left: 80px;
        padding-top: 0;
        justify-content: center;

        .login_bloc.second {
            padding-top: 30px;
        }

        .login_bloc {
            width: 100%;
            height: inherit;
            align-items: baseline;
            padding-bottom: 0;

            &:last-of-type {
                .title h4 {
                    font-size: 14px;
                    margin-bottom: 0;
                }
            }

            .login_charte {
                font-size: 13px;
                text-align: justify;
                margin-top: 30px;

                a {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .w-oneclick-connect {
                gap: 1.3rem;

                .w-social-btn {
                    border: 0;
                    position: relative;
                    margin-left: 0;

                    div {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .fb-connect {
                    order: 3;
                }

                #googleConnect {
                    order: 2;
                }

                .ap-connect {
                    order: 1;
                }

                .w-social-btn:hover {
                    background-color: #C0C0C0;
                }

                #googleConnect {
                    width: 38.39px;
                    height: 38.39px;
                    background: $nearWhite;

                    &:hover {
                        background: $silver
                    }

                    .g_id_signin {
                        &>div {
                            &>div {
                                div[aria-labelledby="button-label"] {
                                    background-color: $nearWhite;
                                    height: 38.39px;
                                    width: 38.39px;
                                    border-radius: 0;
                                    border: none;
                                    box-shadow: none;

                                    div:last-of-type {
                                        justify-content: center !important;

                                        &>div {
                                            height: 20px !important;
                                            width: 20px !important;
                                        }
                                    }

                                    &:hover {
                                        background-color: $silver
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .title.my_account {
                display: none;
            }

            .title {
                margin-bottom: .8rem;

                .subtitle {
                    font-size: 16px;
                }

                h3 {
                    font-size: 20px;
                    font-weight: bold;
                    text-align: left;
                    letter-spacing: 0;
                    font-family: inherit;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                h2 {
                    letter-spacing: inherit;
                    font-size: 16px;
                    text-align: left;
                    font-family: inherit;
                }

                .subtitle {
                    text-align: left;
                }

                #subtitleLogin {
                    font-size: 16px;
                    text-align: left;
                }

                h3,
                h4,
                h1 {
                    text-transform: none;
                }

                h1 {
                    margin: 2.7rem 0 .75rem;
                }

                h4 {
                    font-size: .9rem;
                }

                h2.rs_quick_access {
                    text-transform: none;
                }
            }

            .w-newlogin-form {
                width: 100%;

                .w-form-line {
                    &.w-submit {
                        .w-submit-button {
                            &.w-loader {
                                font-size: 0;
                                background: $black url(../svg/three_dots_w.svg) no-repeat center;
                                background-size: auto 20%;

                                &.loading {
                                    span {
                                        background: transparent !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            p.subtitle {
                font-size: 12px;
                color: grey;
                line-height: 1.3;
                letter-spacing: .01rem;
            }

            .login_form_wrapper {
                .w-input-label {
                    font-size: 14px;
                    color: #777777;

                    span {
                        font-style: normal;
                    }
                }

                input {
                    border-radius: 0;
                }

                .w-lines-group {
                    margin-bottom: 0;

                    .colon {
                        display: none;
                    }

                    .w-fieldset-label {
                        font-family: inherit;
                        font-size: 14px;
                        margin-left: 0;
                        margin-bottom: 1rem;
                        letter-spacing: normal;
                    }

                    .optin_container {
                        justify-content: inherit;
                        flex-direction: row;
                        align-items: center;

                        .w-input-note.w-input-error {
                            position: absolute;
                            left: 130px;
                            top: -2px;
                            text-align: left;
                        }

                        .w-form-line {
                            justify-content: flex-start;

                            .w-input-label {
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                color: $primaryColor;
                            }
                        }

                        .optin-consent-line {
                            width: fit-content;
                            margin-bottom: 0;
                        }

                        .info_site {
                            position: absolute;
                            top: -1.1rem;

                            a {
                                color: grey;
                                font-size: .7rem;
                            }
                        }

                        .w-radio-group {
                            .w-input.w-radio-input {
                                &:nth-of-type(1) {
                                    margin-right: fs-vw(10, 1920);
                                }

                                &:nth-of-type(2) {
                                    display: none;
                                }
                            }

                            .w-radio-input .w-input-element {
                                +.w-input-label {
                                    &::before {
                                        min-width: unset;
                                        width: 1em;
                                        height: 1em;
                                        border-radius: 0;
                                    }
                                }

                                &:checked {
                                    +.w-input-label::before {
                                        box-shadow: inset 0 0 0 0.125rem #fff;
                                        border-color: $black;
                                    }
                                }
                            }
                        }

                        .knowMoreOptin {
                            display: inline-block;
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            letter-spacing: normal;
                            color: $primaryColor;
                            text-decoration-line: underline;
                            margin-left: 0;
                            border: 0;
                        }

                    }
                }

                .intro {
                    // Asked by customer
                    display: none;
                    font-size: 12px;
                    text-align: initial;
                }

                .w-submit {
                    max-width: 13rem;

                    .w-submit-button {
                        height: 50px;
                        width: 270px;
                        font-size: 15px;
                        @extend .dark;
                    }
                }

                .w-lostpass-form {
                    .w-submit {
                        max-width: 15rem;
                    }
                }
            }

            .w-pwd-reset {
                font-size: 16px;
                letter-spacing: normal;
                margin-bottom: .7rem;
                margin-left: 0;
                margin-right: 0;
                font-weight: $light;
                color: inherit;
            }
        }
    }
}

.wrapper_content_password {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;

    .wrapper_form {
        margin-top: 10px;
    }

    .w-submit {
        max-width: 270px;
        margin-left: auto;
        margin-right: auto;
    }
}

.newlogin_page .w-radio-input .w-input-element+.w-input-label span {
    color: black;
}

.newlogin_page .w-radio-input .w-input-element+.w-input-label::before {
    box-shadow: inset 0 0 0 0.21rem #fff;
    border-color: grey;
}

/**
 * Guest customer or invalid login message
 */
.login_bloc .form-msg {
    align-items: flex-start;
    text-align: left;
    font-family: $primaryFont;
    font-size: 14px;

    &.error {
        background-color: $backgroundnavy;
        color: $navy;
    }

    p {
        display: initial;
        padding: 0em 1em;
    }

}

.mdpSentEmail {
    font-size: 14px;
    color: green;
}

#popup_savoir_plus {
    padding: 45px;
    width: 50vw;
    margin: 0 auto;

    .btn_fermer {
        top: 0.5rem;
        right: 0.5rem;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(19, 6, 6);
            display: inline-block;
        }
    }
}

.body_login .w-newlogin-form .w-form-line {
    margin-bottom: 25px;
}