$col: 6;
$row: 6;

/*******************************************************************/
/* HEADER LOGOS */
/*******************************************************************/

.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.bfr {
        &:before {
            content: '';
            width: 15px;
            height: 15px;
        }
    }

    &.aft {
        &:after {
            content: '';
            width: 15px;
            height: 15px;
        }
    }

    @include spriter('../svg/spritesheet.svg', 6, 6, (
    1: 'user',
    2: 'locator',
    3: 'wish',
    4: 'search',
    5: 'addcart',
    6: 'rightarrow',
    7: 'download',
    8: 'leftarrow',
    9: 'clientservice',
    10: 'deliveryfp',
    11: 'returns',
    12: 'delivery',
    13: 'instagram',
    14: 'facebook',
    15: 'pinterest',
    16: 'basket',
    17: 'simple_arrow',
    18: 'locator_full',
    19: 'cross',
    20: 'rightarrow_white',
    25: 'tiktok',
    ));
}