/**
 * RIGHT SIDE CART INFOS
 */

.cmd_table_delivery:last-of-type {
    padding-bottom:0;
}
.cmd_table_delivery:nth-child(1) {
    border:0;
}
.cmd_table_delivery {
    position: relative;
    width: 100%;
    padding-top:30px;
    padding-bottom:30px;
    border-top:1px solid black;
    .cart_product_title {
        font-size:16px;
        font-weight:$bold;
        margin-bottom:10px;
    }
    .product_del {
        height: 25px;
        position: absolute;
        right: 0;
        top: 30px;
    }
    .cmd_info_delivery {
        display:flex;
        .cmd_img_delivery {
            height:93px;
            width:93px;
        }
        .cmd_txt_delivery {
            margin-left:15px;
            display:flex;
            flex-direction:column;
            .cart_product_info {
                font-size:14px;
                label {
                    display: inline-block;
                    width: 80px;
                    font-weight:$bold !important;
                }
            }
        }
    }
}

#cart_total {
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    padding: 40px;

    @media screen and(max-width: 1200px) {
        padding: 20px;
    }

    .seeMore {
        width:100%;
        margin-top:10px;
        cursor:pointer;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;

        &::before {
            content: "";
            mask-image: url(../svg/spritesheet.svg);
            @include maskIconCoord(6, 6, 6);
            width: 20px;
            height: 20px;
            background-color: $primaryColor;
            margin-left: 10px;
            transition: all .2s ease-in-out;
        }

        &::after {
            content: "";
            display: none;
        }

        p {
            font-weight:$light;
            text-align: left;
            text-decoration:underline;
            width: 100%;
            width: fit-content;
        }
    }

    .recap_cart_title{
        font-size:30px;
        font-weight:$bold;
        line-height:30px;
        color: $black;
        display: flex;
        flex-direction: column;
    }

    .bill_line{
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        &.total_produit.toggle {
            pointer-events: none;
            label {
                font-weight: $light;
                text-underline-position: under;
                cursor: pointer;
                font-size:16px;
            }

            &.open + .seeMore {
                &::before {
                    transform: rotate(268deg);
                }
            }
        }

        &.total_produit_dropdown {
            order: 3;
            flex-direction: column;
            .cart_product_line {
                display: flex;
                place-items: flex-start;
                align-items: center;
                margin-bottom: 20px;
                width: 100%;
                padding: 0 0 24px;
                border-bottom: 1px solid #e0e3e4;

                &:first-child {
                    margin: 12px 0 24px;
                }

                &:last-child {
                    margin: 0;
                    border-bottom: 0;
                }

                .cart_product_pic {
                    margin: 1rem 1rem 1rem 0rem;
                    max-width: 85px;

                    .product_mod {

                        .cart_product_img {
                            width: 100%;

                            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { height: 100%; }
                        }
                    }
                }
                .cart_product_desc {
                    position: relative;
                    width: 63%;

                    .product_del_top{
                        position: absolute;
                        top: 4%;
                        right: -3%;

                        &:before,
                        &:after {
                            position: absolute;
                            left: 0;
                            top: -5px;
                            content: ' ';
                            height: 18px;
                            width: 1px;
                            background-color: #333;
                        }

                        &:before {
                            transform: rotate(45deg);
                        }

                        &:after {
                            transform: rotate(-45deg);
                        }
                    }

                    .wrapper_cart_product_desc {
                        height: 85%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        .cart_product_title {
                            @media screen and(max-width: 1200px) {
                                max-width: 120px;
                            }
                        }
                        .cart_product_modify .product_mod,

                            .cart_product_modify .product_mod {
                                @media screen and(max-width: 1400px) {
                                    bottom: 0;
                                }
                            }

                            a.product_del {
                                @media screen and(max-width: 1400px) {
                                    top: 0;
                                }
                            }
                        .product_mod .cart_product_title.line_product_desc {
                            margin-bottom: .5rem;
                            font-size: 12px;
                            color: $black;
                        }

                        .wrap_titre_prix {

                            .wrapper_cart_product_price {
                                margin-bottom: .5rem;
                                display: flex;

                                .pricetag {
                                    color: grey;
                                    font-weight: $medium;

                                    .currency{
                                        vertical-align: baseline;
                                    }

                                    +.pricetag{
                                        margin-left: .5rem;
                                        font-size: 13px;
                                        color: grey;
                                        text-decoration: line-through;
                                    }
                                }
                            }
                        }

                        .cart_product_sizecol {
                            font-size: .75rem;
                            margin-bottom: .5rem;
                        }

                        .cart_product_quantity {
                            font-size: .75rem;
                        }

                        .cart_product_modify {
                            position: relative;
                            .product_quantity_change {
                                display: none;
                            }
                        }
                        .product_quantity_change{

                            .qteWrapper{
                                display: flex;

                                .selected_qte{
                                    width: 23px;
                                    height: 23px;
                                    padding: 0;
                                    border-radius: 50%;
                                    text-align: center;
                                }

                                .change_qte{
                                    padding: 0 8px;
                                }
                            }
                        }

                    }
                }
            }
        }

        &.discount {
            display: flex;
            justify-content: space-between;
            position: relative;
            order: 2;
            height: 30px;
            font-size: 14px;
            label {
                font-weight:normal;
            }
        }

        &.total_produit {
            height: 30px;
            font-size: 16px;
        }

        &.hide_shippingfees {
            display: none !important;
        }

        &.total {
            align-items: center;
            height: 40px;
            font-size: 18px;
            letter-spacing: .05rem;
            border-top: 1px solid $primaryColor;

            order: 5;
            display: flex;
            justify-content: space-between;
            font-weight:$bold;
            padding-top:15px;
            label {
                font-weight:$bold;
            }
        }

        &.coupon {
            order: 6;
            #input_codePromo {
                border:0;
            }
            .w-submit{
                height: 100%;

                .w-submit-button{
                    color: black;
                    border: none;
                    text-transform: lowercase;
                    height: 100%;
                    width: 2rem;
                    background-color: transparent;
                    span {
                        font-weight:$bold;
                    }
                }
            }
        }

        &.used_cp{
            position: relative;
            .code_promo {
                font-size:18px;
                position: relative;
            }
            .close{
                position: absolute;
                left: 110%;
                top: 50%;
                transform: translateY(-50%);
                width: 13px;
                &:before,
                &:after{
                    background-color: black;
                }
            }
        }

        label{
            font-weight:$light;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { width: 100%; }
        }

    }

    .wrapper_payment {
        margin-left: 30px;
        .payment_addr_wrapper {
            margin-bottom:35px;
            position: relative;
            .title {
                color: black;
                font-size: 16px;
                position: relative;
            }
            .address {
                color: black;
                margin: 10px 0 20px 0;
                font-size: 16px;
                line-height:25px;
                font-weight:$light;
                .address_mod {
                    position: absolute;
                    top: inherit;
                    right: 0;
                    bottom:0;
                    text-decoration: underline;
                    font-size: 16px;
                    font-weight:$light;
                }
            }
            .address_mod {
                position: absolute;
                top: inherit;
                right: 0;
                bottom:0;
                text-decoration: underline;
                font-size: 16px;
                font-weight:$light;
            }
        }
    }

    .mc_texte {
        font-size: .8125rem;
        vertical-align: bottom;
    }
    .last_child {
        text-align: right;
    }

    .bill_total {
        td {
            padding-bottom: 2rem;
            text-transform: lowercase;

            &::first-letter{
                text-transform: uppercase;
            }
        }
    }

    button#btn_cmd_mag,
    button#btn_cmd_valid,
    .button#btn_cmd_valid {
        text-align: center;

        span {
            text-align: center;
            margin: 0 auto;
        }

        @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
            text-align: center;

            span {
                text-align: center;
                margin: 0 auto;
            }
        }
    }

    .form_submit{
        .loader {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;

            span {
                background: $secondaryColor url(../svg/three_dots_white.svg) center no-repeat;
                background-size: 18%;
                font-size: 0;
            }
        }
        #btn_cmd_valid{
            height: 50px;

            span{
                font-size: 15px;
                width: 100%;
            }
        }
    }

    .w-input-element {
        &+.shipment_cgv_link span {
            width: calc(100% - 1.5em);
            letter-spacing: .02rem;

            a {
                text-decoration: underline;
            }
        }

        &:checked+.shipment_cgv_link {
            &::before {
                background-color: #000 !important;
                box-shadow: inset 0 0 0 0.125em #fff !important;
                border-color: #ccc;
            }
        }
    }
    p.shipment_cgv_link {
        position:relative;
        font-family: $primaryFont;
        display: flex;
        align-items: flex-start;
        font-size: 15px;
        line-height: 20px;
        font-weight:$light;
        text-align: justify;
        &::before {
            border-radius:0;
            background-color:white;
            margin-top:0 !important;
            height: 18px !important;
            width: 19px !important;
        }
        a{
            text-decoration: underline;
        }
        span{
            width: 95%;
        }
    }

    .checkCgv{
        margin-bottom: 1rem;
        .w-checkbox-input{
            height: auto;

            .w-input-element{
                & + .w-input-label{
                    &:before{
                        margin-top: 0.25em;
                    }
                }
            }
        }

        & + #err_cgv_compulsory{

            font-size: 13px;
            font-style: italic;
            color: #922d32;
            margin: -.6rem 0 .5rem;

            text-align: center;
            padding-top: 1rem;
            background: #f7f8fa;
        }
    }
    #keep_alive_coupon {
        margin-bottom: 25px;

        .w-coupon-form {
            .w-submit-button span {
                background-color: unset;
            }
        }

        .w-coupon-form .w-form-line .w-input-element:not([type=radio]):not([type=checkbox]),
        .w-coupon-form .w-form-line input[type=text].w-input-element {
            width:inherit;
            height: 48px;
            box-sizing: border-box;
            border-radius: 0;
            border-color: #828e96;
            background-color: #fff;
        }

        .w-has-error:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]),
        .w-has-error:not(.w-dropdown) input[type=text].w-input-element {
            box-shadow: none;
            border-color: red;
        }

        .w-submit-button.w-loader,
        button.w-submit-button.w-loader {
            background: #fff url(../svg/three_dots.svg) center no-repeat;
            background-size: auto 12%;
            height: 96%;
            top: 1px;
            left: -2px;
        }
    }
}


