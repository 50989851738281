.store_locator .main_ariane {
    max-width: 1680px;
}

/* Store locator */
.store_locator_page {
    width: 98%;
    max-width: 1680px;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    .locator_heading {
        margin-bottom: 51px;
        width: 49%;

        .title {
            font-size: 35px;
            font-weight: $bold;
        }

        .subtext {
            font-size: 16px;
            line-height: 18px;
            margin-top: 5px;
        }

        .liste_distributeur_wrapper {
            max-height: 660px;
            scrollbar-width: thin;
            scrollbar-color: $black $bgGrey;

            @media screen and (max-width: 1410px) {
                max-height: 598px;
            }

            @media screen and (min-width: 1411px) and (max-width: 1555px) {
                max-height: 615px;
            }
        }
    }

    .storeloc_form {
        margin-top: 30px;

        .country_select {
            position: relative;

            #search_pays {
                width: 96px;
                height: 48px;
                padding-left: 10px;
                border: 1px solid #d9d9d9;
                color: #777777;
                -webkit-appearance: none;
                -moz-appearance: none;
            }

            &::after {
                content: '';
                position: absolute;
                right: 15px;
                top: 19px;
                border: solid grey;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
        }

        .loc_btn {
            align-items: center;
        }
    }

    .wrap_fields {
        display: flex;

        .field_country {
            width: 95px;

            label {
                border-radius: 0;
            }

            .w-dropdown::after {
                border-color: #777777;
                border-style: solid;
                border-width: 0.13em 0.13em 0 0;
                content: '';
                display: inline-block;
                height: 0.35em;
                position: absolute;
                top: 50%;
                right: 0.75rem;
                vertical-align: top;
                width: 0.35em;
                transform: translateY(-50%) rotate(135deg);
            }
        }

        .field_address {
            position: relative;
            width: 345px;
            margin-left: 10px;

            @media screen and (max-width: 1200px) {
                width: 257px;
                margin-left: 0px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 12px;
                right: 12px;
                height: 22px;
                width: 22px;
            }

            #search_cp {
                width: 320px;
                height: 46px;
                padding-left: 10px;
                border: 1px solid #d9d9d9;

                &::placeholder {
                    font-size: 13px;
                    color: #929292;
                    text-transform: capitalize;
                    font-style: normal;
                    padding-left: 10px;
                }
            }
        }

        .ou {
            margin-right: 15px;
            margin-left: 15px;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: $bold;
            line-height: 50px;

            @media screen and (max-width: 1024px) {
                margin-left: 10px;
            }
        }

        .send_form {
            flex-grow: 1;
        }
    }

    .resultat_recherche {
        margin-top: 25px;
        margin-bottom: 10px;
        font-size: 16px;
    }

    .wrapper_retailers_map {
        width: 49%;
        margin-top: 10px;
        height: 870px;
    }
}

#retailers_map {
    width: 100%;
    height: 870px;

    [pane_id="floatPane"] > div {
        // the first span is the close button with only inline styles and no way to target it...
        > span {
            font-size: 38px !important;
            top: 20px;
            right: 20px !important;
            height: 48px;
            width: 48px;
            display: grid !important;
            place-content: center;
            opacity: 0.6;
            transition: opacity 0.2s ease;

            &:hover {
                opacity: 1;
            }
        }
    }
    
    .info_popup {
        font-family: $primaryFont;
        font-size: 16px;
        margin: 40px;

        .name {
            display: flex;

            a {
                font-size: 16px;
                font-weight: $bold;
            }

            .dist {
                font-size: 13px;
                color: #777777;
                margin-left: 15px;
            }
        }

        .adresse {
            line-height: 18px;
            font-weight: normal;

            > p {
                text-transform: lowercase;
            }
        }

        .coord {
            margin-bottom: 20px;
            font-weight: normal;
        }

        .horaire_info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.26px;
            margin-top: 20px;

            .day_time {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 3px;

                .day {
                    width: 100px;
                    font-weight: $bold;
                }
            }

            span {
                p {
                    height: 21px;
                    line-height: 21px;
                    font-weight: $bold;

                    span {
                        font-weight: $regular;
                        float: right;
                    }
                }
            }

            br {
                display: none !important;
            }

            .currently_open {
                color: #78aa00;
            }

            .currently_closed {
                color: #9f0000;
            }
        }

        .savoir_plus {
            display: block;
            margin-top: 30px;
            font-weight: $light;
            text-decoration: underline;
        }
    }
      
}

.elem_liste_distributeur {
    display: grid;
    grid-template-columns: 320px 1fr auto;
    grid-template-rows: repeat(7, auto);
    column-gap: 1em;
    width: 99%;
    min-height: 136px;
    font-size: 16px;
    border-bottom: 1px solid #000;
    padding: 0 0.5em 0 2em;
    box-sizing: border-box;
    position: relative;

    @media screen and (max-width: 1300px) {
        grid-template-columns: 217px 1fr auto;
        padding-left: 0;
    }

    .store_infos_ {
        display: none;
    }

    .nom_store {
        font-weight: $bold;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        display: flex;
    }

    .store_infos {
        grid-column: 1 / 2;
    }
    
    .click_and_collect {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    
        display: flex !important;
        flex-direction: row;
        align-content: flex-end;
        flex-wrap: wrap;

        font-weight: bold;

        &::before {
            content: '•';
            margin-right: 0.5ch;
        }
    }

    .horaire_today {
        grid-column: 2 / 3;
        grid-row: 3 / -1;

        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .puce {
            display: block;
            width: 0.6em;
            height: 0.6em;
            border-radius: 50%;
            margin: 0.1rem 0.5rem 0 0;

            &.store_open {
                background-color: #78aa00;
            }

            &.store_closed {
                background-color: #9f0000;
            }
        }

        &:has(.puce.store_closed) {
            color: #9f0000;
        }

        .store_open_txt {
            color: #78aa00;
        }

        .mag_close_status,.store_open_txt {
            width: calc(100% - 1.2em);
        }
    }

    .button.secondary {
        grid-column: 3 / 4;
        grid-row: 1 / -1;

        height: unset;
        border: 0;
        text-decoration: underline;
        text-transform: none;
        align-items: center;
        display: flex;
    }
    
    .addfavorite {
        grid-column: 1 / 2;
        grid-row: -2 / -1;
        margin-top: 1em;
        margin-bottom: 0.5em;

        .choose_fav_label {
            text-decoration: underline;
            display: flex;
            gap: 5px;
            align-items: center;
            
            .bgSprite {
                display: none;
            }

        }
    }



    /* OLD BELOW */

    .content_store {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        padding: 0 1em;
        box-sizing: border-box;

        .mention_collect {
            font-weight: 700;
        }
    }


    a.savoir_plus {
        font-weight: $light;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    p.addfavorite {
        position: absolute;
        bottom: 5px;
        left: 2em;
        text-decoration: underline;
    }

    .contactMag.button.dark {
        display: none;
    }
}

/* Content Store Detail  */
.wrapper_store_details {
    width: 1280px;
    margin: 30px auto;

    .store_locator_page_details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .store_locator_left {
            display: flex;
            flex-direction: column;
            width: 600px;

            .store_locator_left_adresse {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid black;
                padding-bottom: 50px;
                margin-top: 40px;

                .store_locator_left_adresse_txt {
                    h2 {
                        font-size: 38px;
                        font-weight: $bold;
                        margin-bottom: 10px;
                        line-height: 40px;
                    }

                    p {
                        margin: 0;
                    }
                }

                .store_locator_left_adresse_cta {
                    min-width: 270px;
                    width: 270px;

                    .store_locator_left_adresse_cta_contact {
                        width: 100%;
                        height: 50px;
                        color: white;
                        text-align: center;
                        text-transform: uppercase;
                        background-color: black;
                        line-height: 50px;
                        margin-top: 16px;
                        cursor: pointer;
                    }

                    .displayStoreContact {
                        background: #000;
                        border: 1px solid #000;
                        color: #fff;
                        display: none;
                    }

                    .store_locator_left_adresse_cta_itinerary {
                        color: black;
                        background-color: white;
                        margin-top: 10px;
                        text-align: center;
                        text-transform: uppercase;
                        width: 100%;
                        height: 50px;
                        line-height: 50px;

                        .button {
                            letter-spacing: 0px;
                            font-weight: 500;
                        }
                    }
                }
            }

            .store_locator_left_hourly {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid black;
                padding-bottom: 25px;
                margin-top: 25px;

                .store_locator_left_hourly_hour {
                    max-width: 300px;
                    width: 100%;

                    h2 {
                        font-size: 30px;
                        font-weight: $bold;
                        margin-bottom: 20px;
                        line-height: 30px;
                    }

                    table {
                        line-height: 20px;
                        width: 100%;

                        tr {
                            td.day.current {
                                font-weight: $bold;
                                color: #78aa00;
                            }

                            td.day.current + td {
                                color: #78aa00;
                            }

                            td:nth-child(1) {
                                font-weight: $bold;
                            }

                            .closed_boutique {
                                color: #9f0000;
                            }
                        }
                    }
                }

                .store_locator_left_hourly_excep {
                    max-width: 305px;

                    h2 {
                        font-size: 30px;
                        font-weight: $bold;
                        margin-bottom: 20px;
                        line-height: 30px;
                    }

                    .holiday {
                        line-height: 18px;
                    }
                }
            }

            .store_locator_left_services {
                border-bottom: 1px solid black;
                padding-bottom: 25px;
                margin-top: 25px;

                h2 {
                    font-size: 30px;
                    font-weight: $bold;
                    margin-bottom: 20px;
                }

                ul {
                    width: 100%;
                    display: flex;

                    li {
                        font-weight: $bold;
                        list-style: disc;
                    }

                    li:not(:first-child) {
                        margin-left: 48px;
                    }

                    li:first-child {
                        margin-left: 24px;
                    }
                }
            }

            .store_locator_left_presentation {
                padding-bottom: 25px;
                margin-top: 25px;

                h2 {
                    font-size: 30px;
                    font-weight: $bold;
                    margin-bottom: 20px;
                }

                p {
                    line-height: 18px;
                }
            }
        }

        .store_locator_right {
            width: 630px;
            display: flex;
            flex-direction: column;

            .store_locator_right_map {
                width: 100%;
                height: 445px;
            }

            .store_locator_right_img {
                width: 100%;
                height: 430px;
            }
        }
    }
}
