body {
    &.category {
        .page_wrapper {
            max-width: 1680px;
            margin: 0 auto;
        }
    }

    &.customer.body_login {
        .wrapper_bandeau.bandoBottom {
            .txt_content_container {
                max-width: 1680px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &.category .wrapper_bandeau.rayon,
    &.category .wrapper_bandeau.bandoBottom,
    &.customer .wrapper_bandeau.bandoBottom {
        display: flex;
        flex-wrap: wrap;
        line-height: 18px;
        margin-bottom: 50px;
        overflow: hidden;
        text-align: center;

        .lienSeo {
            margin: 60px auto 0 auto;
            max-width: 1220px;

            .textSeo_bloc_liens {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px 10px;
                flex-wrap: wrap;
                width: auto;

                .textSeo_bloc_list_items {
                    a {
                        padding: 6px 20px;
                        border-radius: 20px;
                        background: $bgGrey;
                        color: $black;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                        letter-spacing: 0.7px;
                        text-decoration: none;
                        display: block;
                    }
                }
            }
        }

        >.title {
            max-width: 553px;
            width: 33%;
            height: 270px;
            box-sizing: border-box;
            font-size: 50px;
            font-weight: $bold;
            line-height: 54px;
            padding: 60px 120px 60px 60px;
            background-color: var(--parentbgcolor, $white);
            color: var(--parenttextcolor, $black);

            &.cat_page {
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
            }

            @media screen and (max-width: 1024px) {
                font-size: 40px;
                line-height: 1.3;
            }
        }

        &.bandoBottom {
            .txt_content_container {
                display: flex;
                flex-direction: column;
                margin-top: 25px;
                padding: 0 10px;
                text-align: left;

                .rayon_desc_summary {
                    a span strong {
                        text-decoration: underline;
                    }

                    span strong a {
                        text-decoration: underline;
                    }
                }
            }

            .txt_content {
                padding-bottom: 60px;
            }
        }

        >.txt_content_container {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .txt_content_container {
            strong {
                font-weight: $bold;
            }

            >.txt_content,
            >.rayon_desc_summary {
                padding-top: 1rem;

                ul {
                    list-style: disc;
                    padding-left: 46px;

                    li {
                        list-style: disc !important;
                    }
                }

                a {
                    text-decoration: underline;
                }
            }

            .see_more_txt {
                background: $bgGrey;
                width: 100%;
                max-width: 185px;
                height: 40px;
                font-family: $primaryFont;
                font-weight: 500;
                font-size: 16px;
                letter-spacing: 0.6px;
                border-radius: 20px;
                cursor: pointer;
                color: $black;
                line-height: 100px;
                padding: 10px;
            }

            h2,
            h3 {
                margin: 0;
            }
        }

        .selected_options,
        .wrapper_bandeau_visuel {
            max-width: 1127px;
            width: 67%;
            height: auto;
        }

        .wrapper_bandeau_visuel {
            position: relative;
        }

        .selected_options {
            display: flex;
            flex-direction: column;
            padding-top: 30px;
            padding-left: 50px;
            box-sizing: border-box;

            .bandeau_menu_filter {
                width: auto;
            }

            .swiper-pagination.swiper-pagination-progressbar {
                opacity: 0;
            }

            .form_itm {
                display: flex;
                flex-direction: column;
                width: 85px;
                margin-right: 50px;

                &:last-child {
                    margin-right: 0;
                }

                &.current h2 {
                    padding-bottom: 6px;
                    border-bottom: 3px solid var(--parenttextcolor, rgba(0, 0, 0, 0.2));
                    color: var(--parenttextcolor);
                }

                img {
                    height: 85px;
                    width: 85px;
                    background-color: #e3e3e3;
                }

                h2 {
                    margin-top: 8px;
                    font-size: 15px;
                    font-weight: $bold;
                    line-height: 18px;
                    text-align: center;
                }
            }
        }

        &.bandoTop {
            .selected_options {
                align-items: flex-start;
                padding-right: 30px;

                .txt_content_container .txt_content {
                    text-align: left;
                }
            }
        }
    }
}

#trigger_filtre {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    .filtres_wrap {
        display: flex;
        overflow: hidden;
    }

    .filtre_elem {
        display: block;
        padding-right: 20px;
        padding-left: 20px;
        height: 40px;
        background-color: #f5f5f5;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        margin-right: 20px;
        border-radius: 20px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.more_filters {
            font-weight: 600;
        }
    }

    .initFilters {
        font-size: 15px;
        font-weight: $light;
        cursor: pointer;
        text-decoration: underline;
        line-height: 40px;
        white-space: nowrap;
        display: none;
        margin-right: 0.5rem;
    }

    #filter_trier {
        position: relative;
        align-items: center;
        width: fit-content;
        z-index: 5;
        top: 0.6rem;

        &:hover {
            overflow: visible;

            .selected_options {
                display: block;
            }

            #pic {
                transform: rotate(-135deg);
            }
        }

        #filter_word {
            font-size: 16px;
            color: #8d8d8d;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        #pic {
            border: solid #8d8d8d;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(45deg);
            position: absolute;
            top: 6px;
            margin-left: 8px;
        }

        .searchPriceFilter,
        .selected_options {
            background-color: $white;
        }

        .searchPriceFilter {
            text-align: right;
            margin-right: 1.2rem;

            >span {
                white-space: nowrap;
            }
        }

        .selected_options {
            box-sizing: border-box;
            position: absolute;
            width: calc(100% - 2rem);
            transition: all 0.3s ease-out;
            padding: 1rem;
            box-sizing: content-box;
            display: none;

            .tri_price {
                cursor: pointer;

                &::first-letter {
                    text-transform: uppercase;
                }
            }

            input {
                position: absolute;
                left: -999999px;
            }

            .tri_price.actif,
            input:checked+label {
                font-weight: 900;
            }
        }
    }
}

#filter_sticky.triggered,
#filter_sticky_search.displayed {
    transform: translateX(0);
}

#site_head_wrap.sticky+#site_global_wrap {
    #filter_sticky {
        top: $fixed_headerHeightBig;
        height: calc(100vh - #{$fixed_headerHeightBig});
    }
}

#filter_sticky_search,
#filter_sticky {
    box-sizing: border-box;
    transform: translateX(100%);
    position: fixed;
    top: $headerHeightBig;
    right: 0px;
    width: 484px;
    height: calc(100vh - #{$headerHeightBig});
    padding: 40px 60px;
    background-color: #f5f5f5;
    z-index: 99;
    transition-duration: 0.3s;

    >h2 {
        width: 100%;
        position: relative;
        font-size: 40px;
        font-weight: $bold;
        padding-bottom: 1rem;

        span {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    // On scrollbar *******/
    .filters_container_wrapper {
        .os-content {
            padding-right: 20px;
        }
    }

    // *******************/
    .filters_container {
        height: calc(100% - 142px);
        width: 108%;

        .filters_container_wrapper {
            max-height: 100%;
            height: 100%;

            @media screen and (max-height: 830px) {
                max-height: 50vh;
            }

            @media screen and (max-height: 740px) {
                max-height: 45vh;
            }

            @media screen and (max-height: 700px) {
                max-height: 42vh;
            }
        }

        #filter_price {
            .selected_options {
                height: 60px;
                width: 73%;
                margin-left: auto;
                margin-right: auto;

                .ui-slider {
                    position: relative;
                    text-align: left;
                    z-index: 0;

                    .ui-slider-handle {
                        z-index: 2;
                        position: absolute;
                        top: -5px;
                        transform: translateX(-10px);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        outline: none;
                        cursor: pointer;

                        &::before {
                            content: '';
                            display: block;
                            width: 10px;
                            height: 10px;
                            background-color: white;
                            border: 1px solid black;
                            border-radius: 50%;
                        }

                        &:first-of-type {

                            .handler_price {
                                margin-left: -130%;
                            }
                        }

                        &:nth-of-type(2) {

                            .handler_price {
                                margin-right: -130%;
                            }
                        }
                    }

                    .ui-slider-range {
                        z-index: 1;
                        position: absolute;
                        display: block;
                        border: 0;
                    }

                    .handler_price {
                        font-weight: 900;
                    }
                }

                .ui-slider-horizontal {
                    height: 2px;
                    border-radius: 5%;
                    background-color: grey;
                    margin-top: 30px;

                    .ui-slider-range {
                        top: 0;
                        height: 1px;
                        margin: 0;
                    }

                    .ui-slider-range-min {
                        left: 0;
                    }
                }

                .ui-widget-header {
                    background-color: $black;
                }
            }
        }

        .filters_dropdown_bloc {
            position: relative;
            cursor: pointer;
            width: 92%;

            input {
                cursor: pointer;
            }

            input[type='checkbox'] {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                z-index: 99;
                height: 51px;
                margin: 0;
            }

            .form_itm {
                &:not(.color) {
                    input.is_checked+label {
                        font-weight: $bold;
                        text-decoration: underline;
                    }
                }
            }

            .filter_checkbox_hide:checked+.filters_dropdown .selected_options {
                display: block;
            }

            .filter_checkbox_hide:checked+.filters_dropdown .pic {
                transform: rotate(225deg);
            }

            // Search price range filter *************/
            .searchRangeFilter+.selected_options {
                padding: 0 16px;

                .vue-slider-dot-tooltip {
                    line-height: 1;
                }

                .vue-slider-rail {
                    background-color: $white;
                }

                .vue-slider-process,
                .vue-slider-dot {
                    background-color: $primaryColor;
                }

                .vue-slider-dot {
                    border-radius: 50%;
                }
            }

            // *************************************/
            .filter_checkbox_hide {
                cursor: pointer;
            }

            .filters_dropdown {
                min-height: 50px;
                border-bottom: 1px solid #cfcfcf;
                line-height: 50px;
                font-family: inherit;
                position: relative;

                .filter_name {
                    cursor: pointer;
                }

                .selected_options {
                    display: none;
                    margin-bottom: 20px;

                    .form_itm.color {
                        height: 28px;
                        line-height: 28px;

                        label {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                        }

                        .filter_color {
                            display: flex;
                            line-height: 23px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            p {
                                margin-left: 10px;
                                font-size: 16px;
                                font-weight: $light;
                            }

                            .color_picto {
                                display: flex;
                            }
                        }

                        input:checked+label {
                            border: 1px solid $primaryColor;
                        }
                    }

                    .form_itm {
                        padding-left: 14px;
                        position: relative;

                        input.input_check {
                            cursor: pointer;
                            opacity: 0;
                            z-index: 2;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            margin: 0;

                            &:checked,
                            &.is_checked {
                                &+.filter_color {
                                    >p {
                                        font-weight: $semibold;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }

                        input.input_check:checked+p {
                            text-decoration: underline;
                            color: blue;
                        }
                    }
                }

                .pic {
                    border: solid black;
                    border-width: 0 1px 1px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    position: absolute;
                    right: 5px;
                    top: 22px;
                }
            }
        }

        .filters_cta {
            display: flex;
            justify-content: space-between;
            position: fixed;
            bottom: 2rem;
            width: calc(100% - 120px); // 120 = Parent's padding
            padding-top: 40px;
            height: 50px;
            margin-top: 30px;

            button {
                width: 175px;
                text-align: center;
                line-height: 50px;
                cursor: pointer;
            }

            .filter_see {
                height: 50px;
                background-color: black;
                color: white;
                text-transform: uppercase;
                border: 0;
            }

            .filter_remove {
                height: 48px;
                background-color: white;
                color: black;
                border: 1px solid black;
                text-transform: uppercase;
            }
        }
    }
}

.page_rayon {
    margin-top: 20px;

    .wrap_flitre_pager {
        text-align: center;
        position: relative;
        width: 250px;
        margin-top: 70px;
        margin-left: auto;
        margin-right: auto;

        .pagination {
            display: none;
        }

        .nbProdDisplayed {
            text-align: center;
            margin: 1rem 0 0.5rem;
        }

        .loader_scroll {
            position: relative;

            &.loading {
                .loader {
                    display: block;
                    z-index: 1;
                    opacity: 1;
                    border: 0;
                    border-radius: 20px;
                }
            }

            &.modeAuto {
                display: none;
            }

            .loader {
                background-color: $bgGrey;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../svg/three_dots.svg);
                background-size: 18%;
            }
        }

        .loadedGauge {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            height: 3px;
            width: 190px;
            margin: 0 auto;
            background-color: lightgrey;

            span {
                display: block;
                background-color: black;
                margin: 0;
            }
        }

        .see_all_product {
            position: relative;
            background: #f5f5f5;
            min-width: 185px;
            height: 40px;
            font-family: $primaryFont;
            font-weight: 500;
            font-size: 16px;
            line-height: 48px;
            letter-spacing: 0.6px;
            color: #ffffff;
            border-radius: 20px;
            margin-top: 30px;
            cursor: pointer;
            color: black;
            line-height: 40px;
            margin-left: auto;
            margin-right: auto;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                content: '';
                width: 100%;
                height: 40px;
                background: url(../svg/three_dots.svg) center center/18% no-repeat #f5f5f5 !important;
                z-index: -1;
                border-radius: 20px;
                opacity: 0;
            }

            &.loading {
                &::before {
                    z-index: 1;
                    opacity: 1;
                }
            }
        }
    }
}

.container_products {
    margin-top: 20px;

    #list_item {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .wrapperProdVertical {
            position: relative;

            &:nth-child(4n) {
                margin-right: 0;
            }

            .iframe_container {
                position: relative;
                padding-bottom: calc(328 / 328 * 100%);

                iframe {
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .link_product_iframe {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }

            .wrapper_img.push_video {
                .infos {
                    display: none;
                }
            }

            .old-price {
                position: absolute;
                font-size: 13px;
                white-space: nowrap;
                top: 46px;
                right: 10px;
            }
        }

        a.push {
            .infos {
                background-color: var(--parenttextcolor, $primaryColor);
                color: white;
                text-transform: uppercase;
                text-align: center;
                font-size: 15px;
                margin-top: 10px;
                width: 100%;
                height: 50px;
                line-height: 50px;
            }
        }
    }
}


#shad_link.filterOn {
    display: block;
    z-index: 8;
}

.backToTopAlgolia,
#back_to_top {
    background-color: $black;
    width: 36px;
    height: 48px;
    position: fixed;
    bottom: 150px;
    right: 15px;
    transition: background-color 0.3s, opacity 0.5s;
    z-index: 1000;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    &:after {
        content: '';
        display: block;
        transform: rotate(90deg) translate(-50%, 50%);
        position: absolute;
        mask-image: url(../svg/spritesheet.svg);
        mask-repeat: no-repeat;
        mask-size: 600% auto;
        mask-position: 20% 20%;
        width: 30px;
        height: 30px;
        background-color: $white;
        left: 50%;
        top: 50%;
    }
}