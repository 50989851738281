/*menu tunnel*/
body.cart,
body.checkout,
body.customer {
    .tunnel_track {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        max-width: 1680px;
        height: 38px;
        border-bottom: 1px solid $primaryColor;
        margin: 1.4rem auto 4rem;

        .tunnel_step {
            position: relative;
            display: block;
            height: 100%;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.04rem;
            text-decoration: none;
            text-align: center;
            margin-left: 12.5%;

            &:first-child {
                margin-left: 0;
            }

            &:after {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background: $white;
                border: 1px solid $primaryColor;
                border-radius: 50%;
                box-shadow: 0 0 0 3px $white;
                position: absolute;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
            }

            &.on {
                font-weight: 800;

                &:after {
                    background: $primaryColor;
                }
            }
        }
    }

    .w-has-tel {
        .w-tel-input.w-has-error {
            input#clientTel {
                border-color: $bitterSweet;
                box-shadow: 0 0 0 0 $bitterSweet !important;
            }

            input#telephone {
                border-color: $bitterSweet;
                box-shadow: 0 0 0 0 $bitterSweet !important;
            }
        }
    }
}

body.in_tunnel {
    &.step_2 {
        .texte_bloc_noel {
            display: flex !important;
            align-items: center;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            color: $cognac;
            font-family: $primaryFont;
            width: 98%;
            max-width: 1280px;
            margin: -1rem auto;

            &:before {
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                margin-right: 8px;
                background-image: url(../img/noel.jpg);
                background-repeat: no-repeat;
            }
        }
    }
}

body.in_tunnel {

    &.step_2,
    &.checkout,
    &.body_login {

        .header_middle .header_main_wrapper,
        .header_leftSide,
        .header_rightSide {
            display: none;
        }

        #header_mention_id {
            opacity: 0;
            pointer-events: none;
        }

        .tunnel_track_pusher {
            top: 170px;
        }
    }
}

.checkout .deconnection_tunnel {
    margin-top: 2rem;
}

.deconnection_tunnel {
    display: flex;
    justify-content: space-between;
    background-color: $whiteLilac;
    margin-bottom: 12px;
    padding: 0.5rem;

    .button {
        width: initial;
        height: initial;
        text-transform: initial;
        outline: initial;
        padding: initial;
        border: initial;

        span {
            justify-content: flex-end;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    p {
        max-width: 50%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

/* TUNNEL LIGHTBOX */
#recuppanier,
#modboxpromo {
    @extend .lightbox;
}

#modboxpromo {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 55px;
    min-width: 300px;

    &.actif {
        display: flex;
    }

    p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem 0 2rem;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 40px;
        border: 1px solid $silverChalice;
        margin: 0 auto;
    }

    #btn_cmd_valid {
        @extend .dark;
    }

    .modboxBtnClose {
        display: block !important;
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 18px;
        height: 18px;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        font-size: 0;

        &:before,
        &:after {
            position: absolute;
            left: 8px;
            content: ' ';
            height: 20px;
            width: 1px;
            background-color: $darkGrey;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

body.in_tunnel {
    #site_global_wrap {
        padding: 0 0 50px 0;
    }

    &.cart.step_2,
    &.cart.step_3 {
        @media only screen and (min-width: 997px) and (max-width: 1500px) {
            #site_global_wrap {
                padding-right: 10px;
                padding-left: 10px;
            }
        }
    }

    /* Formulaire de livraison invité */
    &.cart.step_2 {
        .bill_line.discount {
            display: flex !important;
        }

        .cmd_table.cart_product_line {
            display: none !important;
        }

        #cart_total .recap_cart_title {
            padding-bottom: 20px;
        }

        #cart_total .seeMore {
            padding-bottom: 0 !important;
        }
    }

    &.cart.step_2_0 {
        .checkCgv {
            display: none !important;
        }

        .cmd_table.cart_product_line {
            display: none !important;
        }

        .cmd_table_delivery {
            display: block;
        }

        .w-form-line.w-has-tel {
            .w-tel-input.w-has-error {
                input#telephone {
                    border-color: $bitterSweet;
                    box-shadow: 0 0 0 0 $bitterSweet !important;
                }

                #iconValidtelephone {
                    display: none;
                }
            }
        }
    }

    /* Bloc adresses de facturation */
    .left_side.elem_tunnel.addressWrapper {
        width: 100%;
        margin-left: 2rem;

        .conteneur_adresse {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 2rem;
            width: 100%;

            .adresse {
                display: flex;
                justify-content: center;
                padding: 30px 20px;
                text-align: center;
                background: $seashell;
                min-width: 31.3%;
                margin-right: 3%;
                margin-bottom: 3%;

                &:nth-child(3n + 3) {
                    margin-right: 0;
                }

                .addr_label {
                    text-transform: uppercase;

                    span {
                        font-size: 1.1rem;
                        max-width: min-content;
                        font-weight: $semibold;
                        letter-spacing: 1px;
                        line-height: 1.5;
                    }
                }

                .infos {
                    .addr_line {

                        .full_name,
                        span {
                            font-weight: $regular;
                            color: $grey;
                            font-size: 13px;
                            line-height: 1.3;
                        }
                    }
                }

                .addr_type {
                    margin-left: 0.3rem;
                }

                .addr_label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1rem;

                    p {
                        font-weight: 400;
                        font-size: 0.83rem;
                    }

                    .addr_titre {
                        text-transform: uppercase;
                    }

                    span {
                        background-color: transparent;
                    }
                }

                .modlinks_wrapper {
                    font-family: $primaryFont;
                    letter-spacing: 0;
                    font-size: 0.8rem;
                    margin-top: 1rem;

                    a {
                        font-weight: 400;
                        color: $secondaryColor;
                        text-decoration: underline;
                        text-decoration-color: $mountainMist;
                    }

                    .delete {
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}

/* TUNNEL STEPS */
.step_1 {
    .cmd_table_delivery {
        display: none;
    }
}

body.cart,
body.checkout,
body.customer {
    .wrapper_panier {
        display: flex;
        justify-content: space-between;
        width: 98%;
        max-width: 1280px;
        margin: 3rem auto;
        flex-wrap: wrap;

        .left_side {
            width: 855px;

            @media screen and (max-width: 1230px) {
                width: 800px;
            }

            @media screen and (max-width: 1180px) {
                width: 760px;

                .cmd_table tbody tr td.cmd_unit_price {
                    width: 20%;
                }

                .cmd_table tbody tr td.cmd_qnt {
                    width: 11%;
                }

                .cmd_table tbody tr td.cmd_tot_price {
                    width: 20%;
                }
            }

            @media screen and (max-width: 1135px) {
                width: 680px;

                .cmd_table tbody tr td.cmd_unit_price {
                    width: 18%;
                }

                .cmd_table tbody tr td.cmd_qnt {
                    width: 9%;
                }

                .cmd_table tbody tr td.cmd_tot_price {
                    width: 18%;
                }
            }

            @media screen and (max-width: 1052px) {
                width: 625px;

                .cmd_table tbody tr td.cmd_unit_price {
                    width: 15%;
                }

                .cmd_table tbody tr td.cmd_qnt {
                    width: 7%;
                }

                .cmd_table tbody tr td.cmd_tot_price {
                    width: 15%;
                }
            }

            @media screen and (max-width: 965px) {
                width: 600px;

                .cmd_table tbody tr td.cmd_img_info {
                    width: 310px;
                }

                .cmd_table tbody tr td.cmd_unit_price {
                    width: 14%;
                }

                .cmd_table tbody tr td.cmd_qnt .qnt_prod {
                    width: 83px !important;
                }

                .cmd_table tbody tr td.cmd_tot_price {
                    width: 13%;
                }
            }

            .error_no_conveyor {
                border: 1px solid $black;
                padding: 20px;
                color: $black;

                &:before {
                    content: '';
                    width: 25px;
                    background: url(../img/triangle-exclamation-solid.svg) 0 0 no-repeat;
                    height: 25px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 12px;
                }
            }

            .cart_main_title {
                font-weight: $bold;
                color: $black;
                font-size: 40px;
                display: flex;
                flex-direction: column;

                span.nb_products {
                    width: fit-content;
                    position: relative;
                    font-weight: $regular;
                    font-size: 16px;
                    left: -5px;

                    &::before {
                        content: '';
                        height: 22px;
                        width: 4px;
                        background-color: $white;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }

                    &::after {
                        content: '';
                        height: 22px;
                        width: 5px;
                        background-color: $white;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
            }

            .cart_main_title.wishlist {
                padding-top: 40px;
            }

            .cart_product_modify {
                a.product_del {
                    position: absolute;
                    top: 20px;
                    height: 25px;
                    right: 0;
                }

                a.remove_wish {
                    position: absolute;
                    top: 5px;
                    right: 0;
                    font-size: 0;
                    display: block;
                    width: 15px;
                    height: 15px;
                    margin-left: 40px;

                    @media screen and(max-width: 1200px) {
                        margin-left: 0;
                    }

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        left: 7px;
                        width: 1px;
                        height: 16px;
                        background-color: $black;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }

                .basketToWishlist {
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    bottom: 40px;
                    right: 0;
                    background-color: $bgGrey;
                    border-radius: 5px;

                    &::after {
                        width: 25px;
                        height: 25px;
                    }

                    &.existToWishlistButton {
                        &::after {
                            background-position: 40% 60%;
                        }
                    }
                }
            }

            /* BLOC PANIER */
            .cart_main_table.basket {
                .dropdown_cart {
                    p {
                        &.precoSubtitle {
                            display: none;
                        }

                        &.precoDeliveryDate {
                            font-size: 16px;
                            text-transform: initial;
                            font-weight: $light;
                            color: $black;
                        }
                    }
                }

                .texte_bloc_noel {
                    font-size: 16px;
                    font-weight: 600;
                    color: $cognac;
                    line-height: 1;
                    font-family: $primaryFont;
                    margin-bottom: 20px;
                    display: flex !important;
                    margin-top: 5px;
                    align-items: center;

                    &:before {
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                        background-image: url(../img/noel.jpg);
                        background-repeat: no-repeat;
                    }
                }

                .cmd_table {
                    margin-top: 15px;

                    .cmd_img_info {
                        width: 330px;

                        .cart_thumb_info {
                            margin-left: 20px;

                            .cart_product_title {
                                font-size: 16px;
                                font-weight: $bold;
                                white-space: nowrap;
                                max-width: 12rem;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                height: 24px;
                            }

                            .cart_product_sizecol.color {
                                margin-top: 10px;
                            }

                            .cart_product_sizecol {
                                font-size: 14px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 12rem;

                                label {
                                    display: inline-block;
                                    min-width: 70px;
                                    font-weight: $bold;
                                }
                            }

                            .product_mod {
                                p {
                                    max-width: 12rem;
                                }
                            }
                        }
                    }

                    .cmd_qnt {
                        .qnt_prod {
                            cursor: pointer;
                            width: 108px;
                            height: 48px;
                            background: $white url(../img/select_arrow.png) no-repeat 90% center;
                            background-size: auto 6px;
                            padding: 0 0 0 1rem;

                            &.disabled {
                                background: none;
                                cursor: initial;
                            }
                        }
                    }
                }

                .cart_product_desc {
                    position: relative;

                    .product_del_top {
                        position: absolute;
                        top: 10%;
                        right: 0;

                        &:before,
                        &:after {
                            position: absolute;
                            left: 8px;
                            content: ' ';
                            height: 18px;
                            width: 1px;
                            background-color: $darkGrey;
                        }

                        &:before {
                            transform: rotate(45deg);
                        }

                        &:after {
                            transform: rotate(-45deg);
                        }
                    }

                    .wrapper_cart_product_desc {
                        .cart_product_sizecol {
                            label {
                                font-weight: $bold;
                            }
                        }

                        .product_quantity_change {
                            min-height: 2rem;

                            .selected_qte {
                                width: 2.2rem;
                                height: 2rem;
                                text-align: center;
                                border: none;
                                padding: 0;
                                font-size: 0.9rem;
                            }

                            button.change_qte {
                                border: 1px solid $grey;
                                border-radius: 50%;
                                width: 1rem;
                                height: 1rem;
                                cursor: pointer;
                                line-height: 1;
                            }

                            img.load_qte {
                                padding: 0.5rem 0 0 1.5rem;
                            }
                        }

                        .cart_product_modify {
                            display: block;
                            margin: 1.5rem 0 1rem;
                            color: $grey;

                            .product_mod {
                                margin-right: 2.5rem;
                                color: $grey;
                            }
                        }

                        .no_stock {
                            color: $red;
                            margin-bottom: 0.7rem;
                        }
                    }
                }

                /* Masqué pour ne pas être affichée, mais conserver son espace occupé */
                .line_ssTotal {
                    opacity: 0;
                }
            }

            /* BLOC WISHLIST */
            .cart_main_table {
                &.wishlist {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    .wishlist_persis_info {
                        display: none;
                    }

                    .cart_product_line:not(:nth-last-child(-n + 2)):not( :nth-child(even)) {
                        border-bottom: 1px solid $black;
                    }

                    .cart_product_line:last-child:nth-child(odd) {
                        border-bottom: 0;
                    }

                    .cart_product_line {
                        display: flex;
                        border-bottom: 1px solid $black;

                        .cart_product_brand,
                        .dragHandle,
                        .dragOverlay {
                            display: none;
                        }

                        .cart_product_pic {
                            position: relative;
                            width: 136px;
                            height: 136px;
                            overflow: hidden;

                            img.cart_product_img {
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                                width: 100%;
                                height: auto;
                            }

                            .eclat_rect {
                                z-index: 2;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }

                        .cart_product_desc {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: calc(100% - 165px);
                            margin-left: 20px;
                            position: relative;

                            .tunnel_wish_nostock {
                                display: none;
                            }
                        }

                        .cart_product_modify {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            width: calc(100% - 180px);

                            @media screen and(max-width: 1200px) {
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: flex-end;
                                height: 100%;
                            }
                        }
                    }

                    .cart_product_line {
                        width: 50%;
                        box-sizing: border-box;
                        padding-bottom: 30px;
                        padding-top: 30px;

                        @media screen and(max-width: 1200px) {
                            width: 100%;
                            padding: 24px;
                        }

                        .wrapper_cart_product_price {
                            margin-bottom: 18px;
                        }

                        .cart_product_sizecol {
                            font-size: 14px;
                            display: flex;

                            label {
                                display: inline-block;
                                min-width: 70px;
                                font-weight: $bold;
                            }
                        }

                        .wrapper_cart_product_desc {
                            width: 100%;
                            position: relative;

                            .wrap_titre_prix {
                                display: flex;
                                flex-direction: column;

                                .product_mod {
                                    order: 1;
                                }

                                .wrapper_cart_product_price {
                                    order: 2;
                                }

                                .cart_product_sizecol {
                                    &.color {
                                        order: 3;
                                    }

                                    &.size {
                                        order: 4;
                                    }
                                }
                            }
                        }

                        .cart_product_modify {
                            .button.wishToBasket {
                                position: absolute;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 40px;
                                height: 40px;
                                bottom: 0;
                                right: 0;
                                background-color: $bgGrey;
                                border-radius: 5px;
                                border: 0;

                                &::after {
                                    content: '';
                                    display: block;
                                    width: 25px;
                                    height: 25px;
                                    @include bgIconPath('../svg/spritesheet.svg'
                                    );
                                    @include bgIconCoord(5, 6, 6);
                                    background-repeat: no-repeat;
                                }

                                span {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .cart_product_line {
                    .cart_product_desc {
                        .wrapper_cart_product_desc {
                            .cart_product_title.line_product_desc {
                                font-weight: $bold;
                                white-space: nowrap;
                                max-width: 235px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                height: 24px;
                            }
                        }
                    }
                }
            }

            .old-price {
                font-size: 13px;
            }
        }
    }

    .store_pref {
        display: flex;
        justify-content: space-between;
        width: 98%;
        max-width: 1280px;
        margin: 3rem auto 160px;
        flex-wrap: wrap;
        flex-direction: column;

        h3 {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 20px;
            color: $black;
            font-weight: 700;
        }

        .container_store_pref {
            display: flex;

            .bloc_store_pref {
                background-color: $white;
                text-align: center;
                width: 300px;
                height: 160px;
                border: 10px solid rgba(245, 245, 245, 0.5);
                position: relative;

                p {
                    line-height: 20px;
                    font-size: 18px;
                    color: $black;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 50%;
                }
            }
        }

        .form_submit {
            margin-top: 30px;

            a {
                width: 270px !important;
                padding: 15px 1.85rem !important;
                border: 1px solid $black !important;
                color: $black !important;
                font-size: 15px !important;
                line-height: 50px;
                border-radius: 0 !important;
                letter-spacing: 1px;
            }
        }
    }

    /*BLOC DE DROITE*/
    .right_side {
        width: 350px;

        .wrapper_info_cgv.upselling {
            display: none;
        }

        .bloc_question {
            display: none;
        }

        @media screen and(max-width: 1000px) and (min-width: 950px) {
            width: 300px;
        }

        @media screen and(max-width: 955px) {
            width: 290px;
        }
    }
}

body.customer {
    .store_pref {
        max-width: unset;
        margin-left: 0;
        margin-right: 0;
    }
}

/*  Details de livraison */
.wrapper_mes_adresses {
    .w-submit-button {
        .w-submit {
            @extend .dark;
            color: inherit;

            >span {
                color: inherit;
            }
        }
    }
}

body.step_2_0 {
    width: 100%;
    min-width: 1007px;

    .wrapper_mes_adresses {
        width: 100%;
        border-bottom: 1px solid $black;
        padding-bottom: 30px;

        .edit_adresse {
            display: flex;
            justify-content: space-between;

            .left_side {
                .wrapper_form_adresse {
                    .w-newuser-form {
                        .w-group-label {
                            font-family: inherit;
                            font-size: 30px;
                            font-weight: $bold;
                        }

                        .choix_type_adresse {
                            display: flex;
                            margin-bottom: 20px;

                            .type_adresse.actif {
                                background-color: $black;

                                p {
                                    color: $white;
                                }
                            }

                            .type_adresse {
                                background-color: $backgroundWhite;
                                border-radius: 20px;
                                height: 40px;
                                width: 140px;
                                padding: 0 40px;
                                margin-right: 20px;
                                margin-bottom: 2rem;

                                p {
                                    font-size: 16px;
                                    line-height: 40px;
                                    color: $primaryColor;
                                    cursor: pointer;
                                    transition: opacity 0.2s ease-in;
                                }

                                &.actif {
                                    p {
                                        opacity: 1;
                                    }
                                }

                                &.ami {
                                    display: none;
                                }
                            }
                        }

                        .soloTel.telInfos {
                            display: none;
                        }

                        input[type='date'] {
                            padding: 1.5rem 1rem 0.4rem;
                        }

                        .w-submit {
                            width: 270px !important;
                            max-width: inherit;

                            .w-submit-button {
                                width: 270px;
                                height: 50px;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }

        .conteneur_adresse {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 30px;
            padding-top: 25px;
            position: relative;

            h3 {
                position: absolute;
                top: 0;
                font-size: 20px;
                color: $black;
                font-weight: 700;
            }

            .adresse {
                display: flex;
                position: relative;
                margin-top: 20px;
                margin-right: 30px;
                min-height: 120px;
                width: 260px;
                font-size: 16px;
                padding: 20px;

                &:nth-child(5n + 2) {
                    background-color: $moutarde;
                }

                &:nth-child(5n + 3) {
                    background-color: $cerise;
                }

                &:nth-child(5n + 4) {
                    background-color: $navy;
                }

                &:nth-child(5n + 5) {
                    background-color: $camouflageGreen;
                }

                &:nth-child(5n + 6) {
                    background-color: $grey;
                }

                .addr_label {
                    p {
                        text-transform: capitalize;
                        white-space: nowrap;
                        height: 20px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-size: 16px;
                        font-weight: 700;
                        color: $white !important;
                    }
                }

                .infos {
                    p {
                        height: 20px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-weight: 200;
                        color: $white !important;
                    }

                    .addr_line {
                        line-height: 18px;
                    }

                    .modlinks_wrapper {
                        color: $white !important;
                        font-weight: 300;
                        margin-top: 22px !important;

                        .modlink {
                            margin-right: 1rem;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .form_submit {
            a {
                width: 270px !important;
                padding: 15px 1.85rem !important;
                border: 1px solid $black !important;
                color: $black !important;
                font-size: 15px !important;
                line-height: 50px;
                border-radius: 0 !important;
                letter-spacing: 1px;
            }
        }
    }
}

.cart,
.step_2,
.in_tunnel {
    .bloc_livraison {
        .title_type_livraison {
            font-size: 25px;
            font-weight: $bold;
            text-transform: initial;

            /* Works because element has specific display */
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}

.bloc_livraison {
    position: relative;
    display: block;
    border: 1px solid $mercury;
    margin-bottom: 64px;
    padding: 17px 38px 38px;
    box-sizing: border-box;
    min-height: 260px;
    height: fit-content;

    &:not(.active) {
        cursor: pointer;

        .checkbox {
            cursor: pointer;
        }
    }

    input[name='choix_livraison'] {
        display: none;
    }

    .checkbox,
    .title_type_livraison {
        position: absolute;
        z-index: 1;
    }

    .checkbox {
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        background: $white;
        border: 1px solid $mercury;
        border-radius: 50%;
        box-shadow: 0 0 0 3px $white;
        top: 50%;
        left: -7px;
        transform: translateY(-50%);
    }

    &:hover,
    &.active {
        border-color: $primaryColor;

        .checkbox {
            border-color: $primaryColor;
            background: $primaryColor;
        }
    }

    .title_type_livraison {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 346px;
        height: 32px;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        font-size: 18px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 0.08rem;
    }

    .transporteur_info {
        font-size: 18px;
        text-align: center;
        padding-bottom: 9px;
    }

    &.transporteur_Chronopost,
    &.transporteur_DHL {
        .transporteur_info:after {
            width: 108px;
            height: 23px;
            background-size: contain;
        }
    }

    &.transporteur_Chronopost {
        .transporteur_info:after {
            background: transparent url(../img/chronopost.png) no-repeat center;
        }
    }

    &.transporteur_DHL {
        .transporteur_info:after {
            background: transparent url(../img/dhl.png) no-repeat center;
        }
    }

    .choix_delivery {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 14px;
        padding-bottom: 10px;
    }

    .shop_name,
    p.civilite,
    .delivery_form_title {
        font-weight: $bold;
        font-size: 16px;
        letter-spacing: 0.06rem;
    }

    #searchform {
        z-index: 50;
        position: relative;

        .w-input-element {
            width: 100% !important;
        }
    }

    p.adresse {
        &+.societe {
            margin-bottom: 40px;
        }

        &+a.show_modif_info {
            margin-top: 24px;
        }
    }

    a.show_modif_info {
        width: auto;
        height: auto;
        background-color: transparent;
        display: inline-block;
        font-size: 16px;
        font-weight: $light;
        color: $black;
        text-transform: initial;
        text-decoration: underline;
        text-underline-position: under;
        border: 0;
        letter-spacing: 0.04rem;
        box-sizing: border-box;

        /* Override style from dark class on .button elements --> _app master class in HTML */
        &.dark {
            span {
                background-color: transparent;
            }
        }

        span {
            color: $primaryColor;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .delivery_form_title {
        margin: 36px 0 25px;
    }

    .transporteur_delay {
        margin-top: 20px;
        width: 97%;
    }

    .price_tag,
    .fdp_offered {
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 0.04rem;
    }

    .w-input-element:not([type='radio']):not([type='checkbox']).autocomplete_addr_map,
    input[type='text'].w-input-element.autocomplete_addr_map {
        height: 48px !important;
        border-color: $cloud;
        border-radius: 0;
        box-sizing: border-box;
        background: $white url(../img/maps/marker-44.png) no-repeat 98% center;
        background-size: auto 64%;
    }

    .wrapper_locate_relais {
        .displayed_result {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            margin-top: 32px;

            .map_canvas {
                width: 71%;
                max-width: 690px;
                height: 450px;

                @media screen and(max-width: 1300px) {
                    width: 60%;
                }

                .info_popup {
                    .name {
                        text-transform: uppercase;
                        font-weight: 800;
                        letter-spacing: 0.04rem;
                    }

                    .indication {
                        display: none;
                    }

                    .horaire_title {
                        display: none !important;

                        &+table td {
                            width: 100% !important;
                            line-height: 2 !important;
                        }
                    }

                    a.button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 35px;
                        font-size: 10px;
                        letter-spacing: 0.04rem;
                        margin-top: 1rem;
                        padding: 0 1rem;
                    }
                }
            }

            .sidebar-right {
                width: 29%;
                height: 450px;
                padding: 0 0 0 2%;
                box-sizing: border-box;
                padding-left: 1rem !important;

                @media screen and(max-width: 1300px) {
                    width: 40%;
                }

                table.records {
                    max-width: 90%;

                    tr {
                        .kp {
                            border-top: 1px solid $mercury;
                            padding: 24px 0 0;
                            margin-top: 24px;
                            font-size: 14px;
                            line-height: 1;
                        }

                        &:first-child {
                            .kp {
                                border-top: 0;
                                margin-top: 0;
                            }
                        }
                    }
                }

                .distance {
                    display: none;
                }

                .name {
                    font-weight: 800;
                    text-transform: uppercase;
                    letter-spacing: 0.06rem;
                }

                .address {
                    margin: 0.8rem 0;
                    line-height: 1.4;
                }

                a.relayLink {
                    font-style: italic;
                    font-weight: 500;
                    text-decoration: underline;
                    text-underline-position: under;
                }
            }
        }
    }

    .w-dropdown {
        border-radius: 0;
        border-color: $cloud;
        height: 48px;
        box-sizing: border-box;

        .w-input-label {
            font-weight: 13px;
            color: $secondaryColor;
        }
    }

    .elem_global {
        .w-group-label {
            font-weight: 600;
        }

        .choix_type_adresse {
            display: flex;
            font-size: 16px;
            line-height: 1;
            margin-bottom: 1.5rem;

            .type_adresse {
                cursor: pointer;

                &.ami {
                    display: none;
                }
            }
        }

        &.delivery {
            .w-form-line {
                &.w-has-tel {
                    .w-input-container+.w-input-container {
                        .w-input.w-tel-input.w-nonempty {
                            .valid_form {
                                position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
    }
}

.w-form-line.sameBillAddress,
.w-input-group.accountCreate {
    width: fit-content;
    min-width: 335px;
}

/* TUNNEL IDENTIFICATION */
.wrapper_tunnel.inTunnel {
    height: 550px;
}

#adresseForm {
    .w-submit {
        .w-submit-button {
            @extend .dark;
        }
    }
}

form#adresseForm {
    .noaddr {
        color: $primaryColor;
        font-size: 12px;
    }

    .img_error {
        display: none !important;
    }
}

#adresseForm {

    .w-checkbox-input .w-input-element+.w-input-label,
    .w-radio-input .w-input-element+.w-input-label {
        left: 0;
    }

    .w-form-line {
        z-index: 1;

        &.w-submit,
        &.addrType {
            z-index: initial;
        }
    }

    .w-input-group {
        position: relative;
        z-index: 20;
    }
}

#right_col_sticky .wrapper_reinsurance,
#tunnel_right_col .wrapper_reinsurance {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 25px auto;
    justify-content: space-between;
    align-items: baseline;

    .reisurance_item {
        width: 50%;
        margin-bottom: 30px;

        .reisurance_payment {
            margin-top: 10px;
            display: flex;
            width: 135px;
            flex-wrap: wrap;
            justify-content: space-between;
            row-gap: 0.5rem;
            margin-left: 0;

            .pictos_item {
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }

                &.alma {

                    width: 65px;
                    display: flex;
                    border: 1px #ccc solid;
                    justify-content: center;

                    img {
                        max-width: 35px;
                    }
                }
            }
        }
    }

    .reisurance_item>a {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        .reisurance_item_link {
            margin-bottom: 10px;

            &:before {
                width: 25px;
                height: 25px;
            }

            &.garantienoel,
            &.domicilenoel {
                &:before {
                    background-image: url(../img/livraison.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }

            &.deliveryfp::before {
                width: 43px;
                padding-bottom: 4px;
                box-sizing: content-box;
            }
        }

        .title {
            text-transform: uppercase;
            font-size: 13px;
            line-height: 1;
            font-weight: $bold;
        }

        .subtitle {
            font-size: 13px;
            line-height: 1;
            font-weight: $regular;
        }
    }
}

body.checkout {
    .cmd_table {
        display: none !important;
    }

    .wrapper_tunnel {
        display: block;
        max-width: inherit;
    }

    .wrapper_reinsurance {
        display: none !important;
    }

    #cart_total {
        .formPanier_container {
            padding: 0 !important;
        }
    }
}

/* Checkout left side */
.bank_left_side {
    width: 64%;
    max-width: 1060px;
}

.wrapper_bloc_banque {
    position: relative;
    border: 1px solid $mercury;
    margin-bottom: 40px !important;
    margin-top: 2rem;

    &.paypal {
        .bank_title {
            font-size: 0;
        }
    }

    .radio,
    .bank_title {
        position: absolute;
        z-index: 1;
    }

    .radio {
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        background: $white;
        box-shadow: inset 0 0 0 2px $white;
        border: 1px solid $mercury;
        border-radius: 50%;
        top: 50%;
        left: -7px;
        transform: translateY(-50%);
    }

    &:hover,
    &.actif {
        border-color: $primaryColor;

        .radio {
            border-color: $primaryColor;
            background: $primaryColor;
        }
    }

    &.alma {
        &.actif {
            .bank_subtitle {
                display: none;
            }
        }

        .bank_title {
            width: 447px;
        }

        .bank_subtitle {
            display: flex;
            justify-content: center;
            text-decoration: underline;
            cursor: pointer;

            strong {
                font-weight: 300;
            }
        }
    }

    .bank_title {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 346px;
        height: 32px;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        font-size: 25px;
        font-weight: $bold;
        letter-spacing: 0.08rem;

        span {
            margin-bottom: -4px;

            .alma_img {
                width: 65px;
                height: 27px;
                display: inline-block;
                background: url('../img/static_img/alma_img.jpg') center center no-repeat;
                background-size: 100%;
            }
        }
    }

    .bank_subtitle {
        img {
            vertical-align: -0.2rem;
        }
    }

    .total_transaction {
        font-size: 16px;
        font-weight: $bold;
        letter-spacing: 0.04rem;
        margin: 0.5rem 0 1rem;
    }

    .cards-wrapper {
        margin-bottom: 35px;

        img {
            width: auto;
            height: 25px;
            margin-right: 0.1rem;
        }
    }

    &.paypal {
        .bank_title {
            display: flex;

            &:after {
                content: '';
                display: block;
                width: 78px;
                height: 19px;
                background-image: url(../banque/logo/paypal_logo_txt.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                margin-left: 0.8rem;
            }
        }

        .bank_subtitle {
            display: flex;
            flex-direction: column;
            font-weight: $regular;
            margin: 0 auto;

            strong {
                order: 2;
                text-decoration: underline;
                margin-top: 10px;
                text-align: center;
                font-weight: $light;
            }

            >span {
                order: 1;
            }
        }

        input#inputPAYPAL {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
        }

        &:hover .bank_subtitle {
            text-decoration: none;
        }
    }
}

#hipay_applepay_bloc {
    padding: 1.75rem;

    .title_wrapper_fold {
        margin-top: -1.2rem;

        .bank_title {
            top: -26px;
        }

        .total_transaction {
            display: none;
        }
    }

    #hipay-apple-pay-button {
        position: relative;
        height: 1rem;
        padding: 0;
        overflow: hidden;
        max-width: 23rem;
        margin: 1.3rem auto 0;

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 3rem;
            background-color: $white;
            height: 6.95rem;
            top: 0;
            z-index: 2;
        }

        &:after {
            left: inherit;
            right: 0;
        }

        iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: 2rem;
        }
    }
}

.wrapper_basket_content .bank_left_side .paypal .bloc_bank_contents {
    justify-content: center;

    .bank_subtitle.loader_paypal::before {
        background-size: auto 16%;
    }
}

/* Right col For Tunnel Checkout */
#tunnel_right_col {
    #cart_total {
        #keep_alive_coupon {

            .w-has-error:not(.w-dropdown) .w-input-element:not([type='radio']):not([type='checkbox']),
            .w-has-error:not(.w-dropdown) input[type='text'].w-input-element {
                box-shadow: none;
                border: 1px solid $bitterSweet;
            }
        }
    }

    #cart_total {
        padding: 0;
        background-color: $white;

        .recap_cart_title {
            padding-top: 30px;
            padding-left: 30px;
            padding-right: 30px;
            background-color: $whiteLilac;
            order: 1;
        }

        .wrapper_payment {
            order: 7;
            margin-left: 0;
            padding-top: 55px;
        }

        .seeMore {
            z-index: 1;
            padding-left: 30px;
            padding-right: 30px;
            width: inherit;
            padding-bottom: 10px;
            padding-top: 10px;
            margin: 0;
            background-color: $whiteLilac;
            order: 4;
        }

        .bill_line.total_produit {
            padding-left: 30px;
            padding-right: 30px;
            margin-top: 0;
            padding-top: 0px;
            background-color: $whiteLilac;
            order: 2;
        }

        .bill_line.total_produit_dropdown {
            padding-left: 30px;
            padding-right: 30px;
            background-color: $whiteLilac;
            padding-bottom: 30px;
            order: 4;

            .cmd_table_delivery {
                padding-top: 0;
            }
        }

        .bill_line.coupon {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 30px;
            margin-bottom: 0;
            padding-top: 20px;
            background-color: $whiteLilac;
        }

        .bill_line.discount {
            padding-bottom: 30px;
            padding-left: 30px;
            padding-right: 30px;
            background-color: $whiteLilac;
            order: 6;
        }

        .bill_line.promo {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 0;
            background-color: $whiteLilac;
            align-items: center;
            order: 4;
            position: relative;
        }

        .bill_line.used_cp {
            padding-bottom: 15px;
            padding-left: 30px;
            padding-right: 30px;
            background-color: $whiteLilac;
            align-items: center;
            height: 22px;
            order: 5;
        }

        .bill_line.total {
            background-color: $whiteLilac;
            margin-top: 0;
            outline: 30px solid $whiteLilac;
            margin-left: 30px;
            margin-right: 30px;
            margin-bottom: 15px;
            order: 6;
        }

        .formPanier_container {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 30px;
            margin-bottom: 0;
            background-color: $whiteLilac;
            order: 8;
        }
    }

    >.top {
        display: none;
    }
}

/* Login Page Tunnel */
.customer.body_login.cart {
    .wrapper_tunnel {
        margin-top: 300px;

        .tunnel_track_pusher {
            position: absolute;
            top: 250px;
            width: 100%;
            left: 0;
        }
    }
}

.customer.body_login.in_tunnel .wrapper_tunnel {
    margin-top: 250px;

    .tunnel_track_pusher {
        position: absolute;
        top: 170px;
        width: 100%;
        left: 0;
    }
}

// BANK THANKS
.thanksWrapper {
    position: relative;
    max-width: $wrapperSmall;
    margin: 0 auto;

    @media screen and (max-width: $wrapperSmall) {
        padding: 0 2rem;
    }

    .bankThanksTop {
        >h1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-weight: $bold;
            font-size: 100px;
            line-height: 1;
            letter-spacing: unset;
            margin-bottom: 10px;

            @media only screen and (max-width: 1360px) {
                font-size: 80px;
            }

            &::before {
                display: none;
            }

            >span {
                font-weight: $medium;
                font-size: 20px;
                margin-top: 16px;
            }
        }
    }

    .thanksLeftWrapper {
        line-height: 1.2;
        display: flex;
        flex-direction: column;

        #clientForm {
            width: 604px;
            order: 3;
        }

        .seeOrderEvo {
            order: 1;
        }

        .createPwdTxt {
            order: 2;
        }

        .thanksTrust {
            order: 5;
        }

        .bankThanksBtn {
            max-width: 270px;
            margin: 1.5rem 0;
            order: 4;
        }

    }

    .thanksRightWrapper {
        background-color: $bgGrey;
        padding: 35px;

        >p {
            font-size: 15px;
            line-height: 1;
            margin-bottom: 0;
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &.txt_thanks_transaction {
                font-weight: $regular;
            }

            >label {
                font-weight: $medium;
                display: flex;
                align-items: center;

                &::after {
                    content: ':';
                    display: block;
                    padding: 0 4px;
                }
            }

            >span {
                font-weight: $light;
                word-break: break-word;
            }
        }
    }

    /* app rules overrides */
    .thanksRecap {
        flex-wrap: wrap;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 400px;
            background-image: url('../img/static_img/tunnel_bankthanks.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-top: 1.5rem;
        }

        @media screen and (max-width: 1100px) {
            display: block;
            justify-content: unset;
            align-items: unset;
        }

        .thanksRight {
            text-align: initial;
            width: auto;
            position: absolute;
            top: 0;
            right: 0;
            max-width: 50%;

            @media screen and (max-width: 1100px) {
                position: initial;
                display: inline-block;
                width: auto;
                margin-top: 1.5rem;
                max-width: 100%;
            }

            p.txt_thanks_transaction {
                text-transform: initial;
            }
        }
    }

    #around_me_map {
        display: none;
    }
}

// Evo tunnel compte invité
.thanksWrapper .thanksRecap .thanksLeft .thanksLeftWrapper .thanksTrust {
    margin: 0;
}

body.in_tunnel .wrapper_form_adresse .w-newuser-form .choix_type_adresse+.w-form-line {
    display: none;
}

form#hipay-3-form .form_line.saveAlias {
    display: flex;
    column-gap: 1rem;
}

div.transporteur_Mondial {
    padding: 60px 38px 38px;
}

.left_delivery {
    min-width: 30%;
}

body.cart.step_2 .bloc_livraison {
    .store_delivery_hidden_tittle {
        display: block;
        margin-bottom: 33px;
    }
}

body.cart.in_tunnel {
    .w-input:not(.w-dropdown) input[type="tel"].w-input-element:focus {
        &#billtelephone+button.selected-flag {
            +.w-input-label {
                font-style: italic;
                transform: translateY(-115%);
                font-size: .75rem;
                transition: transform .2s ease-out, font-size .2s ease-out, opacity .2s linear, -webkit-transform .2s ease-out;
            }
        }
    }
}