// COVER MODULE
body.homepage {
    width: 100%;
    min-width: 1007px;

    .home-module {
        a[href=""] {
            pointer-events: none;
            cursor: initial;
        }
    }
}

.home-module.slider-cover-module {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
    max-width: 1920px;

    .home-module-bg img {
        width: 100%;
        height: auto;
    }

    iframe {
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(1.0025);
    }

    .slide_video {
        border: 0;
        overflow: hidden;
        padding-bottom: calc(765 / 1920 * 100%);
        position: relative;
        height: 0;

        a {
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: calc(765 / 1920 * 100%);
        }
    }

    .home-module-contents {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .home-module-title-container {
            line-height: 1;
        }

        .home-module-title {
            font-size: 4.1vw;
            font-weight: $bold;
            margin-bottom: 15px;
        }

        .home-module-cta-container {
            text-align: right;

            a {
                justify-content: flex-end;
                text-transform: uppercase;
                font-size: 15px;
                margin-top: 20px;

                &::after {
                    content: "";
                    width: 22px;
                    height: 22px;
                    @include maskIconPath('../svg/spritesheet.svg');
                    @include maskIconCoord(6, 6, 6);
                    display: inline-block;
                    top: 0;
                    bottom: 0;
                    background-color: var(--txtcolor, #ffffff);
                    vertical-align: -5px;
                    margin-left: 5px;
                }
            }
        }

        &.align-left {
            left: 3rem;
            transform: none;
        }

        &.align-center {
            text-align: center;
        }

        &.align-right {
            text-align: right;
        }

        &.align-top {
            top: 3rem;
        }

        &.align-middle {
            left: 50%;
        }

        &.align-bottom {
            bottom: 3rem;
            top: unset;
        }
    }

    .swiper-pagination {
        bottom: 22px;
        left: 50%;
        transform: translate(50%);
    }

    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 10px;
        opacity: 1;
        border: solid 1px #39392f;
        background: transparent;
    }

    .swiper-pagination-bullet-active {
        background: #39392f;
    }
}

// GONDOLE MODULE
.home-module.simple-gondole {
    width: 98%;
    max-width: 1680px;
    margin: 0 auto;
    margin-bottom: 80px;
    position: relative;

    .home-module-title {
        font-weight: $bold;
        font-size: 50px;
        text-transform: uppercase;
        line-height: 1;
        text-align: center;
        margin-bottom: 5px;
    }

    .home-module-subtitle {
        font-weight: $regular;
        font-size: 16px;
        line-height: 1;
        display: block;
        margin-bottom: 30px;
        text-align: center;
    }

    .gondole_swiper {
        margin-bottom: 1rem;

        .swiper-scrollbar {
            height: 3px;
            margin-left: 11.905%;
            width: 67.679%;
        }

        .swiper-pagination {
            height: 3px;
            margin-left: 11.905%;
            width: 67.679%;

            .swiper-pagination-progressbar-fill {
                background-color: rgba(0, 0, 0, .5);
            }
        }

        .iframe_container {
            position: relative;
            padding-bottom: calc(328 / 328 * 100%);

            iframe {
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .link_product_iframe {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }

        &+.swiper-scrollbar {
            height: 3px;
            margin-left: 11.905%;
            width: 67.679%;
        }

        &+.swiper-pagination {
            height: 3px;
            margin-left: 11.905%;
            width: 67.679%;

            .swiper-pagination-progressbar-fill {
                background-color: rgba(0, 0, 0, .5);
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        bottom: -1.625rem;
        top: unset;
        height: initial;
        background-image: none;
        z-index: 2;

        &::before {
            width: 20px;
            height: 20px;
        }
    }

    .swiper-button-next {
        left: 86.5%;
    }

    .swiper-button-prev {
        left: 83%;
    }

    .swiper-button-lock {
        display: none;
    }

    .wrapper_price {
        position: relative;
    }

    .old-price {
        position: absolute;
        font-size: 13px;
        bottom: 0px;
        left: -54px;
        width: 131px;
    }
}

// TEXT MODULE
.home-module.text {
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
    font-weight: $regular;
    font-size: 22px;
    line-height: 1.2;

    strong {
        font-weight: $bold;
    }
}

.home-module {

    // &.multi-bloc-5,
    &.multi-bloc-2,
    &.multi-bloc-3 {

        .home-module-bg,
        .slide_video,
        .container_video {
            position: relative;
            display: block;
            width: 100%;
            height: 0;

            img,
            iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
            }

            // If there is a redirection on video
            iframe {
                pointer-events: none;
            }
        }
    }

    &.multi-bloc-5 {
        .container_video {
            position: relative;
            display: block;
            width: 100%;
            height: 0;

            img,
            iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
            }

            // If there is a redirection on video
            iframe {
                pointer-events: none;
            }
        }
    }
}

// MULTI BLOC 5 MODULE
.home-module.multi-bloc-5 {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 90px;

    .home-module-block-container {
        display: flex;
        justify-content: space-between;
        column-gap: clamp(12.375px, 2.578vw, 49.5px);



        .column_blog {
            width: 29.219%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:first-child {
                .home-module-block {

                    .home-module-bg,
                    .container_video {
                        padding-bottom: calc(350 / 374 * 100%);
                    }

                    +.home-module-block {

                        .home-module-bg,
                        .container_video {
                            padding-bottom: calc(550 / 374 * 100%);
                        }
                    }
                }
            }

            &:nth-child(2) {
                width: 33.829%;

                .home-module-bg,
                .container_video {
                    padding-bottom: calc(945 / 433 * 100%);
                }
            }

            &:last-child {
                .home-module-block {

                    .home-module-bg,
                    .container_video {
                        padding-bottom: calc(550 / 374 * 100%);
                    }

                    +.home-module-block {

                        .home-module-bg,
                        .container_video {
                            padding-bottom: calc(350 / 374 * 100%);
                        }
                    }
                }
            }
        }
    }
}

// MULTI BLOC 2 MODULE
.home-module.multi-bloc-2 {
    width: 100%;
    max-width: 1680px;
    margin: 0 auto;
    margin-bottom: 90px;

    .home-module-block-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .home-module-block {
            width: 72.142857%;

            .home-module-bg,
            .slide_video,
            .container_video {
                padding-bottom: calc(458 / 1212 * 100%);
            }

            +.home-module-block {
                width: 27.26190476%;

                .home-module-bg,
                .slide_video,
                .container_video {
                    padding-bottom: calc(458 / 459 * 100%);
                }
            }
        }
    }
}

/* MULTI BLOC 3 MODULE */
.home-module.multi-bloc-3 {
    width: 100%;
    max-width: 1680px;
    margin: 0 auto;
    margin-bottom: 90px;

    .home-module-block-container {
        display: flex;
        justify-content: space-between;
        column-gap: clamp(18px, 1.875vw, 36px);

        .home-module-block {
            max-width: 524px;
            width: 100%;

            .home-module-bg,
            .slide_video,
            .container_video {
                padding-bottom: calc(333 / 524 * 100%);
            }
        }
    }
}

// SOCIAL MODULE
.home-module.social-module {
    width: 98%;
    max-width: 1680px;
    margin: 0 auto;
    margin-bottom: 75px;

    .home-module-title {
        font-size: 30px;
        font-weight: $bold;
        text-transform: uppercase;
        margin-bottom: 5px;
        line-height: 1;
    }

    .cta_title {
        font-size: 16px;
        margin-bottom: 25px;
        font-weight: $regular;
        line-height: 1;
    }

    .insta_module .swiper-wrapper {
        display: flex;
        justify-content: space-between;

        .insta_picture {
            position: relative;
            max-width: 287px;
            width: calc(20% - 2.55vw);
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            // Theres not enough parents to set padding-top rule on wrapper so do it with pseudo element
            &::before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            >.insta-picture {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: auto;
                transform: translate(-50%, -50%);
            }
        }
    }
}

// BLOG MODULE
.home-module.blog-module {
    max-width: 1280px;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 1rem;
    position: relative;

    .upper_mid_section {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        text-align: center;

        .home-module-title {
            font-weight: $bold;
            line-height: 1;
            margin-bottom: 5px;
            text-transform: uppercase;
            font-size: 50px;
        }

        .home-module-subtitle {
            line-height: 1;
            font-weight: $regular;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .home-module-link {
            line-height: 1;
            font-weight: $extrabold;
            font-size: 15px;
            text-transform: uppercase;

            &::after {
                content: "";
                width: 22px;
                height: 22px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(6, 6, 6);
                display: inline-block;
                top: 0;
                bottom: 0;
                background-color: var(--txtcolor, #000000);
                vertical-align: -5px;
                margin-left: 5px;
            }
        }
    }

    .home-module-blog-container {
        display: flex;
        justify-content: space-between;

        .column_blog:nth-child(1) {
            width: 29%;
            margin-right: 4%;
        }

        .column_blog:nth-child(2) {
            margin-right: 4%;
            margin-top: 120px;
            width: 34%;
        }

        .column_blog:nth-child(3) {
            width: 29%;
        }

        .home-module-blog {
            h3 {
                line-height: 1.2;
                font-weight: $bold;
                font-size: 15px;
                text-transform: uppercase;
                margin-bottom: 3px;
            }

            p {
                line-height: 1.2;
                font-weight: $regular;
                font-size: 16px;
                margin-bottom: 25px;
                height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            img {
                width: 100%;
                height: auto;
                display: block;
                margin-bottom: 15px;
            }
        }
    }
}

// SIGNUP MODULE
.home-module.signup-module {
    max-width: 1280px;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .home-module-body {
        &:nth-child(1) {
            margin-right: 6.5%;
            width: 38.5156%;
            margin-top: 30px;

            img {
                width: 100%;
                height: auto;
            }
        }

        &:nth-child(2) {
            width: 55.3125%;
            line-height: 1;
            margin-bottom: 40px;

            .home-module-title {
                font-weight: $bold;
                font-size: 80px;
                margin-bottom: 15px;
            }

            .home-module-subtitle {
                margin-bottom: 18px;
                font-weight: $medium;
                font-size: 20px;
            }

            .home-module-textcontent {
                margin-bottom: 20px;
                font-weight: $regular;
                font-size: 16px;
                line-height: 1.2;
            }

            .w-newsletter-form {
                width: 100%;
            }

            .w-newsletter-form input[type=email].w-input-element {
                padding-right: 0;
                border-radius: 0;
            }

            .w-input.w-email-input .w-input-label span {
                opacity: 0;
                transition: opacity .2s linear;

                &.visible {
                    opacity: 1;
                }
            }

            .w-form-line.w-submit {
                top: 80px;
                left: 0;

                .w-submit-button {
                    opacity: 0;
                    background-color: black;
                    transition: all .2s linear;
                    width: 270px;

                    &:hover {
                        background-color: white;

                        span {
                            color: black;
                        }
                    }

                    span {
                        font-weight: $regular;
                        font-size: 14px;
                    }

                    &.visible {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// 3 IMAGES MODULE
.home-module.image-image-image {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
    max-width: 1680px;

    .home-columns-container {
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .home-column-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        // Displayed if images
        .home-module-title {
            text-transform: uppercase;
            font-size: 4.15vw;
            font-weight: $bold;

            .home-module-cta {
                display: block;
                text-align: right;
                font-weight: $extrabold;

                span {
                    width: fit-content;
                    margin-left: auto;
                    border: none;

                    &::after {
                        content: "";
                        width: 22px;
                        height: 22px;
                        @include maskIconPath('../svg/spritesheet.svg');
                        @include maskIconCoord(6, 6, 6);
                        display: inline-block;
                        top: 0;
                        bottom: 0;
                        background-color: var(--txtcolor, #ffffff);
                        vertical-align: -5px;
                        margin-left: 5px;
                    }
                }
            }
        }

        // Displayed if images END
    }

    .home-columns-container .insideImg {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .slide_video {
            position: relative;
            width: 100%;
            height: 0;
        }

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }

        .left_part {
            width: 57.8%;
            max-width: 971px;
            position: relative;

            .slide_video {
                padding-bottom: calc(765 / 971 * 100%);
            }
        }

        .right_part {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 41.7%;
            max-width: 699px;

            .top,
            .bottom {
                width: 100%;
                position: relative;
            }

            .top {
                .slide_video {
                    padding-bottom: calc(424 / 699 * 100%);
                }
            }

            .bottom {
                .slide_video {
                    padding-bottom: calc(331 / 699 * 100%);
                }
            }
        }

        article:has(a) {
            cursor: pointer;
        }
    }
}

// style the cta after the widget flowbox
.cta-btn {
    display: block;
    text-align: center;
    width: 200px;
    background: $black;
    color: $white;
    padding: 10px;
    margin: 0px auto 40px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;

    &::after {
        content: "";
        width: 22px;
        height: 22px;
        -webkit-mask-image: url(../svg/spritesheet.svg);
        mask-image: url(../svg/spritesheet.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: 600% auto;
        mask-size: 600% auto;
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
        display: inline-block;
        top: 0;
        bottom: 0;
        background-color: var(--txtcolor, $white);
        vertical-align: -5px;
        margin-left: 10px;
    }
}

/* MODULE 2 IMAGES + TEXTES + CTA*/
.home-module-body.image-image {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-wrap: wrap;
    margin: 1.65rem auto 1.25rem;

    @media screen and(max-width: 1290px) {
        padding: 0 1.5rem;
        box-sizing: border-box;
    }

    .home-columns-container {
        width: 100%;
        max-width: 1280px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-between;
    }

    .home-column {
        width: 50%;
        max-width: 630px;

        @media screen and(max-width: 1290px) {
            width: calc(50% - 10px);

            &:first-child {
                padding-right: 20px;
            }
        }

        .picture-container {
            margin-bottom: 0.9rem;

            img {
                width: 100%;
                height: auto;
            }

            .slide_video {
                display: flex;
                position: relative;
                height: 0;
                width: 100%;
                max-width: 630px;
                padding-bottom: 63.493%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    cursor: pointer;
                    display: block !important;
                }
            }
        }

        .home-module-text {
            display: block;
            line-height: 20px;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: $regular;
            line-height: normal;
            margin-top: 0.9rem;
            margin-bottom: 1.25rem;

            a {
                text-decoration: underline;
            }
        }

        .home-module-cta {
            width: 100%;
            text-align: center;

            span,
            a {
                width: auto;
                height: 42px;
                padding: 4px 24px !important;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-family: $primaryFont;
                font-size: 0.9375rem;
                font-weight: $regular;
            }
        }
    }
}

body {

    &.homepage,
    &.product_page {
        #banner_flowbox_title .ctn {
            display: flex;
            flex-direction: column;
            margin: 0 auto;

            .banner_h1 {
                font-family: $primaryFont;
                font-size: 35px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: .15rem;
                color: $black;
                line-height: 1;
                text-align: center;
                margin: 0 auto;

                +.banner_h2 {
                    font-family: $primaryFont;
                    font-size: 20px;
                    font-weight: 400;
                    letter-spacing: .8px;
                    color: $black;
                    line-height: 1;
                    text-align: center;
                    margin: 0 auto;
                    margin-top: 0.8rem;

                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}