/* TEMPORARY HIDDING */
.pictos_item {
    &.amazon {
        display: none;
    }
}

/* ***************** */


body {
    font-family: $primaryFont;
    color: $primaryColor;
}

.close_pop {
    cursor: pointer;
}

.cache,
[v-cloak] {
    display: none;
}

.swiper-button-prev,
.swiper-button-next {
    z-index: 3;
}

// Exceptions
.product_page {
    .main_ariane {
        @media screen and (max-width: $wrapperMedium) {
            padding: 0;
        }
    }
}

//


.main_ariane {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: $wrapperMedium;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

    @media screen and (max-width: $wrapperMedium) {
        padding: 0 2rem;
    }

    .breadcrumb.current {
        color: $primaryColor;
        margin-left: 7px;

        span {
            text-transform: none;
        }
    }

    .breadcrumb {
        font-weight: $regular;
        font-size: 13px;
        color: #777777;
        display: inline-block;

        &:not(:first-child) {
            margin-left: 7px;
        }

        &::first-letter {
            text-transform: uppercase;
        }

        span {
            text-transform: lowercase;
        }
    }

    .breadcrumb:after {
        content: '|';
        margin-left: 10px;
        display: inline;
    }

    .breadcrumb.current:after {
        content: none;
    }
}

.shad {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    overflow-y: auto;
    transition: all .3s ease-in-out;

}

.shad.actif {
    z-index: 10;
    opacity: 1;

    &#shad_menu {
        z-index: 8;
    }
}

// PRICES
.wrapper_price {
    display: flex;
    align-items: baseline;
    padding-left: 1rem;
}

// wishlist page
.nrArticlesSelected #total_price {
    white-space: nowrap;
    font-size: 24px;
    font-weight: $extrabold;
    display: inline-block;

    .decimal_price {
        font-size: 15px;
        vertical-align: .5em;
    }

    .devise {
        font-size: 12px;
        font-weight: $regular;
        vertical-align: .75em;
    }
}

// Tunnel
.step_2 .bloc_livraison .price_tag,
.pricetag {
    white-space: nowrap;
    font-size: 24px;
    font-weight: $extrabold;

    &.crossed {
        font-size: 18px;
        position: relative;
        padding-right: .5rem;
        color: $txtGrey;

        &::after {
            box-sizing: border-box;
            content: "";
            display: block;
            height: 1px;
            width: 90%;
            background-color: $txtGrey;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-56%, -50%) rotate(-18deg);
        }

        .cents {
            font-size: 13px;
            vertical-align: .4em;
            margin-left: -3px;
        }

        .currency,
        .devise {
            font-size: 11px;
            vertical-align: .55em;
        }
    }

    .cents {
        font-size: .6em;
        vertical-align: .5em;
        margin-left: -6px;
    }

    .currency,
    .devise {
        font-size: .5em;
        margin-left: 1px;
    }

    .currency {
        font-size: 12px;
        font-weight: $regular;
        vertical-align: .75em;
    }
}

.product-link {
    .pricetag, 
    .pricetag.crossed {
        font-size: 20px;
    } 
}
/**
 * Overlay scrollbars
 */
.os-theme-dark>.os-scrollbar>.os-scrollbar-track {
    border-radius: 0;
    background: #f5f5f5;
}

.os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
    background: #000;
    margin-left: 2.5px;
}

/* Overlay scrollbars for bundle produit */ 
.bloc_infos.bord {
      .os-theme-dark>.os-scrollbar>.os-scrollbar-track {
        border-radius: 0;
        background: #ece8e8;
    }

    .os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
        background: #000;
        margin-left: unset;
        width: 3px;
    }
}

.bloc_infos.bord.first_bloc_infos {
    .os-theme-dark>.os-scrollbar.os-scrollbar-vertical {
        top: 26px;
        right: 25px;
    } 
}

// SWIPERS

.swiper-button-disabled {
    opacity: .45;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.swiper-pagination-progressbar.swiper-pagination {
    z-index: 1;
}

div.transporteur_Mondial {
    padding: 60px 38px 38px;
}

.left_delivery {
    min-width: 30%;
}

#flowbox-container {
    /* on s'en sert pas du titre dans le widget */
    .bzfy-t-feed-carousel .bzfy-feed-banner {
        display: none;
    }
}

#banner_flowbox_title {
    margin-top: 80px;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    box-sizing: border-box;

    .banner_h1 {
        font-family: $primaryFont;
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0.2175rem;
        text-transform: uppercase;
        margin: 0 auto;

        span {
            font-family: $secondaryFont;
            font-weight: 700;
            text-transform: lowercase;
            font-size: 2.38rem;
            display: inline-block;
            letter-spacing: .2rem;
            margin-top: 2px;

            .hash {
                font-weight: 400;
                font-size: 2.8rem;
            }
        }
    }

    .banner_h2 {
        font-family: $primaryFont;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0 auto;
        letter-spacing: 0.8px;
        margin-top: 0.8rem;

        span {
            font-weight: 700;
        }
    }
}

a.button.primary.flowboxBtn {
    display: none;
}

body.inspiration_flowbox {
    #inspiration-flowbox-container {
        min-height: 140px;
        max-width: 1920px;
        margin: auto;

        .bzfy-feed {
            .bzfy-feed-banner {
                display: none;
            }
        }
    }

    #banner_flowbox_title {
        margin-bottom: 25px;
        margin-top: 20px;
        justify-content: center;
        width: 95%;
        margin-left: 2.5%;

        .banner_h2 {
            font-size: 20px;
            color:$black;
            line-height: 1;
            text-align: center;
        }

        .banner_h1 {
            font-size: 35px;
            color: $black;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            letter-spacing: 0.15rem;
        }
    }

    .flowboxBtn {
        display: none;
    }

    #banner_flowbox_cta {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        max-width: 1500px;
        margin: auto;
        margin-top: 15px;

        .flowbox_btn_cta {
            display: flex;
            align-items: center;
            height: 2rem;
            border-radius: 15px;
            white-space: nowrap;
            cursor: pointer;
            margin: 0px 11px 15px 11px;
            text-decoration: none;
            text-transform: none;
            justify-content: center;
            width: 8rem;

            &#cta_rangement {
                background-color: $reviewstar;
                border: 1px solid $reviewstar;

                &:hover {   
                    border: 1px solid $reviewstar;
                    background-color: $white;

                    .cta_name {
                        color: $reviewstar;
                    }
                }
            }

            &#cta_cuisine {
                background-color: $camouflageGreen;
                border: 1px solid $camouflageGreen;

                &:hover {   
                    border: 1px solid $camouflageGreen;
                    background-color: $white;

                    .cta_name {
                        color: $camouflageGreen;
                    }
                }
            }

            &#cta_salle_de_bain {
                background-color: $cerise;
                border: 1px solid $cerise;

                &:hover {   
                    border: 1px solid $cerise;
                    background-color: $white;

                    .cta_name {
                        color: $cerise;
                    }
                }
            }

            &#cta_dressing {
                background-color: $navy;
                border: 1px solid $navy;

                &:hover {   
                    border: 1px solid $navy;
                    background-color: $white;

                    .cta_name {
                        color: $navy;
                    }
                }
            }

            &#cta_buanderie {
                background-color: $starDust;
                border: 1px solid $starDust;

                &:hover {   
                    border: 1px solid $starDust;
                    background-color: $white;

                    .cta_name {
                        color: $starDust;
                    }
                }
            }

            &#cta_all {
                background-color: $black;
                border: 1px solid $black;

                &:hover {   
                    border: 1px solid $black;
                    background-color: $white;

                    .cta_name {
                        color: $black;
                    }
                }
            }

            .cta_name {
                padding: 0 20px 2px;
                font-size: clamp(13px, 0.729vw, 14px);
                letter-spacing: .05rem;
                color: $white;
                font-family: $tertiaryFont;
                font-weight: 300;
            }
        }
    }

    #flowbox_loader {
        width: 200px;
        height: 70px;
        background: url(../svg/three_dots.svg) no-repeat center;
        background-size: auto 20%;
        position: absolute;
        bottom: -120px;
    }
}
/** WOOSMAP **/
#adresseForm .w-form-line.addrType, #adresseForm .w-form-line.w-submit {
    z-index: initial;
}

.autocomplete-results {
    position: absolute;
    background-color: #fff;
    padding: 0;
    list-style-type: none;
    margin: 0;
    border: 1px solid #d9d9d9;
    border-top: 0;
    box-sizing: border-box;
    z-index: 99;
    width: 100%;
    top: 100%;

    .autocomplete-item {
        padding: 5px 5px 5px 35px;
        height: 26px;
        font-size: 13px;
        font-family: $primaryFont;
        line-height: 26px;
        border-top: 1px solid #d9d9d9;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
            background-color: #f2f2f2;
            cursor: pointer;
        }

        .autocomplete-icon {
            display: block;
            position: absolute;
            top: 7px;
            bottom: 0;
            left: 8px;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .autocomplete-icon.icon-localities {
            background-image: url(https://images.woosmap.com/icons/locality.svg);
        }

        .autocomplete-item:hover .autocomplete-icon.icon-localities {
            background-image: url(https://images.woosmap.com/icons/locality-selected.svg);
        }

        .autocomplete-item:hover {
            background-color: #f2f2f2;
            cursor: pointer;
        }
    }
}
// OPTIN DATA TREATMENT INFO
.data_treatment {
    color: $errormsgadd;
    font-size: 13px;
    margin-top: 18px;

    a {
        text-decoration: underline;
        color: $errormsgadd;
    }
}