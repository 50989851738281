//GENERAL
.cms-page-module-img {
    width: 100%;
    height: auto;
    display: block;
}

.cms-page .products_list .swiper-button-prev {
    background-position: 20% 20%;
}

.cms_page #cms_page_btn_back,
.cms_page #cms_page_title,
.cms_page_new #cms_page_btn_back,
.cms_page_new #cms_page_title {
    display: none;
}

.cms_page strong,
.cms_page_new strong {
    font-weight: $bold;
}

/* Module with video */
.cms_page_new .picture-container {
    img {
        width: 100%;
    }

    .slide_video {
        display: flex;
        position: relative;
        height: 0;
        width: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        a {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
            cursor: pointer;
            display: block !important;
        }
    }
}

// SOCIAL MODULE
.cms-page-module.social-module {
    width: 98%;
    max-width: 1680px;
    margin: 0 auto;
    margin-bottom: 75px;

    .cms-page-module-title {
        font-size: 30px;
        font-weight: $bold;
        text-transform: uppercase;
        margin-bottom: 5px;
        line-height: 1;
    }

    .cta_title {
        font-size: 16px;
        margin-bottom: 25px;
        font-weight: $regular;
        line-height: 1;
    }

    .insta_module .swiper-wrapper {
        display: flex;
        justify-content: space-between;

        .insta_picture {
            position: relative;
            max-width: 287px;
            width: calc(20% - 2.55vw);
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            // Theres not enough parents to set padding-top rule on wrapper so do it with pseudo element
            &::before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            >.content__img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: auto;
                transform: translate(-50%, -50%);
            }
        }
    }
}

//MODULE 2 IMG
.cms-page-module.text-image-3 {
    display: flex;
    justify-content: space-between;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 70px;

    .cms-page-column {
        margin-right: 25px;
    }
}

//MODULE TEXT 1
.cms-page-module.text-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 1024px;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 60px;

    .cms-page-column-title {
        font-weight: $regular;
        font-size: 116px;
        line-height: 1;
    }

    .cms-page-column-subtitle {
        font-weight: $bold;
        font-size: 18px;
        margin-bottom: 5px;
    }

    .cms-page-column-txt {
        font-weight: $regular;
        font-size: 16px;
    }
}

//MODULE TEXT IMAGE 1
.cms-page-module.image-1 {
    margin-bottom: 3.15rem;

    .cms-page-module-img {
        width: auto;
        margin: 0 auto;
    }
}

//MODULE IMAGE TEXT 1
.cms-page-module.text-image-1 {
    display: flex;
    align-items: center;
    max-width: 1280px;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 35px;

    .left_part {
        margin-left: 60px;
        margin-right: 110px;
        width: 41%;
    }

    .cms-column-title {
        font-weight: $regular;
        font-size: 116px;
        line-height: 0.8;
        margin-right: 15px;
    }

    .right_part {
        padding-right: 60px;
        width: 42%;
    }
}

//MODULE PRODUCT LIST
.cms-page-module.products_list {
    display: flex;
    max-width: 1280px;
    width: 98%;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 50px;

    .slide_video {
        display: flex;
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 114.706%;
        position: sticky;
        top: 120px;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        a.cta_video_redirect {
            display: block !important;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
            cursor: pointer;
        }
    }

    .left_part {
        width: 53.125%;
        padding-top: 48px;

        .cms-page-column-title {
            display: none;
        }

        .cms-page-module-img {
            position: sticky;
            top: 120px;
        }
    }

    .right_part {
        height: fit-content;
        width: 41.8%;

        .text_part {
            margin-top: 2rem;
    
            .cms-page-column-title {
                font-family: $primaryFont;
                font-size: 50px;
                font-style: normal;
                font-weight: $bold;
                line-height: normal;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 8px;
            }
        
            .cms-page-column-txt {
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                margin-bottom: 30px;
            }
        }

        .cms-page-cta {
            margin: 30px auto 0;
            display: flex;
            width: 271px;
            height: 50px;
            padding: 4px 24px;
            border: 1px solid $black;
            justify-content: center;
            align-items: center;
            font-family: $primaryFont;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            text-align: center;
            color: $white;
            background: $black;
            box-sizing: border-box;

            &:hover {
                color: $black;
                background: $white;
            }
        }
    }

    #swiperImageTextList {
        padding-bottom: 10px;
    }

    .content_products {
        .swiper-slide {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .cms-page-column {
            width: calc(50% - 15px);
            margin-bottom: 20px;

            &:nth-last-child(2),
            &:last-child {
                margin-bottom: 0;
            }
        }

        .wrapperProdVertical {
            display: block;
        }

        .item_name {
            text-overflow: ellipsis;
            display: inline;
            text-wrap: nowrap;
        }

        .wrapper_description {
            padding: 15px 0 0 10px;
        }

        .wrapper_price .pricetag {
            line-height: 1.2;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-image: none;
        height: 10px;
        top: unset;
        bottom: 0;
        left: unset;
        right: -4px;

        &:before {
            width: 20px;
            height: 20px;
        }
    }

    .swiper-button-prev {
        right: 34px;
    }

    .swiper-pagination {
        bottom: 0;
        width: calc(100% - 84px);
        height: 3px;
        margin-bottom: 3px;

        .swiper-pagination-progressbar-fill {
            background-color: rgba(0, 0, 0, .5);
        }
    }

    .iframe_container {
        position: relative;
        padding-bottom: calc(328 / 328 * 100%);

        iframe {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .link_product_iframe {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}

//MODULE ARTICLES LIST
.cms-page-module.articles-list {
    display: flex;
    max-width: 1280px;
    width: 98%;
    justify-content: space-between;
    margin: 0 auto;

    .title_cms_articles {
        font-weight: $bold;
        font-size: 30px;
        margin-bottom: 30px;
        line-height: 1.3;
        text-align: center;
    }

    img {
        width: 100%;
        height: 255px;
        object-fit: cover;
    }

    .name {
        font-weight: $bold;
        font-size: 15px;
        text-transform: uppercase;
        line-height: 1;
        margin-top: 10px;
        margin-bottom: 5px;

        @media screen and(max-width: 1024px) {
            font-size: 14px;
        }
    }

    .description {
        font-weight: $regular;
        font-size: 16px;
        line-height: 1.2;
    }

    #articles_list_swiper {
        padding-bottom: 75px;
        margin-bottom: 40px;
        width: 100%;

        &:not(.swiper-initialized) {
            margin-bottom: 0;

            .swiper-wrapper {
                justify-content: space-between;
            }

            .swiper-slide {
                width: calc(33% - 29px);
            }

            .swiper-scrollbar,
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }
        }

        .swiper-pagination {
            height: 3px;
            width: 88%;
            position: absolute;
            bottom: 25px;

            .swiper-pagination-progressbar-fill {
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
    }

    .swiper-scrollbar {
        height: 3px;
        width: 88%;
        position: absolute;
        bottom: 25px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        bottom: 17px;
        top: unset;
        height: initial;
        background-image: none;
        z-index: 2;

        &::before {
            width: 20px;
            height: 20px;
        }
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev {
        left: 90%;
    }
}

// MODULE TEXT GAMME
.cms-page-module.image-text-gamme {
    max-width: 1680px;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 70px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 423px;
    position: relative;

    @media screen and (min-width: 1700px) {
        width: 100%;
    }

    @media screen and (max-width: 1570px ) {
        background-size: contain;
    }

    >div {
        position: absolute;
        height: calc(100% - 40px);
        top: 0;
        left: 0;
        border: solid 10px;
        box-sizing: border-box;
        padding: 50px;
        max-width: 480px;
        margin: 20px;

        .cms-column-title {
            font-weight: $bold;
            line-height: 1;
            font-size: 50px;
        }

        .cms-column-txt {
            line-height: 1.2;
            max-height: 115px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 10px;
        }

        .cms-column-cta {
            font-weight: $extrabold;
            font-size: 15px;
            text-transform: uppercase;
            position: absolute;
            bottom: 40px;
            right: 50px;
            height: fit-content;

            &::after {
                margin-left: 10px;
                height: 20px;
                width: 20px;
                margin-bottom: 1px;
            }
        }
    }
}

//MODULE BLOG
.cms_page .cms-module.blog-module,
.cms_page_new .cms-module.blog-module {
    margin-bottom: 70px;
    max-width: 1280px;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 1rem;
    position: relative;

    .upper_mid_section {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        text-align: center;
        width: 34%;
    }

    .cms-module-link {
        line-height: 1;
        font-weight: 800;
        font-size: 15px;
        text-transform: uppercase;
    }

    .cms-module-blog-container {
        display: flex;
        justify-content: space-between;

        .column_blog {
            &:nth-child(1) {
                width: 29%;
                margin-right: 4%;
            }

            &:nth-child(2) {
                margin-right: 4%;
                margin-top: 195px;
                width: 34%;

                @media screen and (min-width: 1100px ) {
                    margin-top: 160px;
                }
            }

            &:nth-child(3) {
                width: 29%;
            }

            .cms-module-blog {
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                    margin-bottom: 15px;
                }

                h3 {
                    line-height: 1.2;
                    font-weight: 700;
                    font-size: 15px;
                    text-transform: uppercase;
                    margin-bottom: 3px;
                }

                p {
                    line-height: 1.2;
                    font-weight: 400;
                    font-size: 16px;
                    margin-bottom: 25px;
                    height: 40px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}


//MODULE 4 / MODULE 5 IMAGES
.cms-module.multi-bloc-5.page-adv {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto 50px;

    img {
        width: 100%;
        height: auto;
    }

    /* Video */
    .video-container {
        position: relative;

        a.cms-module-bg.cms-ga-module-4 {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
        }

        .slide_video {
            position: relative;
            padding-bottom: 109.091%;
            height: 0;
            overflow: hidden;
            clear: both;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .cms-module-block-container {
        display: flex;
        justify-content: space-between;
        column-gap: 15px;

        .column_blog:nth-child(1),
        .column_blog:nth-child(3) {
            width: 32.978%;
            display: grid;
            row-gap: 17px;
        }

        .column_blog:nth-child(2) {
            width: 33.11%;
        }

        .column_blog:nth-child(2) {

            /* Video */
            .video-container {
                .slide_video {
                    padding-bottom: 221.275%;

                    @media screen and(max-width: 1559px) {
                        padding-bottom: 221.8%;
                    }

                    @media screen and(max-width: 1440px) {
                        padding-bottom: 222%;
                    }

                    @media screen and(max-width: 1366px) {
                        padding-bottom: 222.4%;
                    }

                    @media screen and(max-width: 1280px) {
                        padding-bottom: 222.7%;
                    }

                    @media screen and(max-width: 1190px) {
                        padding-bottom: 222.8%;
                    }
                }
            }
        }
    }

    .cms-module-block {
        position: relative;
        line-height: 0;

        .blog-btn-container {
            position: absolute;
            top: -0.7rem;
            left: 0.03rem;
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: center;

            @media screen and(max-width: 1024px) {
                top: 0;
            }
        }

        .cms-column-title {
            font-family: $primaryFont;
            font-size: 50px;
            font-style: normal;
            font-weight: $bold;
            line-height: normal;
            text-transform: uppercase;
            color: $white;
            margin: 0 auto;
            text-align: center;
        }

        .content_2 {
            display: block;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.125rem;

            @media screen and(max-width: 1920px) {
                height: 2.6vw;
            }

            @media screen and(max-width: 1024px) {
                height: 2rem;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $black;
                text-align: center;
                width: 8.3125rem;
                height: 3.125rem;

                @media screen and(max-width: 1920px) {
                    width: 6.927vw;
                    height: 2.6vw;
                }

                @media screen and(max-width: 1024px) {
                    width: 6rem;
                    height: 2.25rem;
                }

                span {
                    color: $white;
                    font-size: 0.9375rem;
                    font-family: $primaryFont;
                    font-weight: $regular;
                    text-transform: uppercase;

                    @media screen and(max-width: 1920px) {
                        font-size: 0.85vw;
                    }

                    @media screen and(max-width: 1024px) {
                        font-size: 0.65rem;
                    }
                }
            }
        }
    }
}

// TG MODULE PAGE ADV
.tg-module {
    .swiper-button-lock {
        display: none;
    }
}
.tg-module.module_adv {
    width: 98%;
    max-width: 1680px;
    margin: 1.6rem auto 2.1rem;
    margin-bottom: 80px;
    position: relative;

    .cms-module-title {
        font-weight: $bold;
        font-size: 50px;
        text-transform: uppercase;
        line-height: 1;
        text-align: center;
        margin-bottom: 5px;
    }

    .cms-module-subtitle {
        font-weight: $regular;
        font-size: 16px;
        line-height: 1;
        display: block;
        margin-bottom: 30px;
        text-align: center;
    }

    .cms-column {
        position: relative;
    }

    .gondole_swiper {
        margin-bottom: 1rem;

        .wrapper_description .pricetag .cents {
            margin-left: 0;
        }

        .eclat_product {
            z-index: 2;
        }

        .container_eclats img {
            @media screen and(max-width: 1024px) {
                width: 100%;
                height: auto;
            }
        }

        &+.swiper-scrollbar {
            height: 3px;
            margin-left: 11.905%;
            width: 67.679%;
        }

        &+.swiper-pagination {
            height: 3px;
            margin-left: 11.905%;
            width: 67.679%;

            .swiper-pagination-progressbar-fill {
                background-color: rgba(0, 0, 0, .5);
            }
        }

        .iframe_container {
            position: relative;
            padding-bottom: calc(328 / 328 * 100%);

            iframe {
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .link_product_iframe {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }

        .swiper-pagination.swiper-pagination-progressbar {
            height: 3px;
            margin-left: 5%;
            width: 67.679%;
            background: $gainsboro;
            bottom: 0;

            .swiper-pagination-progressbar-fill {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 100%;
                transform-origin: left top;
                background-color: fade($black, 20%);
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        bottom: -1.65rem;
        top: unset;
        height: initial;
        background-image: none;
        z-index: 2;

        &::before {
            width: 20px;
            height: 20px;
        }
    }

    .swiper-button-next {
        left: 86.5%;
    }

    .swiper-button-prev {
        left: 83%;
    }
}

//MODULE 3 / MODULE 4 adv
.cms-page-module.text-image-1.page-adv,
.cms-page-module.image-text-1.page-adv {
    width: 100%;
    min-width: 1007px;
    max-width: 1116px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 3.15rem;

    @media screen and(max-width: 1024px) {
        padding: 0 3% !important;
    }

    .left_part,
    .right_part_inverse {
        width: 41.85%;
        margin: 0;
        position: relative;

        @media screen and (min-width: 1224px) {
            width: 46.85%;
        }

        a.module-3-video,
        a.module-4-video {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .slide_video {
            position: relative;
            padding-bottom: 94%;
            height: 0;
            overflow: hidden;
            clear: both;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .right_part,
    .left_part_inverse {
        width: 53.15%;
        box-sizing: border-box;
        padding: 0;
        padding-left: 7.4rem;

        .cms-page-column-title {
            font-family: $primaryFont;
            font-size: 50px;
            font-style: normal;
            font-weight: $bold;
            line-height: normal;
            text-transform: uppercase;
        }

        .description-ctn {
            .cms-page-column-subtitle {
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 20px;
            }

            .cms-page-column-txt {
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 20px;
            }
        }

        .content_2 {
            display: block;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            height: 3.125rem;
    
            @media screen and(max-width: 1099px) {
                height: 3rem;
            }
    
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $black;
                text-align: center;
                width: 8.3125rem;
                height: 3.125rem;
    
                span {
                    color: $white;
                    font-size: 0.9375rem;
                    font-family: $primaryFont;
                    font-weight: $regular;
                    text-transform: uppercase;
                }
            }
        }
    }

    .left_part_inverse {
        padding-left: 0;
        padding-right: 7.4rem;
        margin-top: -5px;
    }
}

body.cms_page_new {
    .cms_page_content {
        width: 100%;
        min-width: 1007px;
    }

    /* MODULE Title*/
    .cms-page-module-body.line-text.module-title {
        width: 100%;
        margin: 0 auto;
        max-width: 1280px;

        .cms-page-module-title {
            margin-bottom: 30px;

            @media screen and(max-width: 1290px) {
                padding: 0rem 1.5rem;
                box-sizing: border-box;
            }

            span {
                font-family: $primaryFont;
                text-transform: uppercase;
                font-size: 30px;
            }
        }
    }

    /* MODULE sub-title*/
    .cms-page-module-body.line-text.module-subtitle {
        width: 100%;
        margin: 0 auto;
        max-width: 1280px;

        .cms-page-module-subtitle {
            margin-bottom: 1rem;

            @media screen and(max-width: 1290px) {
                margin-bottom: unset;
                padding: .7rem 1.5rem;
                box-sizing: border-box;
            }

            span {
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: $regular;
                line-height: normal;
                margin-top: -3px;
                display: block;
            }
        }
    }

    /* MODULE TEXTE */
    .cms-page-module-body.line-text.module-text {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        @media screen and(max-width: 1290px) {
            padding: .7rem 1.5rem;
            box-sizing: border-box;
        }

        .cms-page-module-text {
            margin: 0 0 1.25rem;

            div span {
                display: inline-block;
                line-height: 20px;
                font-family: $primaryFont;
                font-size: 1rem;
                font-weight: $regular;

                ul {
                    list-style: unset;
                    padding-left: 50px;

                    li {
                        list-style: unset;
                    }
                }
            }
        }
    }

    /* MODULE CTA */
    .cms-page-module-body.line-text.module-cta {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto 2.1rem;

        @media screen and(max-width: 1290px) {
            padding: 0 1.5rem;
            box-sizing: border-box;
        }

        .cms-page-module-cta {
            div {
                display: block;
                width: 100%;
                max-width: 1280px;
                margin: 0 auto;
                height: auto;

                span,
                a {
                    width: auto;
                    height: 42px;
                    padding: 4px 24px !important;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $primaryFont;
                    font-size: 0.9375rem;
                }
            }
        }
    }

    /* MODULE BANNER*/
    .cms-page-module-body.image-2.banner-image {
        margin-bottom: 0.9rem;

        .picture-container {
            img {
                width: 100%;
                height: auto;
            }

            .slide_video {
                max-width: 1920px;
                padding-bottom: 14.977%;
            }
        }
    }

    /* MODULE IMAGE */
    .cms-page-module-body.image-2.simple-image {
        margin-top: 1.6rem;
        margin-bottom: 1.5rem;

        @media screen and(max-width: 1290px) {
            padding: 0 1.5rem;
            box-sizing: border-box;
        }

        .picture-container {
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                height: auto;
            }

            .slide_video {
                max-width: 1280px;
                padding-bottom: 37.5rem;

                @media screen and(max-width: 1050px) {
                    padding-bottom: 29.5rem;
                }
            }
        }
    }

    /* MODULE 2 IMAGES + TEXTES + CTA*/
    .cms-page-module-body.two-images-text {
        width: 100%;
        max-width: 1280px;
        display: flex;
        flex-wrap: wrap;
        margin: 1.65rem auto 1.25rem;

        @media screen and(max-width: 1290px) {
            padding: 0 1.5rem;
            box-sizing: border-box;
        }

        .cms-page-columns-container {
            width: 100%;
            max-width: 1280px;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: space-between;
        }

        .cms-page-column {
            width: 50%;
            max-width: 630px;

            @media screen and(max-width: 1290px) {
                width: calc(50% - 10px);

                &:first-child {
                    padding-right: 20px;
                }
            }

            .picture-container {
                margin-bottom: 0.9rem;

                img {
                    width: 100%;
                    height: auto;
                }

                .slide_video {
                    max-width: 630px;
                    padding-bottom: 63.493%;
                }
            }

            .cms-page-module-text {
                display: block;
                line-height: 20px;
                font-family: $primaryFont;
                font-size: 16px !important;
                font-style: normal;
                font-weight: $regular;
                line-height: normal;
                margin-top: 0.9rem;
                margin-bottom: 1.25rem;

                a {
                    text-decoration: underline;
                }
            }

            .cms-page-module-cta {
                width: 100%;
                text-align: center;

                span,
                a {
                    width: auto;
                    height: 42px;
                    padding: 4px 24px !important;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $primaryFont;
                    font-size: 0.9375rem;
                    font-weight: $regular;
                }
            }
        }
    }

    /* MODULE 3 OU 4 IMAGE + TEXTES + CTA */
    .cms-page-module-body.three-images-three-cta {
        width: 100%;
        max-width: 1350px;
        margin: 1.6rem auto 2.1rem;

        @media screen and(max-width: 1290px) {
            padding: 0 1.5rem;
            box-sizing: border-box;
        }

        .cms-page-columns-container {
            width: 100%;
            max-width: 1460px;
            display: flex;
            justify-content: center;

            .cms-page-column {
                width: 22.603%;
                padding-right: 20px;

                &:last-child {
                    padding-right: 0;
                }

                .picture-container {
                    img {
                        width: 100%;
                        height: auto;
                    }

                    .slide_video {
                        max-width: 350px;
                        padding-bottom: 120%;
                    }
                }
            }

            .cms-page-module-text {
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: $regular;
                line-height: normal;
                margin-top: 0.875rem;
                margin-bottom: 1.25rem;

                a {
                    text-decoration: underline;
                }
            }

            .cms-page-module-cta {
                width: 100%;
                text-align: center;

                span,
                a {
                    width: auto;
                    height: 42px;
                    padding: 4px 24px !important;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $primaryFont;
                    font-size: 0.9375rem;
                    font-weight: $regular;
                }
            }
        }
    }

    /* MODULE CATEGORIES */
    .cms-page-module-body.categories {
        width: 100%;
        max-width: 1680px;
        margin: 1.6rem auto 3.8rem;
        position: relative;
        padding: 0 2rem;

        @media screen and (max-width: 1768px) {
            width: 95%;
        }

        @media screen and (max-width: 1700px) {
            margin: 0 auto 3rem;
            box-sizing: border-box;
        }

        .swiperCategories {
            margin-right: 30px;
            position: unset;

            &.center_slides {
                .cms-page-columns-container {
                    justify-content: center;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    display: none;
                }
            }

            .picture-container {
                img {
                    width: 100%;
                    height: auto;
                }

                .slide_video {
                    max-width: 282px;
                    padding-bottom: 63.775%;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                    }
                }
            }

            .cms-page-module-text {
                font-family: $primaryFont;
                font-size: 18px;
                font-style: normal;
                line-height: normal;
                margin-top: 0.5rem;
                text-align: center;
            }

            .swiper-button-prev,
            .swiper-button-next {
                background-image: unset;
                top: 3px;
                right: unset;
                left: unset;

                &:before {
                    width: 20px;
                    height: 20px;
                }
            }

            .swiper-button-next {
                right: 30px;
            }

            .swiper-button-prev {
                left: 0;
            }

            &.no-arrow {

                .swiper-button-prev,
                .swiper-button-next {
                    display: none;
                }
            }
        }
    }

    .lienSeo {
        margin: 60px auto 40px auto;
        max-width: 1220px;

        .textSeo_bloc_liens {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px 10px;
            flex-wrap: wrap;
            width: auto;

            .textSeo_bloc_list_items a {
                padding: 6px 20px;
                border-radius: 20px;
                background: $bgGrey;
                color: $black;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: .7px;
                text-decoration: none;
                display: block;
            }
        }

    }
}

// SIGNUP MODULE
.cms-page-module.signup-module {
    max-width: 1280px;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cms-page-module-body {
        &:nth-child(1) {
            margin-right: 6.5%;
            width: 38.5156%;
            margin-top: 30px;

            img {
                width: 100%;
                height: auto;
            }
        }

        &:nth-child(2) {
            width: 55.3125%;
            line-height: 1;
            margin-bottom: 40px;

            .cms-page-module-title {
                font-weight: $bold;
                font-size: 80px;
                margin-bottom: 15px;
            }

            .cms-page-module-subtitle {
                margin-bottom: 18px;
                font-weight: $medium;
                font-size: 20px;
            }

            .cms-page-module-textcontent {
                margin-bottom: 20px;
                font-weight: $regular;
                font-size: 16px;
                line-height: 1.2;
            }

            .w-newsletter-form {
                width: 100%;
            }

            .w-newsletter-form input[type=email].w-input-element {
                padding-right: 0;
                border-radius: 0;
            }

            .w-input.w-email-input .w-input-label span {
                opacity: 0;
                transition: opacity .2s linear;

                &.visible {
                    opacity: 1;
                }
            }

            .w-form-line.w-submit {
                top: 80px;
                left: 0;

                .w-submit-button {
                    background-color: black;
                    transition: all .2s linear;
                    width: 270px;

                    &:hover {
                        span {
                            color: black;
                            background-color: $white;
                        }
                    }

                    span {
                        font-weight: $regular;
                        font-size: 14px;
                        color: $white;
                    }

                    &.visible {
                        opacity: 1;
                    }

                    &.w-loader {
                        &.loading {
                            background-color: $white;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-image: url(../svg/three_dots.svg);
                            background-size: 18%;
                            opacity: 1;
                        }
                        
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}