.wrapper_content_sat.page_text,
.misc_cookiesinfo,
.footer_sitemap {
    .content_sat,
    .page_satellite {
        max-width: 1280px;
        margin: 0 auto 70px;
        width: 98%;

        .title,
        .satellite_title {
            margin-top: 75px;
            margin-bottom: 40px;
            font-size: 80px;
            font-weight: $bold;
            line-height: 1;
        }

        .row,
        .sat_article {
            padding-bottom: 30px;
        }

        .title_content,
        .big_title_charte {
            font-size: 20px;
            font-weight: $bold;
            margin-bottom: 15px;
        }

        .content_inside {
            line-height: 1.2;

            li {
                list-style: disc;
                margin-left: 1.5rem;
                text-indent: 0.2rem;
            }
        }
    }
}

.footer_sitemap {
    .page_satellite {
        max-width: 1680px;
    }
}

.order_cgv {
    // Anchor set up when redirected to cgv articles to take into account the preheader
    .row .content {
        padding-top: $headerHeightBig;
        margin-top: calc(-1 * #{$headerHeightBig});
    }
}

body.footer_sitemap .satellite_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 15px;

    > .sitemap_section {
        width: 18%;
    }

    @media screen and (max-width: 1300px) {
        > .sitemap_section {
            width: 19%;
        }
    }
}

.footer_legalmentions {
    .content_sat {
        .content_inside {
            a {
                text-decoration: underline;
            }
        }
    }
}
