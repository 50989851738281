//lightbox
.lightbox.actif {
    display: block;
}

.lightbox {
    display: none;
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    min-width: 600px;
    padding: 30px;

    .close {
        position: absolute;
        top: 2rem;
        right: 1.5rem;
    }

    &#lightbox_achat_express {
        .close_pop {
            &:before,
            &:after {
                @media screen and (-ms-high-contrast: active),
                    (-ms-high-contrast: none) {
                    left: 0;
                    top: 0;
                }
            }
        }
    }

    .close_pop {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 18px;
        height: 18px;
        cursor: pointer;
        background-color: transparent;

        &:before,
        &:after {
            position: absolute;
            left: 8px;
            content: ' ';
            height: 20px;
            width: 1px;
            background-color: #333;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &#pdvBox {
        transition: height 0.2s ease-in-out;

        &.deployed {
            min-height: 500px;

            //Validation de demande de contact magasin envoyée
            &.thanks {
                min-height: 0;
                height: 140px;
                width: 350px;

                #content_pdvBox_new {
                    font-family: $primaryFont;
                    margin: 0 auto;
                    width: 86%;
                    text-align: center;
                    font-size: 13px;
                    color: black;
                    line-height: 1.4;

                    .helloCustomer {
                        line-height: 1;
                        color: black;
                        font-size: 18px;
                        font-weight: 500;
                    }

                    a {
                        color: black;
                        font-weight: 600;
                    }

                    .teamSign {
                        line-height: 1;
                        color: grey;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                    }
                }
            }
        }

        .storeloc_form .button.primary.loader {
            width: 200px;
        }

        .container_optin_offre {
            .choose {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }

        input[type='radio'] {
            & + label {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 2rem;

                &:before {
                    content: '';
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    border: 1px solid #777;
                    background-color: $primaryColor;
                    box-shadow: inset 0 0 0 10px white;
                    margin-right: 1rem;
                }
            }

            &:checked + label:before {
                box-shadow: inset 0 0 0 2px white;
            }
        }

        #contact_store {
            display: none;
        }
    }

    .header_lightbox {
        position: relative;

        h3.title {
            font-size: 20px;
            font-weight: 500;
            margin: 3rem 0 0;
            text-align: center;
        }

        .close {
            cursor: pointer;

            &.croix {
                position: absolute;
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

#appContainer .showForm #contact_store {
    display: block;
}

/*
    .wrapper_bottom_container {
        max-width: $wrapperSmall;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        @media screen and (max-width: $wrapperMedium) {
            padding: 25px 2rem;
        }
    }
*/
.lightbox_large {
    position: fixed;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;

    #exit_overlay_OVI {
        position: absolute;
        z-index: 25;
        top: auto;
        bottom: 0;
        right: 0;
        left: unset;
        width: 456px;
        height: 520px;
        box-sizing: border-box;
        padding: 0;
        min-width: unset;
        transform: unset;

        .close_pop {
            top: 40px;
            right: 50px;

            &:before,
            &:after {
                background-color: white;
                width: 2px;
            }
        }

        .pop_contents {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: 95px 52px 30px 49px;
            box-sizing: border-box;
        }

        .w-input-element:not([type='radio']):not([type='checkbox']),
        input[type='email'].w-input-element {
            padding: 1.2rem 1rem 0.4rem;
        }

        #conf_inscription_news {
            padding-top: 92px;
        }

        #img_lightbox {
            position: absolute;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            left: 0;
        }

        .blockpop {
            width: 100%;
        }

        .w-form .pop_cgv_listing {
            margin-bottom: 0 !important;
            margin-top: 65px;
            font-weight: $light;
            font-size: 14px;

            a {
                text-decoration: underline;
                text-underline-position: from-font;
            }

            > a {
                text-decoration: none;
            }
        }

        .newsletter,
        .title_popup_inscription_home {
            color: white;
            font-weight: $bold;
            font-size: 40px;
            line-height: 1;
        }

        .pop_txt_listing {
            color: white;
            margin: 10px 0 20px;
            font-size: 15.9px;
            font-weight: $regular;
            line-height: 1.2;
        }

        .w-form-line-container {
            display: flex;
            flex-direction: column;

            .w-form-line {
                margin-bottom: 12px;
            }

            .w-form-line:nth-child(1) {
                order: 1;

                .w-input-note.w-input-error {
                    position: absolute;
                    top: 7.2rem;
                    color: #ff9090;
                }

                .w-email-input span {
                    color: white;
                }

                .w-input-element {
                    color: white;
                    transition: none;
                    transition: background-color 5000s ease-in-out 0s;
                    background-color: transparent !important;
                    border-color: white;
                    -webkit-text-fill-color: white;

                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-internal-autofill-selected {
                        border-color: white !important;
                        color: white;
                        -webkit-text-fill-color: white;
                    }

                    &::selection {
                        background-color: rgba(255, 255, 255, 0.2);
                    }
                }
            }

            .w-form-line:nth-child(2) {
                order: 3;
            }

            .w-form-line:nth-child(3) {
                order: 2;
                max-width: unset;

                .w-submit-button {
                    border-radius: 0 !important;
                    @extend .light;
                }
            }
        }
    }
}

/* ZOOMBOX FP */
#zoomBox {
    width: 100%;
    height: 100%;
    padding: 40px;
    transform: none;
    top: 0;
    left: 0;
    overflow-y: auto;
    background-color: #fff;
    box-sizing: border-box;

    @media only screen and (max-width: 1360px) {
        overflow-y: auto;
    }

    .zoom_main_wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        padding: 0;
        margin: 0 auto;
        position: relative;
    }

    .close_pop {
        z-index: 5;
        padding: 0;

        span {
            display: none;
        }

        &::before,
        &::after {
            top: 0;
        }
    }

    .thumb_wrapper {
        max-width: 151px;
        height: 788px;
        max-height: 90vh;
        position: relative;
        padding-top: 90px;

        @media only screen and (max-width: 1370px) {
            max-height: 85vh;
        }

        .zoomThumbsPrev,
        .zoomThumbsNext {
            background-image: url(../svg/spritesheet.svg);
            background-size: 600%;
            background-position: 80% 40%;
            background-repeat: no-repeat;
            width: 1.5rem;
            height: 1.5rem;
            margin: auto;
            cursor: pointer;
            z-index: 5;
            bottom: 1.5rem;
            left: 0;
            right: 0;
            position: absolute;

            &.swiper-button-disabled {
                opacity: 0.35;
                cursor: default;
            }
        }

        .zoomThumbsPrev {
            transform: rotate(180deg);
            bottom: inherit;
            top: 3.375rem;
        }

        &.no_swiper {
            .zoomThumbsPrev,
            .zoomThumbsNext {
                opacity: 0.35;
                cursor: default;
            }
        }
    }

    .thumbs_vis {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 151px;
        height: 700px;
        max-height: 76vh;
        margin-right: 10px;
        position: relative;

        @media only screen and (max-width: 1370px) {
            max-height: 85vh;
            max-width: 110px;
        }

        #thumbs_scroller {
            height: 100%;
            align-items: center;
            justify-content: flex-end;
            max-height: 76vh;

            &.swiper-vertical {
                .swiper-wrapper {
                    flex-direction: column;

                    .swiper-slide {
                        text-align: center;
                        font-size: 18px;
                        background: #fff;
                        /* Center slide text vertically */
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }

        .swiper-container {
            height: 100%;

            .swiper-wrapper {
                .swiper-slide {
                    width: 120px;
                    height: 120px;
                    opacity: 0.9;
                    cursor: pointer;

                    @media only screen and (max-width: 1370px) {
                        width: 100px;
                        height: 100px;
                    }

                    &.swiper-slide-thumb-active {
                        opacity: 1;
                        cursor: default;
                    }

                    img {
                        width: 120px;
                        height: 120px;
                        display: block;

                        @media only screen and (max-width: 1370px) {
                            width: 100%;
                            height: 120px;
                        }
                    }
                }
            }
        }
    }

    #zoom_box_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1200px;
        width: 100%;
        height: auto;
        margin: 0;

        img {
            position: relative;
            height: auto;
            width: 100%;
            vertical-align: bottom;
            /*&:hover {
                cursor: url('../img/zoom_out.svg'), auto;
            }*/
        }

        .block_vid{
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 75%;
            margin: 0 auto;
            max-width: 900px;
            max-height: 900px;


            iframe{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                max-width: 900px;
                max-height: 900px;
            }
        }

        .swiper-lazy-preloader,
        .swiper-slide {
            background-color: #fff;
        }

        .swiper-button-prev, .zoomProdPrev {
            background: none;
            &.swiper-button-disabled{
                display: none;
            }
        }

        .swiper-button-next, .zoomProdNext {
            background: none;
            &.swiper-button-disabled{
                display: none;
            }
        }
    }
}

#abox {
    padding: 90px;
    min-width: inherit;
    text-align: center;

    .light_title {
        font-size: 40px;
        font-weight: $bold;
    }

    p {
        line-height: 18px;
    }

    .btn_container {
        margin-top: 25px;

        button {
            margin-left: auto;
            margin-right: auto;
            height: 50px;
            width: 270px;
            font-size: 15px;
        }
    }
}

/********** Cookies **********/
#cookiesLightbox {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    font-family: unset;
    left: 50%;
    transform: translateX(-50%);

    section {
        width: 60%;
        max-width: unset;
        margin: unset;
        margin-left: 6.25%;
        font-size: 16px;
        font-weight: $regular;
        line-height: 1.2;

        a,
        .toggleCookiePrefs {
            font-weight: $light;

            &::after {
                content: none;
            }
        }

        span.cookie_title {
            display: block;
            font-weight: $bold;
            font-size: 30px;
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    ul li {
        cursor: default;

        &#cookiePref01 {
            display: none;
        }
    }

    #texte_question_2 {
        p {
            display: none;
        }

        ul p {
            display: block;
        }

        span {
            text-decoration: underline;
        }
    }

    .w-checkbox-input {
        width: fit-content;
    }

    .w-form-line.w-submit.form_submit {
        max-width: unset;
        position: absolute;
        top: 30%;
        right: 6.25%;
        width: 22%;
    }

    .w-submit-button {
        height: 50px;
    }

    .w-btn-wrapper:nth-child(2) {
        margin-left: 10px !important;
    }

    #js_cookie_refuse {
        @extend .light;
    }

    #js_cookie_accept {
        @extend .dark;
    }
}

/* FP Contact Store */
#pdvBox {
    width: 800px;

    .bgSprite.locator_full:before {
        display: none;
    }

    .nom_store {
        .dist {
            position: relative;
            left: unset;
        }
    }

    .mention_collect {
        display: none;
    }

    .horaire_today {
        margin-top: 10px;
        line-height: 1;
        font-size: 15px;

        .store_open_txt {
            font-size: 16px;
        }
    }

    p.addfavorite {
        position: relative;
        bottom: unset;
        left: unset;
    }

    @media screen and (max-width: 900px) {
        max-width: 800px;
    }
}

#content_pdvBox,
#content_pdvBox_new {
    width: 700px;
    margin: 0 auto;
    padding: 2em 0;

    .wrap_fields {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ou {
        display: none;
    }

    select,
    input[type='text'] {
        height: 48px;
        box-sizing: border-box;
    }

    select,
    input[type='text'] {
        border: 1px solid #c5c5c5;
    }

    select {
        width: 250px;
        background: #fff url(../img/select_arrow.png) no-repeat 90% center;
        background-size: auto 8px;
        padding: 0 1em;
    }

    input[type='text'] {
        width: 440px;
        padding: 0 48px 0 1em;
    }

    button.loc_btn {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        font-size: 0;
        width: 20px;
        height: 20px;
        background: #fff;
        border: 0;
        @include bgIconPath('../svg/spritesheet.svg');
        @include bgIconCoord(18, 6, 6);

        span {
            display: none;
        }
    }

    .bgSprite.aft:after {
        display: none;
    }

    .liste_distributeur_wrapper {
        max-height: 338px;
        margin-top: 2em;
    }

    .popup_title {
        text-align: center;
        font-size: 40px;
        font-weight: $bold;
        line-height: 1;
        margin-bottom: 1em;
    }

    .resultat_recherche,
    .mag_tel,
    #message {
        display: none;
    }

    .elem_liste_distributeur {
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-bottom: 1px solid #e6e8ea;
        padding: 0 0 0 1em;
        margin-bottom: 10px;

        .content_store {
            flex-direction: column;
            flex: initial;
            padding: 0;
            margin-bottom: 10px;
        }

        .content_store,
        .info_store {
            max-width: 420px;
        }
    }

    .dist {
        font-size: 13px;
        font-weight: 400;
        color: #777;
        margin-left: 0.5rem;
    }

    p.addfavorite {
        margin-top: 0.5rem;
        text-decoration: underline;
    }

    .button.contactMag {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-flex;
        align-items: center;
        width: initial;
        padding: 0 0.6em;
    }

    .w-contact-form {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .prenom_nom {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
        }

        .w-text-input {
            width: calc(50% - 0.5rem);

            &#numtel {
                line-height: 1;
                margin-bottom: 2%;

                .errormsgadd {
                    color: $errormsgadd;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }

        .w-textarea {
            width: 100%;
        }

        .w-input-element:not([type='radio']):not([type='checkbox']),
        input[type='email'].w-input-element {
            width: 100%;
            height: 48px;
            padding: 0 1em;
            box-sizing: border-box;
            border: 1px solid #c5c5c5;
            margin-bottom: 1rem;
        }

        input[type='tel'] {
            width: 100% !important;
        }

        .w-input.w-textarea .w-input-element {
            padding: 1em !important;
            height: 130px !important;
            font-size: 14px;
            line-height: 1.5;
        }

        .btn_container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 1em 0;

            .button.dark {
                display: flex;
                justify-content: center;
                align-items: center;
                width: initial;
                height: 48px;
                padding: 0 6em;
                margin: 0 auto;
            }
        }
    }

    .container_optin_offre {
        font-size: 14px;
    }

    .checkbox-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.6rem;

        p {
            width: 80px;
        }

        input[type='radio'] {
            display: none;
        }
    }

    .bar_top,
    .mentions {
        width: 100%;
    }

    .mentions {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 11px;

        a {
            text-decoration: underline;
        }

        .text.champ_obli {
            order: 2;
            color: #777;
            margin-top: 1rem;
        }
    }
}

/* Lightbox Produit Offert */
#selection-offer {
    width: 655px;
    padding: 50px 60px;
    height: inherit;

    .close_pop {
        span {
            display: none;
        }
    }

    .selection-offer-title {
        margin: 0 auto 0 !important;
    }

    .selection-offer-content {
        padding: 0 1rem;
        width: 100%;
        margin: 40px auto 0;

        #wrap_lion {
            width: 100%;

            .js-form-selection-offer {
                min-height: inherit;
                width: 300px;
                opacity: 1;
                margin: 0;
                position: relative;
                padding-top: 112px;

                &:before {
                    top: 50%;
                    left: -10px;
                    transform: translateY(-50%);
                }

                > div:not(.color_list) {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    left: -10px;
                    width: calc(100% + 10px);
                    height: 100%;

                    img {
                        margin-top: 15px;
                        width: 92px;
                        height: 92px;
                    }
                }

                .titleOfferedProd {
                    font-size: 16px;
                    font-weight: $bold;

                    span {
                        font-weight: $regular;
                    }
                }

                .noSlides {
                    .prevColour,
                    .nextColour {
                        display: none;
                    }
                }

                .swiper-slide {
                    width: 24%;
                }
            }
        }
    }
}

#ligthbox_send_store_information {
    .header_lightbox h3.title {
        margin: 1rem 0;
    }

    .w-sendshop-form p {
        margin-bottom: 2rem;
    }
}

#nrc_comment {
    .nrc_content_comment {
        .nrc_rate {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 12px 0.2em 12px 0;
            font-size: 1.5em;
        }
    }
}