#search_page {
    max-width: $wrapperMedium;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 25px;

    @media screen and (max-width: $wrapperMedium) {
        padding: 0 2rem;
    }


    .filters_container {
        .holder_submits {
            display: flex;

            .form_submit {
                &.button.light {
                    .cta {
                        height: 100%;
                    }
                }
            }

            >.btn_container {
                width: 50%;
                &:not(:last-child) {
                    margin-right: .5rem;
                }
                &:last-child {
                    margin-left: .5rem;
                }
            }
        }
    }

    // Home module when search is empty
    .catPush {
        h2 {
            margin: 90px 0 35px;
            font-size: 30px;
            font-weight: $bold;
            text-align: center;
        }
    }
    // 
}

.search_recap {

    &.no_result {
        display: flex;
        align-items: center;

        .search_details_wrapper {
            padding-left: 50px;
        }

        .title_no_result {
            font-size: 30px;
            font-weight: $bold;
        }

        .search_tips_section {
            font-size: 16px;
            font-weight: $regular;
        }

        .tip {
            list-style-type: disc;
            list-style-position: inside;
        }
    }

    .holder_recap {
        box-sizing: border-box;
        background-color: $bgGrey;
        max-width: 553px;
        min-width: 33%;
        min-height: 270px;
        padding: 56px 64px;
    }
    
    .search_term {
        font-size: 2.6vw;
        font-weight: $bold;
        display: flex;
        flex-direction: column;
        line-height: 1;
    }

    .recap_term {
        text-transform: capitalize;

        &::before,
        &::after {
            font-family: $primaryFont;
            display: inline;
            line-height: inherit;
        }
        &::before {
            content: "“";
            margin-right: .5rem;
        }
        &::after {
            content: "”";
            margin-left: .5rem;
        }
    }


    &:not(.no_result) {
        .nbr_result {
            &::before,
            &::after {
                display: block;
                line-height: inherit;
            }
            &::before {
                content: "(";
            }
            
            &::after {
                content: ")";
            }
        }
    }
    
    .nbr_result {
        font-size: 2.35vw;
        font-weight: $regular;
        display: flex;
        align-items: center;
        line-height: 40px;
        margin-top: 15px;
    }
}

.search_results_wrapper {
    margin-top: 60px;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 88vw;
    min-height: 200px;

    &.suggestionsLoading,
    &.search_active {
        display: block;
    }

    &.suggestionsLoading {
        &::after {
            background-color: $white;
        }        
    }

    .close_suggestions {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 0;
        display: block;
        width: 18px;
        height: 18px;
        z-index: 3;

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }

    .search_shad {
        cursor: pointer;
        display: block;
        position: absolute;
        width: $wrapperLarge;
        height: 100vh;
        top: 0;
        left: -120px;
        display: block;
        background-color: rgba(0,0,0,.3);
        z-index: 1;
    }
    
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: $wrapperLarge;
        height: 100%;
        top: 0;
        left: -120px;
        display: block;
        background-color: $bgGrey;
        z-index: 2;
    }

    #search_results {
        position: relative;
        z-index: 3;

        max-width: 80%;

        >li {
            padding: 10px 0;
            max-width: 80%;
        }
    }

    .term_label {
        strong {
            font-weight: $bold;
        }
    }
}

.fullpage-loader {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, .5) url(../svg/search-loader.svg) no-repeat center;

    span {
        font-size: 0;
    }
}