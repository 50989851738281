.marque {
    padding-top: 43px;

    .blocs {
        margin-bottom: 54px;

        .bloc_banner {
            margin-bottom: 24px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .bloc_text {
            .text {
                color: $black;
                font-size: 16px;
                font-family: $primaryFont;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 20px;

                a {
                    text-decoration: underline;
                }
            }

            &.text_01 {
                text-align: center;
            }

            &.center {
                text-align: center;
            }

            &.left {
                text-align: left;
            }

            &.right {
                text-align: right;
            }
        }

        .title {
            text-transform: uppercase;
            color: $black;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            font-size: 50px;
            font-family: $primaryFont;
            margin-bottom: 18px;

            &.center {
                text-align: center;
            }
        }

        .bloc_cta {
            display: flex;

            .cta {
                width: auto;
                display: inline-block;
                height: 48px;
                padding: 0 25.5px;
                box-sizing: border-box;
                min-width: 200px;
                text-align: center;
                background-color: $black;
                border: 1px solid $black;
                color: $white;
                font-size: 15px;
                line-height: 48px;
                font-weight: 400;
                font-family: $primaryFont;
                text-transform: uppercase;
                transition: all ease-in-out 0.5s;

                &:hover {
                    background-color: $white;
                    color: $black;
                }
            }

            &.center {
                justify-content: center;
            }

            &.left {
                justify-content: flex-start;
            }

            &.right {
                justify-content: flex-end;
            }
        }

        /* Tete de gondole 5 slide */
        .tete_de_gondole_par_5 {
            margin-bottom: 2.75rem;

            .swiper-slide {
                width: 19.5238%;
                margin-right: 0.595%;

                &.wrapperProdVertical {
                    padding: 0;
                }
            }

            & + .tete_de_gondole_nav {
                width: calc(100% - 4rem);
                max-width: 1280px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                position: relative;

                .swiper-pagination {
                    height: 3px;
                    width: 88.8%;
                    max-width: calc(100% - 90px);
                    position: relative;

                    .swiper-pagination-progressbar-fill {
                        background-color: rgba($black, 0.5);
                    }

                    &.swiper-pagination-lock {
                        & + .swiper_wrapper_buttons {
                            display: none !important;
                        }
                    }
                }

                .swiper_wrapper_buttons {
                    width: 85px;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    top: -4px;

                    .tete_de_gondole-button-prev,
                    .tete_de_gondole-button-next {
                        cursor: pointer;
                        width: 17px;
                        height: 10px;

                        &:before {
                            width: 17px;
                        }
                    }
                }
            }
        }

        .grid_swiper_wrapper {
            .swiper-wrapper {
                flex-wrap: wrap;
            }

            .swiper-slide {
                width: calc(50% - 30px);

                /* Center slide text vertically */
                display: flex;
                justify-content: center;
                align-items: center;

                &.wrapperProdVertical {
                    padding: 0;
                    margin-bottom: 30px;

                    &:nth-child(odd) {
                        margin-right: 30px;
                    }

                    &:nth-last-child(-n+2) {
                        margin-bottom: 0;
                    }

                    .rolloverimgContainer {
                        a.block_lnk {
                         padding: 0;

                            .itm_vis {
                                &:first-of-type {
                                    position: relative;
                                }
                            }
                        }
                    }

                    .imgWrapper {
                        & + a.block_lnk {
                            display: block;
                            width: 100%;
                        }
                    }

                    .wrapper_description{
                        padding: 0 5px;
                        width: 100%;
                        box-sizing: border-box;
                        margin-top: 0.65rem;

                        .item_name {
                            text-align: left;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: calc(100% - 100px);
                            display: block;

                            .item_subtitle {
                                text-align: left;
                                display: block;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }

        .tete_de_gondole {
            width: calc(100% - 4rem);
            max-width: 1680px;
            margin: 0 auto 19px;

            .wrapperProdVertical {
                .item_name {
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: calc(100% - 100px);
                    text-align: left;
                }

                .wrapper_price {
                    position: relative;
                    top: -5px;

                    .pricetag {
                        line-height: 1;
                    }
                }
            }
        }

        .tete_de_gondole_no_nav {
            .swiper-slide {
                &.wrapperProdVertical {
                    padding: 0;
                }
            }
        }

        &.image_text {
            .blocs_wrapper {
                display: flex;
                justify-content: flex-start;
                align-content: center;
                width: calc(100% - 4rem);
                max-width: 1134px;
                margin: auto;

                .bloc_image {
                    width: 46.125%;
                    margin-right: 9%;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .bloc_text {
                    width: 43.862%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: center;
                }

                @media only screen and (max-width: 1160px) {
                    .bloc_image {
                        width: 49.125%;
                        margin-right: 5%;
                    }

                    .bloc_text {
                        width: 46.862%;
                    }
                }
            }

            &.left {
                text-align: left;
            }

            &.center {
                text-align: center;
            }

            &.right {
                text-align: right;
            }
        }

        &.image_title_tdg_grid {
            .blocs_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: calc(100% - 4rem);
                max-width: 1348px;
                margin: 0 auto 25px;

                .bloc_image {
                    width: 50.4451%;
                    margin-right: 4.673%;
                    position: sticky;
                    top: 120px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .bloc_text {
                    width: 44.88%;

                    .text {
                        width: 87.6%;

                        @media only screen and (max-width: 1160px) {
                             width: 92.6%;
                        }
                    }

                    &.text_02 {
                        .grid_swiper_wrapper {
                            width: 87.6%;
                            margin: 0;
                        }

                        .title {
                            margin-bottom: 7px;
                        }
                    }
                }
            }
        }

        &.images_2_ctas_2 {
            .blocs_wrapper {
                display: flex;
                justify-content: space-between;
                width: calc(100% - 4rem);
                max-width: 1280px;
                margin: 0 auto;

                .blocs_wrapper_left,
                .blocs_wrapper_right {
                    width: 49.21875%;

                    .bloc_image {
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .sub_title {
                        font-family: $primaryFont;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: center;
                        margin-bottom: 3px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        &.images_2_cta_1 {
            width: calc(100% - 4rem);
            max-width: 1280px;
            margin: 0 auto 55px;

            &.center {
                text-align: center;
            }

            .blocs_texts {
                width: 100%;
                max-width: 645px;
                margin: 0 auto 30px;
            }

            .bloc_images {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                a {
                    display: flex;
                }

                .blocs_wrapper_left,
                .blocs_wrapper_right {
                    width: 49.21875%;

                    .bloc_image {
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }

        &.title_text_3_images {
            width: calc(100% - 4rem);
            max-width: 1090px;
            margin: 0 auto 70px;

            .bloc_text {
                .text {
                    margin-bottom: 0;
                    margin-top: 15px;
                }
            }

            .blocs_images {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;

                .bloc_image {
                    width: 32.11%;

                    a {
                        display: flex;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .swiper-container {
            &.swiper-initialized {
                & + .tete_de_gondole_nav {
                    display: flex !important;
                }
            }
        }
    }

    /* Page mon 1er chez moi */
    &.mon_1er_chez_moi {
        .blocs {
            margin-bottom: 55px;

            &.bloc_01 {
                margin-bottom: 55px;
            }

            &.bloc_02 {
                text-align: center;
            }

            &.bloc_04 {
                margin-bottom: 38px;

                .text_04 {
                    .title {
                        margin-bottom: 0;
                    }
                }
            }

            &.bloc_08 {
                .blocs_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: calc(100% - 4rem);
                    max-width: 1134px;
                    margin: auto;

                    .bloc_text {
                        width: 43.827%;

                        .title {
                            margin-bottom: 8px;
                        }

                        .text {
                            width: 85.11%;
                            margin-left: 14.89%;
                        }
                    }

                    .bloc_image {
                        width: 46.1199%;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }

            &.bloc_10 {
                width: calc(100% - 4rem);
                max-width: 1460px;
                margin: 0 auto 70px;

                .blocs_images {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin-top: 25px;

                    .bloc_image {
                        width: 23.9726%;
                        margin-right: 1.369%;

                        img {
                            display: block;
                            margin-bottom: 25px;
                            width: 100%;
                            height: auto;
                        }

                        &:nth-child(4) {
                            margin-right: 0;
                        }
                    }
                }

                .title {
                    margin-bottom: 0;
                }

                .bloc_cta {
                    .cta {
                        min-width: 133px;
                    }
                }
            }
        }
    }

    &.mixnmodule {
        .blocs {
            &.bloc_01 {
                margin-bottom: 20px;

                .bloc_text {
                    .text {
                        margin-bottom: 0;
                    }
                }
            }
            
            &.bloc_02 {
                margin-bottom: 70px;
            }

            &.bloc_03 {
                .bloc_image {
                    width: calc(100% - 4rem);
                    max-width: 1440px;
                    margin: auto;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    &.video {
                        border: 0;
                        overflow: hidden;
                        padding-bottom: 35.25%;
                        position: relative;
                        height: 0;

                        iframe {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 100%;
                            border: 0;
                            z-index: -2;
                        }
                    }
                }
            }

            &.bloc_04  {
                .blocs_wrapper {
                    .bloc_text {
                        .title {
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            &.bloc_05  {
                width: calc(100% - 4rem);
                max-width: 1680px;
                margin: 0 auto 70px;

                .bloc_text {
                    .step_equipement {
                        display: flex;
                        justify-content: space-around;
                        align-items: flex-end;
                        margin-bottom: 20px;

                        .step {
                            width: 17.857%;
                            font-size: 20px;
                            font-weight: 700;
                            text-transform: uppercase;
                            color: $black;
                            height: 60px;
                            border-radius: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: $bgGrey;
                            padding: 10px;
                            box-sizing: border-box;
                            text-align: center;

                            @media only screen and (max-width: 1360px) {
                                font-size: 15px;
                            }

                            @media only screen and (max-width: 1210px) {
                                font-size: 13px;
                            }

                            span:not(.empty) {
                                background-color: $white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 30px;
                                text-align: center;
                                width: 45px;
                                height: 45px;
                                border-radius: 100%;
                                line-height: 45px;
                                margin-right: 8px;

                                @media only screen and (max-width: 1360px) {
                                    font-size: 22px;
                                    width: 35px;
                                    height: 35px;
                                    line-height: 35px;
                                    margin-right: 4px;
                                }
                            }
                        }
                    }
                }

                .tete_de_gondole {
                    width: 100%;
                }
            }

            &.bloc_06  {
                .title {
                    margin-bottom: 0;
                }

                .text {
                    line-height: 1.25rem;
                    margin-top: 15px;
                }
            }

            &.bloc_07 {
                .blocs_wrapper {
                    .bloc_text {
                        .text {
                            width: 90%;
                            margin: 0 auto 30px;
                        }
                    }
                }
            }

            &.bloc_08 {
                .bloc_text {
                    width: 100%;
                    max-width: 620px;
                    margin: 0 auto 28px;
                }

                .title {
                    margin-bottom: 0;
                }
            }
        }
    }
}