/* COMMON */
#site_head_wrap {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: $white;

    +#site_global_wrap {
        .ancre_type:target {
            padding-top: $headerHeightBig;
            margin-top: calc(-1 * #{$headerHeightBig});
            height: $headerHeightBig;
            box-sizing: border-box;
        }
    }   
    
    &.sticky {
        +#site_global_wrap {
            .ancre_type:target {
                padding-top: $fixed_headerHeightBig;
                margin-top: calc(-1 * #{$fixed_headerHeightBig});
                height: $fixed_headerHeightBig;
                box-sizing: border-box;
            }
        }
    }
}

#site_global_wrap {
    margin: $headerHeightBig auto 0;
}

/* Underlineable elements */
.underlineable {
    position: relative;

    &::after {
        content: '';
        height: 1px;
        width: 0;
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        background-color: var(--txtcolor, $primaryColor);
        transition: $all_transition;
    }

    &:hover {
        &::after {
            width: 100%;
            left: 0;
            right: 0;
            
        }
    }
}

.underlineable_with_container {
    display: block;

    .to_underline {
        position: relative;

        &::after {
            content: '';
            height: 1px;
            width: 0;
            position: absolute;
            bottom: 0;
            left: 50%;
            right: 50%;
            background-color: var(--txtcolor, $primaryColor);
            transition: $all_transition;
        }
    }

    /* .current class is used on menu items */
    &.current,
    &:hover {
        .to_underline {
            &::after {
                width: 100%;
                left: 0;
                right: 0;
            }
        }
    }
}

/* Rollovers logic */
.block_top_item.with_rollover {
    &.is_logged.wishlist,
    &.hasItem.wishlist,
    &.is_logged.user,
    &.my_basket {
        &:hover {
            .rollover_bloc {
                transform: translateX(0);
            }
        }
    }
}

.block_top_item.with_rollover {
    &.active_rollover {
        .rollover_bloc {
            transform: translateX(0);
        }
    }

    /* Let the element underlined on active_rollover class */
    &.active_rollover {
        .to_underline {
            &::after {
                width: 100%;
                left: 0;
                right: 0;
            }
        }
    }
}

.rollover_bloc {
    background: $bgGrey;
    color: $primaryColor;
    width: 25vw;
    min-height: 504px;
    padding: 40px 0 40px 28px;
    transition: all 0.3s ease-in-out;
    z-index: 9;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 100%;
    transform: translateX(300%);

    &::after {
        position: absolute;
        content: '';
        width: 500%;
        height: 100%;
        background-color: $bgGrey;
        top: 0;
        left: 100%;
    }

    @media screen and (max-width: $wrapperMedium) {
        width: 30vw !important;
    }

    @media screen and (max-width: 1281px) {
        width: 35vw !important;
    }

    @media screen and (max-width: 1024px) {
        width: 45vw !important;
    }

    /* rollover_bloc btn special bgcolor */
    .button.light {
        background-color: $bgGrey;

        span {
            background-color: $bgGrey;
        }
    }

    /* WISHLIST */
    &#show_top_wish {
        display: flex;
        flex-direction: column;

        .products_listing {
            flex-grow: 1;
        }

        .cart_buttons > .see_wishlist {
            margin-bottom: 14px;
        }
    }

    &#show_top_wish,
    &#show_top_cart {
        height: calc(100vh - #{$headerHeightBig});
        display: flex;
        flex-direction: column;
    }

    /* Title */
    .wrap_title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 25px;
    }

    .title_panier {
        font-size: 40px;
        font-weight: $bold;
        line-height: 1;
        padding-bottom: 10px;
    }

    /* Nb products in wish/basket */
    .nb_products {
        display: block;
        font-size: 16px;
        font-weight: $regular;
    }

    /* Cart Total */
    .wrap_tot_panier {
        padding-top: 20px;
        border-top: 1px solid $primaryColor;
    }

    .cart_panier_total {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 20px;

        .titre_tot {
            font-size: 18px;
            font-weight: $bold;
        }
    }
}

/* Rollover products */
.products_listing {
    max-height: 80%;
    flex-grow: 1;

    .os-padding {
        box-shadow: inset 0px -15px 21px -30px $black;

        .holder_scrollable {
            padding-right: 0.75rem;
        }
    }

    .holder_scrollable > .product-link {
        position: relative;
        display: flex;
        padding-bottom: 30px;

        &:not(:last-child) {
            border-bottom: 1px solid $primaryColor;
            margin-bottom: 30px;
        }

        .cart_link {
            display: block;

            /* ULTRA TEMPORARY in order to have 93px images */
            .cart_thumb_holder {
                position: relative;
                width: 93px;
                height: 93px;
                overflow: hidden;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        /* Aall the box */
        .cart_detail_box {
            position: relative;
            display: flex;
            align-items: center;
            padding: 10px 0 0 10px;
            padding-right: 1rem;
            max-width: calc(100% - 93px);
            width: 100%;
            box-sizing: border-box;
            flex-grow: 1;

            &.with_crossed_price {
                .cart_product {
                    max-width: 50%;

                    @media screen and (max-width: $wrapperMedium) {
                        max-width: 40%;
                    }
                }

                .wrapper_price {
                    padding-left: 0.5rem;
                }
            }
        }

        /* Info part */
        .cart_product {
            max-width: 60%;
            width: 100%;

            .title_holder {
                display: flex;
                flex-direction: column;
                line-height: 1.2;
                margin-bottom: 0.75rem;
            }

            .title {
                font-size: 16px;
                font-weight: $bold;
            }

            .subtitle {
                line-height: 1.2;
                font-weight: $regular;
            }

            .title,
            .subtitle {
                white-space: nowrap;
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        /* COULEURS / TAILLE / QUANTITE */
        .rollover_line {
            max-width: 100%;
            display: flex;
            justify-content: flex-start;
            font-size: 13px;

            .label {
                font-weight: bold;
                min-width: 70px;

                & + span {
                    padding-left: 0.2rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .sub_couleur {
            display: none;
        }

        /* Price */
        .wrapper_price {
            margin-top: -1rem;
        }

        .product_ctas_container {
            .remove_prod,
            .remove_top_wishlist {
                position: absolute;
                top: 0;
                right: 0;
                height: auto;
            }

            .modify {
                display: none;
            }

            .prod_wishToCart,
            .addToWishlistButton {
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 0;
                width: 35px;
                height: 35px;
                background-color: $backgroundWhite;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    @include bgIconPath('../svg/spritesheet.svg');
                }

                &.existToWishlistButton {
                    &::after {
                        content: '';
                        display: block;
                        width: 24px;
                        height: 24px;
                        @include bgIconPath('../svg/spritesheet.svg');
                        background-size: 600% auto;
                        background-position: 40% 60%;
                    }
                }
            }
            
            .addToWishlistButton {
                &::after {
                    @include bgIconCoord(3, 6, 6);
                }
            }

            .prod_wishToCart {
                &::after {
                    @include bgIconCoord(5, 6, 6);
                }
            }
        }
    }
}

/* PREHEADER */
.header_mention {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-color: $primaryColor;
    height: $preheader_height;
}

.pre_header_wrapper {
    max-width: $wrapperMedium;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
    color: var(--txtcolor, $primaryColor);

    /* ISO PSD */
    .wrap_preheadertxt {
        font-size: 15px;
        font-weight: $light;

        @media screen and (max-width: $wrapperMedium) {
            margin-right: 6rem;
        }
    }

    .promo_swiper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .preheader_txt {
            max-width: 234px;
            text-align: center;
        }
    }

    .preHeader_rightSide {
        position: relative;
        display: flex;
        align-items: flex-start;
        z-index: 2;
        padding-top: 13px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -5px;
            width: 508px;
            height: calc(#{$preheader_height} - 1px);
            backdrop-filter: blur(2px);
            z-index: -1;
        }

        @media screen and (max-width: $wrapperMedium) {
            padding-right: 2rem;
        }
    }

    .preHeader_item {
        font-size: 13px;
        font-weight: $light;
        text-transform: uppercase;
        line-height: 1;

        &:not(:last-of-type) {
            padding: 0 12px 0 0;
            margin-right: 12px;
            border-right: 1px solid var(--txtcolor, $primaryColor);
        }

        /* LANG SWITCH */
        &.switchLang {
            position: relative;
            padding-right: 18px;
            padding-bottom: 13px;
            cursor: pointer;

            /* Arrow */
            &::after {
                mask-image: url(../svg/spritesheet.svg);
                @include maskIconCoord(17, 6, 6);
                width: 20px;
                height: 20px;
                background-color: $white;
                position: absolute;
                top: -3px;
                right: -3px;
                transition: $all_transition;
            }

            /* Underline */
            &::before {
                height: 1px;
                width: 0;
                position: absolute;
                bottom: 9px;
                left: 50%;
                right: 50%;
                background-color: var(--txtcolor, $primaryColor);
                transition: $all_transition;
            }

            &:hover {
                &::after {
                    transform: rotate(180deg);
                }

                &::before {
                    width: 100%;
                    left: 0;
                    right: 0;
                }

                .switchLangBtn {
                    opacity: 1;
                    pointer-events: initial;
                }
            }
        }

        /* Other languages holder */
        .switchLangBtn {
            pointer-events: none;
            position: absolute;
            top: 100%;
            left: 0;
            opacity: 0;
            transition: $all_transition;
            background-color: rgb(224, 224, 224);
            min-width: 100%;

            /* Link to lang site */
            .lang_itm {
                padding: 4px 2px;
                font-weight: $bold;
                display: block;
                color: $primaryColor;

                &:not(:last-of-type) {
                    margin-bottom: 5px;
                }
            }
        }

        .link {
            padding-bottom: 4px;
        }
    }
}

/* HEADER */
/* Sticky Header */
#site_head_wrap {
    &.sticky {
        @media screen and (max-width: $wrapperMedium) {
            .header {
                padding: 25px 2rem 0;
            }
        }

        /* On sticky from 1120px and above */
        @media screen and (min-width: 1120px) {
            .header {
                height: $fixed_headerHeightSmall;
                padding-top: 23px;
            }

            .rollover_bloc {
                /* ABSOLUTE --> No top property to modify */
                &#show_top_wish,
                &#show_top_cart {
                    height: calc(100vh - #{$fixed_headerHeightBig});
                }
            }

            .header_leftSide {
                display: flex;
                align-items: center;
                /* Do not remove these properties */
                max-width: 668px;
                width: 39.8%;
            }

            .header_main_wrapper {
                padding-top: 0;
            }

            @media screen and (max-width: 1400px) {
                .header_leftSide {
                    width: 32.8%;
                }

                .header_main_wrapper {
                    & > .main_menu_itm {
                        font-size: 13px;
                        padding: 12px 10px 0;
                    }
                }
            }

            .header_middle {
                .header_title {
                    display: none;
                }

                .header_title2 {
                    display: none !important;
                }
            }

            .sticky_logo {
                position: initial;

                .sticky_header_logo {
                    transition: all 0.3s ease;
                    transform: scale(1);
                    width: 94px;
                    height: 46px;
                }
            }

            /* Classic logo */
            .header_logo {
                transform: scale(0);
            }

            .header_rightSide,
            .wrap_search_field {
                position: relative;
                top: unset;
                left: unset;
                right: unset;
                height: 100%;
            }

            .wrap_search_field {
                width: auto;
            }

            /* Header left side */
            .header_leftSide {
                max-height: 34px;
            }

            .content_block_left.search {
                width: stretch;
                padding: 0 3.2vw 0 4.2vw;
            }

            .wrap_search_field {
                padding: 0;
            }

            #search_home {
                height: 34px;
                padding: 8px 20px 6px;
                width: 100%;
            }

            .main_menu_itm {
                padding: 12px 19px 0;

                .entry {
                    padding-bottom: 30px;
                    white-space: nowrap;
                }
            }

            .block_top_item {
                padding: 6px 19px 0;

                .icon .to_underline {
                    padding-bottom: 31px;
                }

                &.storeloc {
                    height: unset;

                    &:hover {
                        .to_underline::after {
                            width: 100%;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .wrap_search_field {
            width: 25vw;
        }
    }
}

/* Classic Header */
.header {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    max-width: $wrapperMedium;
    margin: 0 auto;
    position: relative;
    height: $headerHeightSmall;
    box-sizing: border-box;
    transition-duration: 0.3s;

    /* SEARCH */
    /* LEFT */
    .wrap_search_field {
        position: absolute;
        padding-right: 80px;
        left: 0;
        top: 25px;

        @media screen and (max-width: $wrapperMedium) {
            left: 2rem;
        }

        .wrap_field {
            position: relative;

            &.search_field_active {
                .close_search {
                    display: block;

                    &::before {
                        display: block;
                    }
                }
            }

            .close_search {
                display: none;
                cursor: pointer;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
        }

        .close_search,
        .wrap_field::before {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
        }

        .wrap_field::before {
            background-color: $white;
            z-index: 2;
        }

        .close_search::before {
            z-index: 1;
        }
    }

    #search_home {
        width: 430px;
        padding: 15px 20px;
        font-size: 14px;
        text-transform: uppercase;
        border-radius: 18px;
        border: 1px solid $primaryColor;
        appearance: 0;
        font-family: $primaryFont;
        font-style: italic;
        box-sizing: border-box;
        height: 48px;

        &::placeholder {
            font-weight: $medium;
            font-family: $primaryFont;
        }

        &::-webkit-input-placeholder,
        &::input-placeholder {
            font-weight: $medium;
            font-family: $primaryFont;
        }
    }

    /* MIDDLE */
    .header_middle {
        max-width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    /* Logo */
    .sticky_logo {
        position: absolute;

        .sticky_header_logo {
            transform: scale(0);
        }
    }

    .sticky_header_logo,
    .header_title {
        font-size: 0;
        display: block;
        width: 141px;
        height: 70px;
        background: url('../svg/logo_five.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &.logo_noel {
            background: url('../svg/logo_noel.svg');
            background-repeat: no-repeat;
        }
    }

    .header_title2 {
        font-size: 0;
        display: block;
        width: 141px;
        height: 70px;
        background: url('../svg/logo_noel.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .header_logo {
        position: relative;
        transition: all 0.3s ease;
        transform: scale(1);
        display: flex !important;
    }

    .header_middle {
        .header_logo2 {
            display: flex;
            position: relative;
            transition: all 0.3s ease;
            transform: scale(1);
        }
    }

    /* RIGHT */
    .header_rightSide {
        position: absolute;
        right: 0;
        top: 25px;
        margin: 0 -19px 0 auto;
        display: flex;
        height: calc(100% - 25px);

        @media screen and (max-width: $wrapperMedium) {
            right: 2rem;
        }

        .block_top_item {
            cursor: pointer;
            padding: 13px 16px 0;

            &.storeloc {
                font-size: 0;
            }

            &.storeloc,
            &.user:not(.is_logged),
            &.wishlist:not(.is_logged) {
                height: max-content;
            }

            &.user {
                &.is_logged {
                    .icon::after {
                        content: '';
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $green;
                        position: absolute;
                        top: 15px;
                        right: 2px;
                        transform: translateX(50%);
                        border: 2px solid $white;
                    }
                }

                > .top_item_cta {
                    position: relative;
                }

                .rollover_bloc {
                    cursor: initial;
                    min-height: unset;
                    padding: 40px 0 40px 40px;
                    width: 355px;

                    @media screen and (min-width: 2200px) {
                        min-width: 13vw;
                        width: auto;
                    }
                }

                .account_name {
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;
                    font-size: 40px;
                    line-height: 1;
                    font-weight: $bold;
                    margin-bottom: 2rem;

                    .name_label {
                        margin-right: 0.5rem;
                    }
                }

                .nicename {
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: $semibold;
                }

                .account_title {
                    text-transform: uppercase;
                    font-weight: $semibold;
                }

                .account_nav {
                    margin: 0 0 100px 7px;

                    > li {
                        margin-top: 15px;
                        line-height: 1;

                        > a {
                            display: inline-block;
                            padding-bottom: 3px;
                            position: relative;

                            &::after {
                                content: '';
                                height: 1px;
                                width: 0;
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                right: 50%;
                                background-color: var(
                                    --txtcolor,
                                    $primaryColor
                                );
                                transition: $all_transition;
                            }

                            &:hover {
                                &::after {
                                    width: 100%;
                                    left: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }

            &.with_rollover {
                .product_mention_number {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    transform: translateY(50%);
                    font-size: 0.75rem;
                }

                .hover_border {
                    height: 100%;

                    .to_underline {
                        height: max-content;
                    }
                }
            }

            /* Last icon don't need padding-right in */
            &.my_basket .icon {
                padding-right: 0;
            }

            .icon {
                .bfr::before {
                    width: 23px;
                    height: 23px;
                }

                .to_underline::after {
                    height: 3px;
                    bottom: 3px;
                }

                .to_underline {
                    padding-bottom: 16px;
                }
            }
        }
    }
}

#site_head_wrap.sticky.sticky {
    .header .header_middle .header_logo2 {
        transform: scale(0);
    }
}

/* MENU */
.header_main_wrapper {
    display: flex;
    padding-top: 13px;

    > .main_menu_itm {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: $semibold;
        line-height: 1;
        padding: 3px 19px 0;
        cursor: pointer;

        #menu_petits-prix,
        #menu_soldes {
            color: var(--txtcolor, $black);
        }

        &.itm_no_rollhover {
            .submenu_wrapper {
                display: none;
            }
        }
        &.hovered {
            .entry {
                color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                
                &::after {
                    background-color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                }
            }
        }
        .entry {
            display: block;
            padding-bottom: 12px;
            transition: $all_transition;

            &::after {
                height: 3px;
            }
            
            &:hover {
                color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                &::after {
                    background-color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                }
            }
        }

        /* current class is used upon categories page */
        &.current,
        &.hovered {
            .entry {
                color: var(--txtcolor, $black);
            }
        }

        &:hover {
            .submenu_wrapper {
                opacity: 1;
                pointer-events: initial;
                cursor: initial;
            }
        }

        // WP-23906 || apply color directly on header entry
        #menu_nouveautes,
        #menu_french-days,
        #menu_rentree,
        #menu_detox-ta-maison-x-5five,
        #menu_petit-prix {
            color: var(--txtcolor, $primaryColor);
        }

        #menu_french-days {
            @extend .itm_no_rollhover;
        }
    }

    .submenu_wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-height: 419px;
        box-sizing: border-box;
        z-index: 2;
        transition: all 0.3s ease-out;
        pointer-events: none;
        opacity: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        /* fake 1920px background */
        &::before {
            content: '';
            display: block;
            width: $wrapperLarge;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            z-index: -1;
            background-color: $bgGrey;
        }

        .menu_categ_wrapper {
            max-width: $wrapperMedium;
            width: 100%;
            margin: 0 auto;
            height: 100%;
            cursor: initial;
        }

        .ss_menu_categ_wrapper {
            display: flex;
            flex-wrap: wrap;
            padding: 50px 0 0;

            /* Add padding left to prevent border touching */
            @media screen and (max-width: $wrapperMedium) {
                padding: 50px 0 50px 32px;
            }

            /* Subcat list */
            .menu_ss_cat__list {
                padding: 0 80px 32px 0;
                text-align: left;

                .subCatWrapper > .subCat_item:not(:last-child) {
                    margin-bottom: 14px;
                }
            }

            /* Subcat title */
            .ss_cat_name {
                display: block;
                margin-bottom: 16px;
                span {
                    &:hover {
                        color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                    }
                }
            }

            /* Subcat items */
            .subCatWrapper {
                padding-left: 8px;
            }

            .subCat_item {
                text-transform: initial;
                font-weight: $regular;
                &:hover {
                    span {
                        color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                    }
                }

                &.see_all {
                    text-decoration: underline;
                    font-weight: $light;
                }
            }
        }

        .menu_img_push {
            img {
                vertical-align: bottom;
            }
        }
    }
}
