@-moz-document url-prefix() {
    .w-form-line * {
        box-sizing: border-box;
    }

    .wrapper_moncompte .w-form-line input[type='tel'] {
        width: inherit !important;
    }
}

.wrapper_content_wishlist,
.wrapper_moncompte {
    width: 98%;
    max-width: $wrapperMedium;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .left-container {
        margin-right: 53px;
        max-width: 490px;
        width: 30%;

        img {
            width: 100%;
        }
    }

    .right_container {
        max-width: 1137px;
        width: 68%;
        position: relative;
        margin-top: 6px;
        margin-bottom: 135px;

        .update_success {
            color: #000;
            font-size: 16px;
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-weight: 400;
        }

        .holder_account_intro {
            font-size: 40px;
            font-weight: $bold;
            color: $primaryColor;
            line-height: 1;
        }

        .account_nav {
            margin: 30px 0 35px;
            display: flex;
            border-bottom: 1px solid $primaryColor;
            white-space: nowrap;

            .item {
                font-size: 18px;
                margin-right: 45px;
                line-height: 1;

                >a {
                    display: block;
                    padding-bottom: 20px;
                }
            }

            .item.actif {
                font-weight: bold;
            }
        }

        .w-form-line {

            input[type='text'],
            input[type='email'],
            input[type='password'] {
                font-size: 14px !important;
            }

            .w-radio-input+.w-radio-input {
                margin-left: 35px;
            }

            .w-radio-input {
                .w-input-label {
                    span {
                        font-weight: normal;
                        color: $primaryColor;
                        font-size: 14px;
                        font-family: arial;
                    }
                }
            }

            .w-input-label {
                color: $primaryColor;

                span {
                    font-style: normal;
                    color: $secondaryColor;
                    font-size: 13px;
                }
            }

            .w-tel-input {
                .selected-flag {
                    border-top: 0;
                    border-bottom: 0;
                    border-left: 0;
                    background-color: white;
                    margin-left: 1px;
                }
            }
        }

        .form_submit,
        .w-submit-button {
            min-width: 270px;
            height: 50px;

            &:not(.w-loader) {
                font-size: 15px;
            }

            &.w-loader.loading {
                background-color: $white;

                span {
                    opacity: 0;
                }
            }
        }

        .store_pref {
            h3 {
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 20px;
                color: $primaryColor;
                font-weight: $bold;
            }

            .favorite_store {
                width: fit-content;
                border: 10px solid #be9045;
                position: relative;
                padding: 20px 80px 60px 20px;

                .addr_label {
                    font-size: 18px;
                    font-weight: $bold;
                }

                .infos {
                    margin-top: 10px;

                    p {
                        font-size: 16px;
                    }
                }

                .modlinks_wrapper {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;

                    a {
                        font-weight: $light;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .container_store_pref {
                display: flex;

                .bloc_store_pref {
                    background-color: white;
                    text-align: center;
                    width: 300px;
                    height: 160px;
                    border: 10px solid rgba(245, 245, 245, 0.5);
                    position: relative;

                    p {
                        line-height: 20px;
                        font-size: 18px;
                        color: $primaryColor;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 50%;
                    }
                }
            }

            .form_submit {
                margin-top: 30px;

                a {
                    width: 270px;
                    padding: 15px 1.85rem;
                    border: 1px solid #000;
                    color: #000;
                    font-size: 15px;
                    line-height: 50px;
                    border-radius: 0;
                    text-transform: uppercase;

                    span {}
                }
            }
        }
    }
}

#resetPass {
    .w-submit {
        display: flex;
        justify-content: space-between;
        max-width: inherit;
    }
}

/* Mes Infos */
.wrapper_content_mes_infos {
    .w-submit {
        margin-top: 35px;

        .w-submit-button {
            &>span {
                transition: none;

                &:hover {
                    background-color: $white;
                    color: $black;
                    transition: all .3s ease;
                }
            }
        }
    }
}

//Adresse Form
.container_adresses {
    .holder_content {
        border-bottom: 1px solid black;
        padding-bottom: 30px;

        h3 {
            font-size: 20px;
            color: $primaryColor;
            font-weight: $bold;
        }

        .wrapper_mes_adresses {
            .conteneur_adresse {
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 30px;
                padding-top: 25px;
                position: relative;

                >h3 {
                    position: absolute;
                    top: 0;
                }

                .adresse {
                    display: flex;
                    position: relative;

                    .addr_label {
                        font-weight: 800;
                        font-size: 0.85rem;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        margin-bottom: 0.5rem;

                        >p {
                            text-transform: capitalize;
                            white-space: nowrap;
                            height: 20px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-size: 16px;
                            font-weight: $bold;
                        }
                    }

                    .infos {
                        .addr_line {
                            line-height: 18px;
                            color: #374956;
                        }
                    }

                    .modlinks_wrapper {
                        position: absolute;
                        bottom: 15px;

                        .modlink {
                            font-weight: $light;
                            text-decoration: underline;
                        }

                        .modlink.delete {
                            display: none;
                        }

                        .modify {
                            margin-right: 2.5rem;
                        }

                        .delete {
                            color: #a53e45;
                        }
                    }
                }
            }

            .form_submit {
                margin-top: 3rem;

                a {
                    letter-spacing: 1px;
                }
            }
        }
    }
}

.wrapper_form_adresse {
    .choix_type_adresse {
        display: flex;
        flex-wrap: wrap;
        font-size: 0.95rem;
        margin-bottom: 2rem;

        .type_adresse.autre {
            width: 173px;
        }

        .type_adresse {
            width: 140px;
            height: 40px;
            color: $primaryColor;
            font-weight: 400;
            background-color: #f5f5f5;
            text-align: center;
            border-radius: 20px;
            margin-right: 20px;
            line-height: 40px;

            &.actif {
                background-color: $primaryColor;
                color: white;
                font-weight: 500;
            }

            p {
                cursor: pointer;
            }
        }

        .ami {
            display: none;
        }

        .autre {
            p {
                &::after {
                    content: none;
                }
            }
        }
    }

    .w-address-form {
        .addrCivility {
            .w-radio-input .w-input-element:checked+.w-input-label::before {
                border: 1px solid #e0e0e0;
                box-shadow: inset 0 0 0 0.125em #fff !important;
            }

            .w-radio-group {
                .w-radio-input:first-child {
                    margin-right: 1.5rem;
                }
            }
        }

        .addrAutoComplete {
            .w-group-label {
                font-family: inherit;
                font-size: 20px;
                font-weight: $bold;
            }
        }

        .addrSubmit {
            .w-loader {
                background: #ffffff url(../svg/three_dots.svg) center no-repeat;
                background-size: auto 25%;
            }
        }

        .img_error {
            position: absolute;
            top: 50%;
            right: 0.5rem;
            transform: translateY(-50%);
        }

        .valid_form {
            display: none;
        }

        .addrType {
            .w-checkbox-input {
                .w-input-element+.w-input-label::before {
                    border-radius: 0 !important;
                    box-shadow: inset 0 0 0 0.21rem white !important;
                    border: 1px solid #e0e0e0;
                }

                .w-input-label {
                    span {
                        font-size: 13px;
                        color: $primaryColor;
                    }
                }
            }
        }
    }

    .w-form-line.addrTitle {
        display: none;
    }
}

//Adresse Blocs
.container_adresses {
    .adresse:nth-child(5n + 2) {
        background-color: #be9045;
    }

    .adresse:nth-child(5n + 3) {
        background-color: #ba3a4f;
    }

    .adresse:nth-child(5n + 4) {
        background-color: #004151;
    }

    .adresse:nth-child(5n + 5) {
        background-color: #728574;
    }

    .adresse:nth-child(5n + 6) {
        background-color: grey;
    }

    .adresse {
        margin-top: 20px;
        margin-right: 30px;
        min-height: 120px;
        width: 260px;
        font-size: 16px;
        padding: 20px;

        >div {
            max-width: 100%;
        }

        p {
            color: white !important;
        }

        .addr_label {
            font-size: 18px;
            font-weight: $bold;
        }

        .full_name {
            margin-top: 10px;
        }

        .modlinks_wrapper {
            color: white !important;
            font-weight: $light;
            margin-top: 22px !important;
        }

        .infos {
            p {
                height: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: $extralight;
            }
        }
    }

    .wrapper_mes_adresses {
        .form_submit {
            margin-top: 0 !important;

            a {
                width: 270px !important;
                padding: 15px 1.85rem !important;
                border: 1px solid black !important;
                color: black !important;
                font-size: 15px !important;
                line-height: 50px;
                border-radius: 0 !important;
            }
        }
    }
}

/* Page Commandes liste */
.ordered_content {
    align-items: inherit;

    .right_container {
        margin-top: 25px;
    }
}

.wrapper_moncompte {
    .wrapper_mes_commandes {
        .intro {
            line-height: 1.4;
            margin-bottom: 35px;
        }

        .wrapper_menu_tab {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            .date_select {
                position: relative;

                select {
                    cursor: pointer;
                    appearance: none;
                    padding: 1rem 4.5rem 1rem 1rem;
                    border: 1px solid black;
                    text-transform: uppercase;
                    font-size: 15px;
                    line-height: 1;
                    width: 270px;
                    box-sizing: border-box;
                }

                &:after {
                    pointer-events: none;
                    position: absolute;
                    top: 19px;
                    right: 25px;
                    border-style: solid;
                    border-width: 0.1em 0.1em 0 0;
                    content: '';
                    display: inline-block;
                    height: 0.45em;
                    transform: rotate(135deg);
                    vertical-align: top;
                    width: 0.45em;
                }
            }

            #com_onglet {
                display: flex;
                justify-content: space-evenly;
                flex: 1;

                .onglet {
                    font-weight: $light;
                    font-size: 1rem;
                    line-height: 1;
                    text-decoration: underline;

                    .label_order {
                        text-transform: capitalize;
                    }
                }
            }
        }

        .table_mon_compte {
            margin-bottom: 45px;

            .pricetag .cents {
                margin-left: 0;
            }

            .header_command {
                border-bottom: 1px solid black;

                th:nth-child(1) {
                    padding-left: 10px;
                }

                th {
                    font-size: 16px;
                    line-height: 1;
                    font-weight: $bold;
                    letter-spacing: 1px;
                    padding: 0.8rem 0;
                }

                .label-bloc {
                    .l-item {
                        display: inline-block;
                    }
                }
            }

            .content {
                .nothing {
                    padding: 1rem;
                }

                .rollover {
                    border-bottom: 1px solid black;

                    &:last-of-type {
                        border-bottom: none;
                    }

                    td {
                        padding: 1.2rem 0 1.7rem;

                        &:first-of-type {
                            padding-left: 1rem;
                        }

                        &.link_detail {
                            padding-right: 1rem;
                        }

                        .transporteur {
                            .store_delivery_hidden_tittle {
                                position: relative;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    width: 10px;
                                    height: 5px;
                                    background-color: $white;
                                    top: 10px;
                                    left: -10px;
                                }
                            }
                        }
                    }

                    a {
                        border-bottom: 1px solid black;
                        font-weight: $light;
                    }
                }

                .prelast.facture {
                    display: flex;
                    flex-direction: column;

                    .btn.shipup_cta {
                        display: none;
                    }

                    a {
                        width: fit-content;
                        line-height: normal;
                        cursor: pointer;

                        .btn.fix.shipup_cta {
                            display: block;
                            padding: 0;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: normal;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

/* Page Commandes details */
.wrapper_moncompte {
    .wrapper_content_cmd_detail {
        .holder_content {
            justify-content: space-between;
            display: flex;

            .wrapper_left {
                max-width: 756px;
                width: 67%;

                .titre {
                    margin-top: 25px;

                    a {
                        text-decoration: underline;
                    }
                }
            }

            .wrapper_right {
                max-width: 350px;
                width: 31%;

                .wrapper_delivery_addr {
                    padding: 30px;
                    border: 1px solid black;
                    margin-top: 30px;

                    .addr_title {
                        font-size: 18px;
                        font-weight: $bold;
                        margin-bottom: 10px;
                    }

                    .addr_name,
                    .addr_address,
                    .addr_tel {
                        font-size: 16px;
                    }
                }
            }
        }

        .order_detail_head {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;

            .go_back {
                width: 100%;
                margin-bottom: 15px;

                a {
                    font-size: 16px;
                    font-weight: $light;
                    position: relative;
                    padding-left: 20px;

                    &:before {
                        content: '<';
                        font-size: 18px;
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        top: -3px;
                        bottom: 0;
                        margin: auto 0;
                    }
                }
            }

            .wrapper_infos_cmd {
                width: 40%;
                font-size: 0.875rem;
                line-height: 1.5;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .cmd_txt {
                    font-size: 1.125rem;
                    line-height: 1;
                    font-weight: 600;
                    margin-bottom: 0.9rem;
                }

                .cmd_cta {
                    text-transform: uppercase;
                    position: absolute;
                    right: 0;
                    top: 0;

                    @media screen and (max-width: 1024px) {
                        position: initial;
                        margin-top: 16px;
                    }
                }

                .cmd_number {
                    font-size: 15px;
                    font-weight: $light;
                    margin-bottom: 10px;

                    span {
                        font-size: 18px;
                        font-weight: $bold;
                    }
                }

                .cmd_date,
                .cmd_status,
                .num_suivi,
                .num_retour {
                    font-size: 16px;
                    font-weight: $medium;

                    span {
                        font-weight: $light;
                    }
                }

                @media screen and (max-width: 1450px) {
                    margin-bottom: 1.5rem;
                }
            }

            .num_suivi {
                a {
                    text-decoration: underline;
                    font-style: italic;
                }
            }

            .wrapper_delivery_addr {
                font-size: 0.875rem;
                line-height: 1.5;
            }

            .btn.shipup_cta {
                display: none;
            }

            .btn.fix.shipup_cta {
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                font-family: $primaryFont;
                font-size: clamp(12px, 1vw, 15px);
                text-align: center;
                text-indent: initial;
                padding: 15px 25px;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                background-color: #000;
                color: #fff;
                cursor: pointer;

                &:hover {
                    background-color: #fff;
                    color: #000;
                    border: 1px solid #000;
                }
            }

        }

        .cmd_detail_top {
            .wrap_total_commande {
                background-color: #f8f9f9;
                padding: 2rem;

                .titre {
                    font-size: 30px;
                    font-weight: $bold;
                    margin-bottom: 30px;
                }

                .wrapper_total {
                    &>div {
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                        margin-bottom: 1rem;
                        font-size: 16px;

                        .montants {
                            &.montant_total {
                                font-size: 18px;
                                font-weight: $bold;
                            }
                        }
                    }

                    .montant_total_wrap {
                        padding-top: 1.5rem;
                        margin-top: 1.5rem;
                        border-top: 1px solid black;

                        .montant_total {
                            font-size: 18px;
                            font-weight: $bold;
                        }
                    }
                }
            }
        }

        .wrapper_cmd_retour {
            margin-top: 30px;
            border: 1px solid black;
            padding: 20px 30px;

            @media screen and (max-width: 1024px) {
                padding: 20px 20px;
            }

            .cmd_retour {
                .intro {
                    font-size: 18px;
                    font-weight: $bold;
                    width: 270px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .content p,
                .refund_not_free,
                #createNumRetour .intro {
                    font-size: 16px;
                    line-height: 18px;
                }

                .down_bill,
                .see_remb {
                    margin-left: auto;
                    margin-right: auto;
                }

                .see_remb {
                    margin-top: 10px;
                }

                .btn_container {
                    margin-top: 15px;
                    margin-bottom: 10px;
                    width: 270px;
                    margin-left: auto;
                    margin-right: auto;
                }

                #createNumRetour {
                    .intro {
                        margin-bottom: 1rem;
                    }

                    .links_comment_retourner_cmd {
                        margin: 0 0 1rem;
                    }
                }

                .w-submit-button {
                    @media screen and (max-width: 1024px) {
                        .texte {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .cmd_faq {
            margin-top: 10px;

            p {
                font-size: 18px;
                font-weight: $bold;

                a {
                    font-size: 16px;
                    font-weight: $light;
                    text-decoration: underline;
                }
            }
        }
    }
}

/* Wishlist */
.wrapper_wishlist {
    .intro {
        margin: 0 0 2rem;

        p {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 13px;
            color: #777;

            &:before {
                content: '';
                display: block;
                width: 25px;
                height: 25px;
                @include bgIconPath('../svg/mobile_vector_spritesheet.svg');
                @include bgIconCoord(7, 6, 6);
                margin-right: 0.8rem;
            }
        }
    }

    .nrArticlesSelectedWrapper {
        display: flex;
        justify-content: space-between;

        >p {
            font-weight: $bold;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        #nrArticlesSelected {
            .txt {
                .wishlist_count_wording {
                    text-transform: lowercase;
                }
            }

            .bloc_price {
                font-size: 24px;
                font-weight: $bold;
            }
        }
    }

    .wishlist_container {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        .w-left-bloc {
            margin-top: 30px;

            .wish_liste_product {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .wrapper_details {
                    display: flex;
                    flex-direction: column;
                }

                .old-price {
                    order: 0;
                    font-size: 12px;
                    margin-bottom: 5px;
                }

                .color {
                    order: 1;
                }

                .shoesize {
                    order: 2;
                }
            }
        }

        .w-right-bloc {
            .button.primary {
                margin-top: 0;
            }

            .button.secondary.unscanForChanges {
                transition: none;
                font-size: 12px;
            }
        }
    }

    .product_ctn.checked {
        border-color: black;

        .product_check label {
            border: 1px solid $black;

            &::after {
                transform: scale(1);
            }
        }
    }

    .product_ctn {
        position: relative;
        width: 498px;
        height: 133px;
        max-width: 100%;
        border: 1px solid #e1e2e3;
        margin-bottom: 35px;
        padding: 20px;

        .product_check {
            position: absolute;
            left: -12px;
            top: 50%;
            bottom: 50%;
            margin: auto 0;
            height: 20px;

            .checkbox_select_product {
                display: none;
            }

            label {
                width: 20px;
                height: 20px;
                border: 1px solid #e6e8ea;
                display: block;
                background-color: #fff;
                position: relative;
                transition: border-color 0.3s ease-in-out;

                &:after {
                    content: '';
                    width: 80%;
                    height: 80%;
                    background-color: black;
                    display: block;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transform: scale(0);
                    transition: transform 0.3s ease-in-out;
                }
            }
        }

        label {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:before {
                background-color: white;
                margin: 0 0 0 -9px;
            }
        }

        .wrapper_product {
            display: flex;

            .photo_product {
                margin: 0 2rem 0 0;

                .wrapper_img {
                    position: relative;
                    width: 136px;
                    height: 136px;
                    overflow: hidden;

                    img {
                        width: 136px;
                        max-width: 100%;
                        height: auto;
                        vertical-align: top;
                    }

                    .report.error {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 3.5em;
                        background: rgba(255, 255, 255, 0.6);
                        font-size: 0.8rem;
                    }
                }
            }

            .product_desc {
                display: flex;
                flex-direction: column;
                width: 100%;

                // Product title in wishlist is directly written in uppercase so change it to have juste the first letter as uppercase
                .title {
                    font-size: 16px;
                    font-weight: $bold;

                    // First letter does not work on inline elements and span is an inline elem so change its display
                    >span {
                        display: inline-block;
                        text-transform: lowercase;
                        max-width: 60%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                }

                .subtitle {}

                .cta_add_to_basket {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    width: 35px;
                    height: 35px;
                    background-color: #f5f5f5;
                    border-radius: 8px;
                    cursor: pointer;

                    span {
                        margin-left: 5px;

                        &::before {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }

                .bottom_content_price {
                    p {
                        font-size: 14px;

                        span:nth-child(1) {
                            display: inline-block;
                            font-weight: $bold;
                            min-width: 70px;
                        }
                    }

                    .wrapper_details>p {
                        max-width: 75%;
                        display: flex;
                        justify-content: flex-start;

                        &.color {
                            >span:last-child {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }

                .wrapper_price {
                    padding-left: 0;

                    .bloc_price {
                        display: flex;
                        align-items: baseline;
                    }
                }

                .cta-wrapper {
                    display: flex;
                    margin-top: 2rem;
                }
            }
        }
    }

    .report.error {}

    .remove {
        width: 20px;
        height: 20px;
        display: block;
        background-color: transparent;
        position: absolute;
        top: 16px;
        right: 14px;

        span {
            font-size: 0px;
        }

        &:after,
        &:before {
            content: '';
            position: absolute;
            width: 13px;
            height: 1px;
            background-color: #706f6d;
            transform-origin: 50% 50%;
            top: 50%;
            left: 50%;
        }

        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    .share_wl .mail_share,
    .cta-modify a {
        font-size: 13px;
        line-height: 1.5;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .share_wl {
        display: none;
    }

    .wrapper_btn_add {
        display: flex;
        width: 562px;
        justify-content: space-between;

        .form_submit {
            border: 0;
        }

        .form_submit,
        .button {
            max-width: 272px;
        }
    }
}

body.wishlist {
    #abox {
        .wrap_btn_abox {
            display: none;
        }
    }
}

form#sendwishlist {
    margin: 0 auto;
}

/* Newsletter preference */
.w-newspreferences-form .w-checkbox-input .w-input-element+.w-input-label::before {
    background: #f0efec;
}

.w-newspreferences-form .w-checkbox-input .w-input-element:checked+.w-input-label::before {
    background: #9f9f9e;
}

.w-newspreferences-form {
    .w-checkbox-input {
        .w-input-element:checked+.w-input-label::after {
            left: 10px;
        }
    }
}

#subscriptionForm {
    input[type='email'] {
        padding-left: 14px;
    }
}

.newsletter_pref_content {
    margin-bottom: 70px;

    .right_container.content_newsletter {
        .w-newspreferences-form {
            display: flex;
            flex-direction: column;

            .w-form-line.optin-select-line:nth-child(3) {
                .w-input-label::before {
                    width: 23px;
                }
            }

            .w-form-line:nth-child(2) {
                margin-bottom: 38px;
            }

            .w-form-line.optin-select-line:nth-child(4) {
                order: 3;
            }

            .w-form-line.optin-select-line:nth-child(5) {
                order: 2;

                .w-input-label::before {
                    width: 25px;
                }
            }

            .w-form-line:nth-child(6) {
                order: 4;
            }

            .w-form-line {
                margin-bottom: 20px;

                .w-input-label {
                    .multiline {
                        margin-left: 20px;
                        width: 100%;

                        strong {
                            color: $primaryColor;
                            font-size: 16px;
                            letter-spacing: -0.5px;
                            font-weight: $bold;
                        }

                        span {
                            color: $primaryColor;
                            font-size: 16px;
                            font-weight: normal;
                        }
                    }
                }
            }

            .w-submit {
                margin-top: 32px;
            }
        }
    }
}

.newlogin_page {
    @media screen and (max-width: 1300px) {
        padding-right: 2rem;
    }

    .login_bloc {
        .title_vp {
            font-size: 40px;
            font-weight: $bold;
            line-height: 40px;
            margin-bottom: 20px;
        }
    }

    .login_bloc .form-msg.active {
        padding: 1rem;
        width: calc(100% - 2rem);
    }

    // Text input with error
    .w-has-error:not(.w-dropdown) {
        .w-input-element:not([type='radio']):not([type='checkbox']) {
            box-shadow: none;
            border-color: $bitterSweet;
        }

        // For when autofill is used
        input:not([type='radio']):not([type='checkbox']):-webkit-autofill,
        input:not([type='radio']):not([type='checkbox']):-internal-autofill-selected {
            border-color: $bitterSweet !important;
        }
    }
}

@media screen and (max-width: 1500px) {
    .wrapper_moncompte .right_container {
        margin-top: 0;
    }
}

@media screen and (max-width: 1350px) {
    .order_details {
        .left-container {
            display: none;
        }

        .right_container {
            width: 100%;
        }
    }
}

@media (min-width: 1000px) and (max-width: 1280px) {
    .wrapper_moncompte {
        .left-container {
            margin-right: 30px;
        }

        .right_container {
            .account_nav .item {
                font-size: 16px;
                margin-right: 35px;
            }
        }
    }
}

@media (min-width: 1000px) and (max-width: 1140px) {
    .wrapper_moncompte .right_container .account_nav {
        height: 30px;

        .item {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 1140px) {
    .wrapper_moncompte.ordered_content {
        flex-direction: column;
        align-items: center;

        .left-container {
            display: none;
        }

        .right_container {
            width: 100%;
            max-width: inherit;

            .wrapper_mes_commandes {
                max-width: inherit;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .wrapper_moncompte {
        .right_container {
            width: 100%;
        }

        .left-container {
            display: none;
        }
    }
}

@media screen and (max-width: 860px) {
    .wrapper_moncompte .right_container .account_nav .item {
        font-size: 16px;
        margin-right: 30px;
    }
}

.wrapper_moncompte #iconValidclientTel,
#iconErrclientTel {
    display: none;
}

.wrapper_moncompte .w-input:not(.w-dropdown) input[type='email'].w-input-element:focus {
    +.w-input-label {
        font-style: normal;
    }
}

/* Lightbox Choose Store */
.content_store_locator {
    max-height: 290px;

    .os-scrollbar-handle {
        background-color: black !important;
    }

    #content_store_locator {
        width: 100%;

        tr {
            border-bottom: 1px solid #e6e8ea;
            cursor: pointer;

            .picStore {
                display: none;
            }

            td {
                height: 60px;
                padding-top: 25px;
                padding-bottom: 25px;
                vertical-align: middle;
            }

            td.info_store {
                text-align: left;

                h2 {
                    font-size: 16px;
                    font-weight: $bold;
                    display: flex;

                    span {
                        font-size: 13px;
                        font-weight: $light;
                        margin-left: 10px;
                        margin-top: 3px;
                        font-size: 13px;
                        color: #777777;
                    }
                }

                p {
                    font-size: 16px;
                }

                .caclick {
                    font-size: 16px;
                    font-weight: $bold;
                    position: relative;
                    padding-left: 10px;

                    &:before {
                        content: '•';
                        display: inline-block;
                        position: absolute;
                        left: 2px;
                        bottom: 0;
                        font-size: 16px;
                    }
                }

                .txt.store_open {
                    padding-left: 15px;
                    position: relative;

                    span {
                        color: #78aa00;
                    }
                }

                .txt.store_close {
                    color: #ba3a4f;
                    padding-left: 15px;
                    position: relative;
                }

                .txt.store_open:before {
                    color: #78aa00;
                    content: '•';
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    bottom: -16px;
                    font-size: 35px;
                }

                .txt.store_close:before {
                    color: #ba3a4f;
                    content: '•';
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    bottom: -16px;
                    font-size: 35px;
                }

                .right_favshop_wrapper {
                    font-size: 16px;
                    font-weight: $light;
                    text-decoration: underline;
                    margin-top: 15px;
                }
            }

            td.contact_store {
                padding-right: 20px;

                .w-submit-button {
                    font-family: inherit;
                    width: 200px;
                    height: 50px;
                    text-align: center;
                    font-size: 15px;
                    line-height: 50px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

/* Command detail + Tunnel panier */
.cmd_table {
    width: 100%;

    thead {
        height: 20px;
        background-color: #f5f5f5;
        font-size: 14px;

        th {
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid black;
            height: 150px;

            td.cmd_img_info {
                max-width: 338px;
                vertical-align: middle;
            }

            td.cmd_unit_price {
                width: 22.5%;

                .pricetag {
                    position: relative;
                    display: inline-block;
                    min-width: 88px;
                    width: fit-content;

                    &.crossed {
                        font-size: 20px;

                        .cents {
                            font-size: 0.6em;
                            vertical-align: 0.5em;
                        }

                        .currency {
                            font-size: 12px;
                            vertical-align: 0.75em;
                        }
                    }
                }

                .container_eclats {
                    position: absolute;
                    bottom: 120%;
                    right: 25%;
                }
            }

            td.cmd_qnt {
                width: 13%;
            }

            td.cmd_tot_price {
                width: 22%;
            }

            td {
                min-width: 20px;
                position: relative;
                vertical-align: middle;

                .cart_thumb_holder {
                    width: 95px;
                    height: 95px;
                    float: left;

                    img {
                        width: 95px;
                    }
                }

                .cart_thumb_info {
                    margin-left: 10px;
                    display: inline-flex;
                    flex-direction: column;

                    .sstitre {
                        font-size: 16px;
                        font-weight: $bold;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 12rem;
                    }

                    .sub_title {
                        font-size: 16px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 12rem;
                    }

                    .sub_couleur {
                        margin-top: 10px;
                    }

                    .sub {
                        display: flex;
                        justify-content: flex-start;
                        font-size: 14px;
                        font-weight: $bold;

                        strong {
                            display: inline-block;
                            width: 70px;
                        }

                        span {
                            font-weight: $regular;
                            white-space: nowrap;
                            max-width: 70%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .wrapper_price {
                    display: inherit;
                    align-items: inherit;
                    padding-left: 0;
                }

                .sub_qte,
                .wrapper_price {
                    text-align: center;
                }
            }
        }
    }
}

.cart_main_table {
    .cmd_table {
        .container_eclats {
            .eclat_img.eclat_img_1 {
                display: none;
            }
        }
    }
}

// PASSWORD RESET
.wrapper_content_password {
    max-width: 600px;
    margin: 0 auto;

    #clientForm {
        .w-btn-wrapper {
            max-width: 300px;
            margin: 0 auto;
        }

        .w-submit-button {
            @extend .dark;
        }
    }
}

body.newsletter {
    .w-submit-button {
        @extend .dark;
    }
}

#deny-optin {
    padding-top: 0.8rem;

    .w-input-label {
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: $primaryColor;

        &::before {
            min-width: unset;
            width: 1em;
            height: 1em;
            border-radius: 0;
            margin-right: 0.5em;
        }
    }

    .w-radio-input {
        .w-input-element:checked+.w-input-label::before {
            box-shadow: inset 0 0 0 0.125rem #fff;
            border-color: $black;
        }
    }
}