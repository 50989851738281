.wrapper_header {
    position:relative;
    width:100%;
    .img_header {
        max-width: 100%;
    }    
    p {
        font-size:80px;
        font-weight:$bold;
        position:absolute;
        left:100px;
        top:115px;
        line-height: 80px;
    }
}
.wrapper_our_brand {
    width: 98%;
    max-width: 1680px;
    margin-top: 120px;
    margin-bottom:80px;
    margin-left:auto;
    margin-right:auto;
    .our_brand_intro {
        display:flex;
        align-items: center;
        justify-content: center;
        .our_brand_visuel {
            position:relative;
            height: 580px;
            min-width: 600px;
            width: 600px;
            background-image: url(../img/our-brand-intro-visuel.jpg);
            background-repeat:no-repeat;
            background-size:cover;
            background-position:center;
        }
        .our_brand_text {
            margin-left:40px;
            max-width: 650px;
            letter-spacing: 0.05em;
            br {
                content: "";
                margin: 4.786em;
                display: block;
                font-size: 24%;
            }
            p {
                line-height:18px;
                strong {
                    font-weight:$bold;
                }
            }
            p:last-child {
                font-size:18px;
            }
        }
    }
    .our_five_product {
        margin-top:120px;
        >h2 {
            text-align:center;
            font-size:50px;
            color:$primaryColor;
            font-weight:$bold;
        }
        .our_five_product_content {
            margin-top:50px;
            display:flex;
            flex-wrap: wrap;
            .bloc_our_brand:nth-child(1) {
                color:$primaryColor;
                background-color:#c6c89b;
                h3 {
                    color:#728574;
                }
            }
            .bloc_our_brand:nth-child(2) {
                color:$primaryColor;
                background-color:#efc9b8;
                h3 {
                    color:#ba3a4f;
                }
            }
            .bloc_our_brand:nth-child(3) {
                color:$primaryColor;
                background-color:#7fa9ae;
                h3 {
                    color:#004151;
                }
            }
            .bloc_our_brand:nth-child(4) {
                color:$primaryColor;
                background-color:#fcd299;
                h3 {
                    color:#be9045;
                }
            }
            .bloc_our_brand:nth-child(5) {
                margin-right:0;
                color:$primaryColor;
                background-color:#f5f5f5;
            }
            .bloc_our_brand {
                width: 220px;
                height: 185px;
                margin-right: 45px;
                padding: 40px;
                position:relative;
                margin-bottom:45px;
                h3 {
                    font-size:30px;
                    font-weight:$bold;
                    margin-bottom: 25px;
                }
                p {
                    color:$primaryColor;
                    right: 40px;
                    width: 220px;
                    bottom: 40px;
                    text-align:left;
                    line-height: 18px;
                }
            }
        }
    }
    .where_are_we {
        margin-top:75px;
        text-align: center;
        > h2 {
            font-size:50px;
            font-weight:$bold;
        }
        > p {
            font-size:16px;
        }
        .where_are_we_content {
            display:flex;
            margin-top:40px;
            justify-content: center;
            .bold {
                font-weight:$bold;
                font-size:18px !important;
                text-transform: uppercase;
            }
            .our_distributors_partners {
                margin-right:50px;
                border:1px solid black;
                border-radius:20px;
                text-align:center;
                padding-top: 35px;
                height:88px;
                width: 36.31%;
                min-width:420px;
                font-size:15px;
                cursor:pointer;
                .pict {
                    font-weight:$semibold;
                    height: 25px;
                    text-transform:uppercase;
                    margin-top: 10px;
                    &::after {
                        margin-left:10px;
                        width: 18px;
                        height: 18px;
                        margin-top: -2px;
                    }
                }
            }
            .delivered_to_you {
                border:1px solid black;
                border-radius:20px;
                text-align:center;
                height:88px;
                width: 36.31%;
                min-width:420px;
                padding-top: 35px;
                cursor:pointer;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .wrapper_our_brand .our_brand_intro {
        flex-direction: column;
        align-items: center;
        .our_brand_text {
            margin-top: 25px;
            margin-left: 0;
            max-width: inherit;
        }
    }
}

@media screen and (max-width: 1024px) {
    .our_five_product_content {
        justify-content: space-between;
        .bloc_our_brand {
            margin-right:10px !important;
        }
    }
}

@media screen and (max-width: 950px) {
    #appContainer .where_are_we_content {
        flex-direction: column;
        .our_distributors_partners {
            max-width: 615px;
            width:100%;
            margin-right: 0;
            margin-left: auto;
            margin-right: auto;
        }
        .delivered_to_you {
            margin-top:25px;
            max-width: 615px;
            width:100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}