#copyright,
.algolia_footer.footer {

    .wrapper_reinsurance {
        display: flex;
        width: 98%;
        max-width: 935px;
        margin: 0 auto;
        justify-content: space-between;
        margin-bottom: 50px;
        align-items: baseline;

        .reisurance_item>a {
            display: flex;
            flex-direction: column;
            text-align: center;

            .reisurance_item_link {
                margin-bottom: 10px;

                &:before {
                    width: 32px;
                    height: 32px;
                }

                &.garantienoel,
                &.domicilenoel {
                    &:before {
                        background-image: url(../img/livraison.png);
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

                &.deliveryfp::before {
                    width: 50px;
                    padding-bottom: 4px;
                    box-sizing: content-box;
                }
            }

            .title {
                text-transform: uppercase;
                font-size: 18px;
                line-height: 1;
                font-weight: $bold;
            }

            .subtitle {
                font-size: 16px;
                line-height: 1;
                font-weight: $regular;
            }
        }
    }

    .wrapper_newsletter {
        width: 98%;
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        margin-bottom: 54px;

        .footer_newsletter_title {
            width: 22.5%;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: $bold;
            text-align: center;
            border: 1px solid black;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            height: 46px;
            line-height: 48px;
        }

        .w-newsletter-form {
            display: flex;
            width: 77.5%;

            .w-input-element {
                border: 0;
            }

            >.w-form-line {
                margin-bottom: 0;

                &:nth-child(5) {
                    width: 71.2%;
                    border-top: 1px solid black;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                    height: 46px;

                    .w-input-container {
                        height: 100%;
                        line-height: 49px;
                    }

                    .w-input-element {
                        &::placeholder {
                            font-size: 14px;
                            color: #777777;
                            font-style: normal;
                            padding-left: 10px;
                        }

                        &::-moz-placeholder {
                            font-size: 14px;
                            color: #777777;
                            font-style: normal;
                            padding-left: 10px;
                        }

                        &::-webkit-placeholder {
                            font-size: 14px;
                            color: #777777;
                            font-style: normal;
                            padding-left: 10px;
                        }

                        &::-ms-placeholder {
                            font-size: 14px;
                            color: #777777;
                            font-style: normal;
                            padding-left: 10px;
                        }

                        &:-ms-placeholder {
                            font-size: 14px;
                            color: #777777;
                            font-style: normal;
                            padding-left: 10px;
                        }
                    }
                }
            }

            .w-form-line.w-submit {
                position: initial;
                width: 29.8%;
                max-width: unset;
                height: 46px;
                border: 1px solid $black;
                border-left: 0;
                border-radius: 0 20px 20px 0;
                overflow: hidden;

                .w-submit-button {
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    width: 100%;
                    background-color: white;
                    color: black;
                    font-size: 15px;
                    font-weight: $bold;
                    text-transform: uppercase;
                    border: 0;

                    &.w-loader {
                        font-size: 0;

                        >span {
                            font-size: 0;
                        }
                    }

                    >span {
                        width: auto;
                        height: auto;
                        background-color: transparent;
                        display: flex;
                        align-items: center;

                        &::after {
                            margin-top: -2px;
                            margin-left: 10px;
                            content: '→';
                            display: inline-block;
                        }
                    }
                }
            }
        }

        .w-input.w-email-input .w-input-element {
            padding-right: 0;
            box-sizing: border-box;
            width: 100%;
            height: 46px;
        }
    }

    .copyright {
        margin-top: 35px;
        padding: 25px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        color: #777777;
        font-size: 13px;
        border-top: 1px solid $primaryColor;
    }

    .wrapper_bottom_container {
        max-width: $wrapperSmall;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;

        @media screen and (max-width: $wrapperMedium) {
            padding: 25px 2rem;
        }
    }

    .wrapper_bottom {
        display: flex;
        justify-content: space-between;
        margin: 0 -1rem;
    }

    .wrapper_links {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;

        &.logos {
            justify-content: space-between;
        }

        .wrapper_socials {
            margin-top: 90px;
            display: flex;
            justify-content: space-between;

            a {
                display: block;

                .bgSprite {
                    &::before {
                        width: 26px;
                        height: 26px;
                    }

                    &.tiktok {
                        background-position: 0 79%;
                        
                        &::before {
                            width: 27px;
                        }
                    }
                }
            }
        }

        .col_title {
            font-size: 16px;
            font-weight: $semibold;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }

        .col_content {
            margin-left: 8px;
        }

        .pictos_list {
            display: flex;
            width: 144px;
            flex-wrap: wrap;
            justify-content: space-between;
            row-gap: 0.5rem;
            margin-left: 0;

            .pictos_item {
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }

                &.avis {
                    .verified_reviews {
                        padding-top: 1.15rem;
                    }
                }

                &.alma {

                    width: 65px;
                    display: flex;
                    border: 1px #ccc solid;
                    justify-content: center;

                    img {
                        max-width: 35px;
                    }
                }
            }
        }
    }

    .footer_logo {
        font-size: 0;
        display: block;
        width: 124px;
        height: 61px;
        background: url('../img/five_logo.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .wshop_logo {
        display: inline-block;
        margin-left: 5px;
        max-width: 48px;
        width: 100%;

        img {
            width: 100%;
            height: auto;
            vertical-align: bottom;
        }
    }
}

#mention_legales_popup {
    width: 100%;
    text-align: center;
}

#mention_legales_pop_up {
    .light_title {
        font-size: 16px;
        margin-bottom: 2rem;
    }
}