/* WRAPPERS VARS */
$wrapperLarge : 1920px;
$wrapperMedium : 1680px;
$wrapperSmall : 1280px;

/* HEADER HEIGHT VARS */
$preheader_height : 40px;
$headerHeightSmall: 138px;
$headerHeightBig : calc(#{$preheader_height} + #{$headerHeightSmall});
$fixed_headerHeightSmall: 80px;
$fixed_headerHeightBig : calc(#{$preheader_height} + #{$fixed_headerHeightSmall});
$all_transition : all .3s ease;