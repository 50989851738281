/* SPECIFIC ***********************************/
.page_rayon,
.products_list {
    .wrapperProdVertical {
        max-width: calc(97% / 4);
        padding: 0 0 10px 0;

        &:not(:nth-child(4n)) {
            margin-right: 1%;
        }
    }
}

.cms_page .wrapperProdVertical,
.cms_page_new .wrapperProdVertical {
    max-width: 100%;
}

// FP recently viewed
#recent_see .wrapperProdVertical {
    max-width: calc(100% / 5);
}

/* GLOBAL ***********************************/
// SPECIAL CASE FOR mise en avant product exception
#scroll_items {
    //no_stars_products class exists only for id categ 1, 11, 12
    &:not(.no_stars_products) {
        .wrapperProdVertical {
            &.mis_en_avant {
                .imgWrapper {
                    background-color: var(--parenttextcolor, transparent);
                    padding: 5px 5px 0 5px;
                }

                > .block_lnk {
                    display: block;
                    background-color: var(--parenttextcolor, transparent);
                    color: var(--parentbgcolor, $primaryColor);
                    padding: 0 5px 5px 5px;
                    height: 100%;
                    flex-grow: 1;

                    .wrapper_description {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                .count_colors {
                    padding: 5px 0 0;
                }
            }
        }
    }
}

.wrapperProdVertical {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px 0;
    box-sizing: border-box;

    &.push {
        img {
            max-width: 100%;
        }
    }

    .no_stock_block {
        width: 165px;
        height: 27px;
        background-color: white;
        border-radius: 12px;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;

        p {
            font-size: 14px;
            font-weight: $semibold;
            text-align: center;
            text-transform: uppercase;
            line-height: 27px;
        }
    }

    .with_crossed_pricetag {
        > h2 {
            max-width: 55%;
        }
    }

    .rolloverimgContainer a.block_lnk {
        position: relative;
        width: 100%;
        padding-top: calc(328 / 328 * 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        overflow: hidden;

        &:hover {
            .rollover_picture {
                z-index: 2;
                opacity: 1;
            }

            .ill_img:nth-child(2) {
                opacity: 1;
                z-index: 2;
            }
        }

        .itm_vis {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            max-width: 100%;
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        .ill_img {
            display: block;
            transition: opacity 0.3s ease;

            &:nth-child(2) {
                opacity: 0;
                z-index: -1;
            }
        }

        .rollover_picture {
            opacity: 0;
            z-index: -1;
            transition: opacity 0.3s ease;
        }
    }

    // AE BLOC
    .ae_bloc {
        .close_pop {
            position: absolute;
            z-index: 10;
            top: .5rem;
            right: 1rem;
            width: 20px;
            height: 20px;
            cursor: pointer;
            background-color: transparent;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                right: 0;
                content: ' ';
                height: 14px;
                width: 1px;
                background-color: $primaryColor;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }


    .wrap_rolloverproduit {
        .ligne_form {
            display: none;
            width: 96%;
            position: absolute;
            bottom: 2%;
            background-color: white;
            z-index: 4;
            border-radius: 10px;
            height: 120px;
            left: 50%;
            transform: translateX(-50%);

            .prod_listes {
                width: 340px;
                margin: auto;
                height: 120px;
                overflow: hidden;
            }

            .titre_choices_list {
                display: none;
            }

            .choices_list {
                display: flex;

                .form_itm {
                    position: relative;
                    .input_check:checked+label {
                        border: 1px solid black;
                    }

                    input[type='radio'] {
                        opacity: 0;
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        cursor: pointer;
                    }

                    label {
                        width: 63px;
                        height: 63px;
                        display: block;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 28.5px;
                        text-align: center;
                        cursor: pointer;

                        span {
                            line-height: 65px;
                        }
                    }
                }
            }

            // Hide nav btns when swiper not init
            .swiper-container:not(.swiper-container-initialized) {
                .swiper-button-prev,
                .swiper-button-next {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                z-index: 1;
                top: 50%;
                transform: translateY(-50%);
                height: 30px;
                cursor: pointer;
                background-image: none;
                margin: 0;

                &::after {
                    width: 25px;
                    height: 25px;
                }
            }

            .swiper-button-prev {
                left: 20px;
            }

            .swiper-button-next {
                right: 20px;
            }
        }
    }

    .imgWrapper {
        position: inherit;
    }

    .imgWrapper {
        position: relative;
    }

    .bloc_add_color {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 3;
    }

    .addToWishlistButton {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;

        span {
            display: none;
        }

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(3, 6, 6);
            display: inline-block;
        }
    }

    .existToWishlistButton {
        &::before {
            @include bgIconCoord(21, 6, 6);
        }
    }

    .form_submit {
        width: 35px;
        height: 35px;
    }

    .form_submit .button,
    .button {
        background-color: transparent;
        border: none;
        height: 100%;

        span {
            display: none;
        }

        &:not(.loader) {
            background-color: white;
            border-radius: 5px;

            &::before {
                content: '';
                display: block;
                width: 25px;
                height: 25px;
                @include bgIconPath('../svg/spritesheet.svg');
                @include bgIconCoord(5, 6, 6);
                margin: 0 auto;
                margin-right: 3px;
            }
        }

        &.loader {
            border-radius: 5px;
            border: 0;
            z-index: -1;
            background: $white url(../svg/three_dots.svg) center no-repeat;
            background-size: 75%;
            position: absolute;
            top: 0;

            &.loading {
                z-index: 2;
            }

            span {
                font-size: 0;
            }
        }
    }

    .w-submit-button:not(.w-loader):hover,
    button.w-submit-button:not(.w-loader):hover {
        background-color: white;
    }

    .btnAddBasketWrapper {
        width: 100%;
        background-color: white;
        font-size: 0.8rem;
        position: absolute;
        z-index: 3;
        padding-top: 5px;
        bottom: 0;

        .alert_stock {
            color: $primaryColor;
            margin-bottom: 10px;
        }

        .bloc_add_alert_form {
            width: 100%;
            height: 40px;
            padding: 0;
            border-radius: 5px;
            box-sizing: border-box;
            border: solid 1px $red;
            overflow: hidden;

            .w-form-line {
                align-items: center;
                box-sizing: border-box;
                height: 40px;

                input {
                    border: none !important;
                    height: 40px;
                    box-sizing: border-box;
                    padding: 0.45rem 1rem;
                    background-color: transparent;
                    width: 100%;

                    &.inputErr,
                    &.inputErr + button {
                        transition: background-color 5000s ease, border 0.2s ease-out, color 0.2s ease-out;
                        border-color: $borderRed;
                    }

                    &.inputErr {
                        color: $red;
                    }

                    &:acitve,
                    &:focus {
                        background-color: transparent;
                    }
                }

                .form_submit {
                    cursor: pointer;
                    width: auto;
                    height: 40px;
                    padding: 0 1rem;
                }

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 100%;
                    padding: 0;
                    cursor: pointer;
                    border: none;
                    border-radius: 0;

                    &::before {
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        @include bgIconPath('../svg/spritesheet.svg');
                        @include bgIconCoord(6, 6, 6);
                        background-repeat: no-repeat;
                    }

                    span {
                        font-size: 0;
                    }
                }

                .img_error {
                    display: none;
                }
            }
        }

        .bloc_add_alert_confirmation {
            top: 2.3rem;
            display: block;
            width: 100%;
            box-sizing: border-box;

            p {
                width: 100%;
                display: block;
                box-sizing: border-box;

                span {
                    display: inline-block;
                    width: 100%;
                }
            }
        }

        .alert_return {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            background-color: transparent;

            span {
                font-size: 0;
                display: block;
                padding: 7px;

                &::before {
                    content: "";
                    display: block;
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(19,6,6);
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    .wrapper_description {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 15px 10px 0px 10px;
    }

    .item_name {
        display: flex;
        flex-direction: column;
        line-height: 1.3;
        font-size: 16px;
        font-weight: $bold;
        overflow: hidden;

        > span {
            display: block;
        }

        .item_subtitle {
            font-weight: $regular;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
        }
    }

    .wrapper_price {
        padding-left: 1rem;
    }

    .count_colors {
        padding: 5px 10px 0;
        font-size: 12px;
    }

    // ECLATS
    .container_eclats {
        position: absolute;
        top: 0;
        left: 0;
        // Approx the width of wishlist btn on top right of the bloc
        max-width: calc(100% - 48px);
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 -3px;
        z-index: 3;

        > * {
            margin: 0 3px 6px;
        }

        img {
            vertical-align: bottom;
        }
    }
}